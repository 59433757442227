import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //

//Serivce
import ItemService from "../../../services/supplier_appCommonService";

//Components
import Layout from "../../../Layout/Layout";
import CreateDocTypeModal from "../../../Components/Modal/supplier_app/CreateDocTypeModal";
import Constants from "../../../common/Constants";
import CommonItemLevelDatatableModal from "../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { addApprovedSelectedRows, addPrDetailNumber, addRfqCreation } from "../../../redux/feature/supplier_app/sourcingSlice";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import CancelModal from "../../../Components/Modal/supplier_app/common/CancelModal";
import ShowStatus from "../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import CreatePRModal from "../../../Components/Modal/supplier_app/CreatePRModal";

const PurchaseRequisitionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Purchase Requisition");
  let purchase_requisition_data = useSelector(
    (state) => state?.sourcing?.purchase_requisition?.data
  ); // User Details from Store

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  // const [perPage, setPerPage] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShowPR, setModalShowPR] = useState(false);
  const [modalShowRFQ, setModalShowRFQ] = useState(false);
  const [modalData, setModalData] = useState({});

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [getPurchaseDocType, setPurchaseDocType] = useState("");
  const [getRFQCategoryType, setRFQCategoryType] = useState("");

  // Show Status Modal
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  const [getSelectedRows, setSelectedRows] = useState([]);
  const [getApprovedRows, setApprovedRows] = useState([]);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  /* Modal Function */
  const createRFQ = () => {
    let obj = {
      modalTitle: "Create RFQ",
      navigate: Constants.SOURCING_CREATE_RFQ,
      documentNumbers: getApprovedRows
    };
    setModalData(obj);
    setModalShowRFQ(true);
  };

  const createManualPR = () => {
    setModalShowPR(true);
  };

  const hideModal = () => {
    setModalShowRFQ(false);
    setModalShowPR(false);
  };

  const detailedPage = (purchaseReqNumber) => {
    dispatch(addPrDetailNumber(purchaseReqNumber));
    navigate(Constants.SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE);
  };

  const fetchUsers = async () => {
    setLoading(true);
    setData(purchase_requisition_data);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase_requisition_data]);

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };

  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  const columns = [
    {
      name: "Ḍocument Type",
      selector: (row) => row.purchasingReqDocumentType,
      sortable: true,
      width: "200px",
    },
    {
      name: "Requisition Number",
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPage(row.purchaseReqDocumentNr)}
        >
          {row.purchaseReqDocumentNr}{" "}
        </button>
      ),
      width: "160px",
    },

    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "150px",
    },
    // Conditionally include the 'workflowActionLogs' column
    ...(data?.some((row) => row.workflowActionLogs !== undefined)
      ? [
          {
            name: "Workflow log",
            selector: (row) => row.workflowActionLogs,
            cell: (row) => (
              <div style={{ textAlign: "center", width: "100%" }}>
                <i
                  className="bi bi-substack iconTable statusColor"
                  title="Workflow Log Details"
                  onClick={() => showStatusInfo(row.workflowActionLogs)}
                ></i>
              </div>
            ),
            sortable: true,
            width: "132px",
          },
        ]
      : []),
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.prStatusHeader === "In Release" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.prStatusHeader}
            </span>
          ) : row.prStatusHeader === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.prStatusHeader}
            </span>
          ) : row.prStatusHeader === "PR Approved" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.prStatusHeader}
            </span>
          ) : row.prStatusHeader === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.prStatusHeader}
            </span>
          ) : (
            row.prStatusHeader
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Value",
      selector: (row) => row.purchasingReqDocumentValue,
      sortable: true,
      width: "100px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "120px",
    },
    {
      name: "RFQ Number",
      cell: (row) => (
        <button className="btnInfo">{row.rfqCollectiveNr || "--"} </button>
      ),

      width: "120px",
    },

    {
      name: "RFQ Status",
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-progress-status">
            Commercial{row.commOpen}
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "RFQ Create On",
      selector: (row) => row.purchasingReqDocumentDate,
      sortable: true,
      width: "150px",
    },

    // {
    //   name: "HeaderText",
    //   selector: (row) => row.headerTextData?.documentText || "--",
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Release Date ",
    //   selector: (row) => row.purchaseReqRelDate,
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Delivery Date ",
    //   selector: (row) => row.item?.expectedDeliveryDate || "--",
    //   sortable: true,
    //   width: "120px",
    // },
    {
      name: "Procurment Category",
      selector: (row) => row.purchaseCategory,
      sortable: true,
      width: "180px",
    },

    {
      name: "Purchase Type",
      selector: (row) => row.purchaseType,
      sortable: true,
      width: "180px",
    },
    // {
    //   name: "User Department",
    //   selector: (row) => row.item?.userDepartment,
    //   sortable: true,
    //   width: "180px",
    // },

    // {
    //   name: "Requisitioner",
    //   selector: (row) => row.item?.initiatorName,
    //   sortable: true,
    //   width: "160px",
    // },
    {
      name: "Created By",
      selector: (row) => row.initiatorName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Purchasing Group",
      selector: (row) => row.item?.purchasingGroup,
      sortable: true,
      width: "180px",
    },
    // {
    //   name: "Plant",
    //   selector: (row) => row.item?.businessLocation,
    //   sortable: true,
    //   width: "140px",
    // },
    {
      name: "Company",
      selector: (row) => row.companyCode,
      sortable: true,
      width: "140px",
    },
  ];

  const lineItemColInfo = [
    {
      name: "PR Number",
      cell: (row) => <span>{row.purchaseReqDocumentNr} </span>,
      width: "180px",
    },
    {
      name: "PR Item Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material Code",
      cell: (row) => <span>{row.materialCode} </span>,
      width: "180px",
    },
    {
      name: "Material Desc",
      cell: (row) => <span>{row.materialDescription} </span>,
      width: "180px",
    },
    // {
    //   name: "Herder Material Desc",
    //   cell: (row) => <span>{row.headerMaterialCode} </span>,
    //   width: "180px",
    // },

    {
      name: "Quantity",
      cell: (row) => <span>{row.purchaseQuantity} </span>,
      width: "180px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "180px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "180px",
    },
    {
      name: "Rate",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "180px",
    },
    {
      name: "Curreny",
      cell: (row) => <span>{row.currency} </span>,
      width: "180px",
    },
  ];

  const getMasterDataPR = async () => {
    let obj = {
      codeType: "PurchaseDocType",
    };
    await ItemService.getMasterData(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setPurchaseDocType(item?.data);
      } else {
        setPurchaseDocType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  const getMasterDataRFQ = async () => {
    let obj = {
      codeType: "RFQCategory",
    };
    await ItemService.getMasterData(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setRFQCategoryType(item?.data);
      } else {
        setRFQCategoryType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  //on page load
  useEffect(() => {
    getMasterDataPR();
    getMasterDataRFQ();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Cancel Modal */
  const [getCancelModalStatus, setCancelModalStatus] = useState(false);
  const cancelModalShow = () => {
    setCancelModalStatus(true);
  };
  const cancelModalHide = () => {
    setCancelModalStatus(false);
  };

  /* Handle selected rows */
  const handleRowSelected = ({ selectedRows }) => {
    // get expect Cancelled records
    const validRows = selectedRows.filter(
      (item) => item?.prStatusHeader !== "Cancelled"
    );
    setSelectedRows(validRows);

    // get only Approved
    const getRows = selectedRows.filter(
      (item) => item?.prStatusHeader === "PR Approved"
    );
    let prNumber = [];
    getRows.map((item, index) => {
     return prNumber.push(item.purchaseReqDocumentNr);
    });    
    dispatch(addApprovedSelectedRows(prNumber))
    dispatch(addRfqCreation("PR"))
    setApprovedRows(prNumber);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Purchase Requisition</h2>
            <p className="subText">Purchase Requisition List</p>
            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RQ</span>
                    </Col>
                    <Col xs={8} md={4} lg={4}>
                      <span className="headerTitle">Purchase Requisition</span>
                      <span className="subHeaderTitle">
                        View of Purchase Requisition{" "}
                      </span>
                    </Col>

                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => createRFQ()}
                        disabled={getApprovedRows.length > 0 ? false : true}
                      >
                        <i className="bi bi-plus-lg"></i> Create RFQ
                      </button>
                    </Col>

                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => createManualPR()}
                      >
                        <i className="bi bi-plus-lg"></i> Manual PR
                      </button>
                    </Col>

                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-person"></i> Block/UnBlock
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-person"></i> Buyer Change
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-recycle"></i> Status Change
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={cancelModalShow}
                        disabled={getSelectedRows.length > 0 ? false : true}
                      >
                        <i className="bi bi-x-circle"></i> Cancel
                      </button>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          onSelectedRowsChange={handleRowSelected}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Create RFQ  */}
      <CreateDocTypeModal
        show={modalShowRFQ}
        onHide={() => hideModal()}
        modaldata={modalData}
        dropdowndata={getRFQCategoryType}
      />

      {/* Manual PR */}
      <CreatePRModal
        show={modalShowPR}
        onHide={() => hideModal()}
        modaldata={modalData}
        dropdowndata={getPurchaseDocType}
        navigate={Constants.SOURCING_PURCHASE_REQUISITION_CREATE}
      />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <CancelModal
        title="Cancel the Purchase Requisition"
        type={"PR"}
        show={getCancelModalStatus}
        onHide={cancelModalHide}
        selectedrow={getSelectedRows}
      />

      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
    </main>
  );
};

export default PurchaseRequisitionList;
