import { configureStore } from "@reduxjs/toolkit";
// import { useDispatch, useSelector } from "react-redux";

import  userSlice  from "./feature/userSlice";

import  commonSlice  from "./feature/supplier_app/commonSlice";
import  menuSlice  from "./feature/supplier_app/menuSlice";
import  workflowSlice  from "./feature/supplier_app/workflowSlice";
import  sourcingSlice  from "./feature/supplier_app/sourcingSlice";
import  supplierSlice  from "./feature/supplier_app/supplierSlice";
import  purchasingSlice  from "./feature/supplier_app/purchasingSlice";
import  gatepassSlice  from "./feature/supplier_app/gatepassSlice";
import  singleSourcingSlice  from "./feature/supplier_app/singleSourcingSlice";


const store = configureStore({
  reducer: {
    user : userSlice,

    common : commonSlice,
    menu : menuSlice,
    workflow : workflowSlice,
    sourcing : sourcingSlice,
    supplier : supplierSlice,
    purchasing : purchasingSlice,
    gatepass : gatepassSlice,
    singleSourcing : singleSourcingSlice,
  }
});

export default store;

// export const useAppDispatch = useDispatch;
// export const useAppSelector = useSelector;
