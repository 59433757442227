import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import POCollabrationCommonFillter from "../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import DataTable from "react-data-table-component";

//Redux
import { useSelector ,useDispatch} from "react-redux"; //useDispatch
import Constants from "../../../common/Constants";

//components
import { useNavigate } from "react-router-dom";
import { addSingleSourceRequestNr } from "../../../redux/feature/supplier_app/singleSourcingSlice";


const SingleSourceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  let singleSourcingData = useSelector((state) => state?.singleSourcing); // User Details from Store
  
  const [data, setData] = useState([]);
  // console.log(">>data",data)
  const loadInitialSuuplierData = async () => {
    setData(singleSourcingData?.data);
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleSourcingData]);

  const [isCardVisible1, setCardVisible1] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
/* 
  const detailedPage = (singleSourceRequestNr) => {
    dispatch(addSingleSourceRequestNr(singleSourceRequestNr.trim()));
    console.log(">>singleSourceRequestNr dispatching",singleSourceRequestNr)
    navigate(Constants.SINGLESOURCING_SINGLE_SOURCING_DETAILS);
  }; */

  const detailedPage = (singleSourceRequestNr) => {
    dispatch(addSingleSourceRequestNr(singleSourceRequestNr.trim()));
    console.log(">>singleSourceRequestNr dispatching", singleSourceRequestNr);
    navigate(Constants.SINGLESOURCING_SINGLE_SOURCING_DETAILS);
  };

  const columns = [
    {
      name: "Single Source Number",
      cell: (row) => <>
          <button className="btnInfo" onClick={() => detailedPage(row.singleSourceRequestNr)}>
            {row.singleSourceRequestNr}
          </button>
      </>,
      width: "150px",
    },
    {
      name: "Collaboration",
      cell: (row) => <>
      {row.employeeName}
      </>,
      width: "150px",
    },
    {
      name: "Request Date",
      cell: (row) => <>
      {row.createdAt}
      </>,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => <>{row.ssStatusHeader}</>,
      width: "150px",
    },
    {
      name: "PR Number",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "SupplierName",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "TotalValue",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Document Reference",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Single Source Form",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Employee",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Department",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "SCM Buyer",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Pending With W/F",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Workflow Withdraw",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Company Code",
      cell: (row) => <></>,
      width: "150px",
    },
  ];

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Single Source Purchases List</h2>
                <p className="subText">Single Source Purchases List</p>
              </Col>
              <hr />
            </Row>

            {/* Advance Payment Request details  -1*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SL</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Single Source Purchases List
                      </span>
                      <span className="subHeaderTitle">
                        Single Source Purchases List
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={columns}
                          data={data}
                          // progressPending={singleSourcingData?.status}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default SingleSourceList;
