import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getSesList } from "../../../../redux/feature/supplier_app/purchasingSlice";

//API
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import Constants from "../../../../common/Constants";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import { currencyFormat } from "../../../../common/Validations";

const CreateSES = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "Service PO");

  let selectedPoDetails = useSelector(
    (state) => state?.purchasing?.purchase_order?.selectedPoDetails
  ); // selectedPoDetails Details from Store

  let poNumber = useSelector(
    (state) => state?.purchasing?.purchase_order?.poNumber
  ); // purchase_order from Store

  let subTransactionActivity = useSelector(
    (state) => state?.purchasing?.goods_received_note?.subTransactionActivity
  ); // User Details from Store

  let getSelectedItemList = useSelector(
    (state) => state?.purchasing?.purchase_order?.itemList
  ); // User Details from Store

  const modalData = {
    title: "SES Creation",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [disabled, setDisabled] = useState(false);
  const [getHeaderData, setHeaderData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemTableData, setItemTableData] = useState([]);
  const [subTotalValue, setSubTotalValue] = useState(0);

  // const [getOtherChargesValue, setOtherChargesValue] = useState(0);

  // const [getItemLevelData, setItemLevelData] = useState([]);
  // const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  // const [getModalTitle, setModalTitle] = useState("");
  // const [getViewHeaderText, setViewHeaderText] = useState(false);
  // const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const [formInputs, setFormInputs] = useState({
    subTransactionActivity: subTransactionActivity,
    documentType: "SES",
    transactionActivity: "SES",
    purchasingDocumentNr: poNumber,
    purchasingDocumentDate: "",
    sesStatusHeader: "SES Created",
    sesDate: "",
    despatchNr: "",
    despatchDate: "",
    vendorCode: "",
    vendorName: "",
    shippingType: "",
    sesRemark: "",
    sesCount: "1",
    purchasingDocumentValue: "",
    deliveredValue: "",
    deliveredStatus: "",
    invoicedValue: "",
    invoicedStatus: "",
    paidValue: "",
    currency: "",
    currencyCode: "",
    paymentTerms: "",
    deliveryNoteNr: "",
    billOfLading: "",
    incoTerms: "",
    vendorInvoiceNo: "",
    vendorInvoiceDate: "",
    vendorBasicValue: "",
    vendorTaxValue: "",
    vendorOtherChargesValue: "",
    vendorTotalInvoiceValue: "",
    vendorInvoiceCurrency: "",
    items: [
      {
        sesItemNr: "",
        materialCode: "",
        materialDescription: "",
        purchaseQuantity: "",
        sesQuantity: "",
        sesBalancePurchaseQuantity: "",
        currency: "",
        orderUnitOfMeasure: "",
        sesStatusItem: "New",
        supplierBatchNumber: "",
        plant: "",
        storeLocation: "",
        purchasingDocumentNr: "",
        purchasingDocumentItemNr: "",
        grossWeight: "",
        sesDeletionIndicator: "",
        cancellationRemarks: "",
        cancelledBy: "",
        cancelledOn: "",
        cancelledTime: "",
      },
    ],
  });

  const PriceSheduleColumns = [
    {
      name: "SES Item Nr",
      selector: (row) => row.sesItemNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "PO Item Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-success-status">
            {row.statusItem}
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },
    {
      name: "SES Qty",
      selector: (row) => row.serviceQuantity,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="purchaseQuantity"
            value={row.serviceQuantity}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "SES Qty",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="sesQuantity"
            name="sesQuantity"
            value={row.sesQuantity}
            onChange={(e) =>
              handleInputChangeTableSES(
                row.local_id,
                e.target.value,
                row.serviceQuantity,
                row.unitValue,
                row?.ses?.sesBalancePurchaseQuantity
              )
            }
            disabled={row?.ses?.sesBalancePurchaseQuantity === 0 ? true : false}
          />
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Balance Qty",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name={"sesBalancePurchaseQuantity"}
            value={row.sesBalancePurchaseQuantity || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      // selector: (row) => row.unitValue,
      cell: (row) => (
        <span className="numberFormate">
          {currencyFormat(Number(row.unitValue).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      selector: (row) => row.totalPrice,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="totalPrice"
            value={row.totalPrice || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Price Unit Qty",
    //   selector: (row) => row.priceUnit,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Tax Code",
      selector: (row) => row.taxId,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "Free Of Cost",
      selector: (row) => row.freeCost,
      sortable: true,
      width: "200px",
    },
    {
      name: "PReq Nr",
      selector: (row) => row.purchaseReqNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "PReq Item Nr ",
      selector: (row) => row.purchaseReqitemNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "ExcessTolerance",
      selector: (row) => row.excessTolerance,
      sortable: true,
      width: "200px",
    },
    {
      name: "ShortageTolerance",
      selector: (row) => row.shortageTolerance,
      sortable: true,
      width: "200px",
    },
  ];

  const handleInputChangeTableSES = (
    id,
    value,
    purchaseQuantity,
    unitValue,
    sesBalancePurchaseQuantity
  ) => {
    if (Number(purchaseQuantity) >= Number(value)) {
      let totalPriceVal = Number(unitValue) * Number(value);

      if (sesBalancePurchaseQuantity) {
        if (Number(value) <= Number(sesBalancePurchaseQuantity)) {
          const updatedData = itemTableData.map((item) => {
            return item.local_id === id
              ? {
                  ...item,
                  sesQuantity: value,
                  sesBalancePurchaseQuantity: 
                  item.ses.sesBalancePurchaseQuantity
                    ? Number(item.ses.sesBalancePurchaseQuantity) -
                      Number(value)
                    : Number(purchaseQuantity) - Number(value),
                  totalPrice: totalPriceVal,
                }
              : item;
          });

          setItemTableData(updatedData);
          getTotalCount(updatedData);
        }
      } else {
        const updatedData = itemTableData.map((item) => {
          return item.local_id === id
            ? {
                ...item,
                sesQuantity: value,
                sesBalancePurchaseQuantity:
                  Number(purchaseQuantity) - Number(value),
                totalPrice: totalPriceVal,
              }
            : item;
        });

        setItemTableData(updatedData);
        getTotalCount(updatedData);
      }
    } else {
      // console.log("Do Nothing");
    }
  };

  // Get Total for SubTotal Pricing Schedule
  const getTotalCount = (data) => {
    const getTotal = data.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.totalPrice || 0),
      0
    );
    setSubTotalValue(getTotal);
  };

  //load SES data on page load
  const loadInitalPageData = async () => {
    setLoading(true);
    if (subTransactionActivity === "PO") {
      if (poNumber) {
        setHeaderData(selectedPoDetails);

        let obj = {};

        if (getSelectedItemList.length > 0) {
          obj = {
            purchasingDocumentNr: poNumber,
            purchasingDocumentItemNr:
              getSelectedItemList[0].purchasingDocumentItemNr,
          };
        } else {
          obj = {
            purchasingDocumentNr: poNumber,
          };
        }

        await ItemService.createSES(obj).then((item) => {
          // console.log(">> res! createSES", item);
          if (item?.status === 200) {
            const updatedItemData = item?.data.map((obj, index) => ({
              ...obj,
              sesItemNr: index + 1,
              local_id: index + 1,
              originalBalancePurchaseQuantity: obj.invBalancePurchaseQuantity,
              invItemTaxValue: 0,
              purchasingDocumentNr: poNumber,
            }));

            setItemTableData(updatedItemData);
          } else {
            toast.error(item?.message || "Please try again !!", {
              duration: 4000,
              position: "top-right",
            });
          }
        });

        setLoading(false);
      } else {
        window.history.back();
      }
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    loadInitalPageData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poNumber, selectedPoDetails]);

  // SAVE
  const handleSave = async () => {
    // let requiredFields = [];

    // if(!validateRequired(formInputs?.organisationName)) {
    //   requiredFields.push('*Please enter Supplier Name');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!validateEmail(formInputs?.email)) {
    //   requiredFields.push('*Invalid Contact Email address');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!getPOLineItemsData[0]?.materialCode) {
    //   toast.error("Alteast one PO Line item should be added.", {
    //     duration: 4000,
    //     position: "top-right",
    //   });
    //   return;
    // }
    
    const userEnteredItemTableData = itemTableData.filter(item => item.sesQuantity != null);

    formInputs.items = userEnteredItemTableData;
    formInputs.vendorCode = getHeaderData.vendorCode;
    formInputs.vendorName = getHeaderData.vendorName;
    formInputs.deliveredValue = getHeaderData.deliveredValue;
    formInputs.deliveredStatus = getHeaderData.deliveredStatus;
    formInputs.invoicedValue = getHeaderData.invoicedValue;
    formInputs.invoicedStatus = getHeaderData.invoicedStatus;
    formInputs.paidValue = getHeaderData.paidValue;
    formInputs.vendorInvoiceCurrency = getHeaderData.currencyCode;
    formInputs.purchasingDocumentDate = getHeaderData.purchasingDocumentDate;
    formInputs.incoTerms = getHeaderData.incoTerms;
    formInputs.paymentTerms = getHeaderData.paymentTerms;
    formInputs.purchasingDocumentValue = getHeaderData.purchasingDocumentValue;
    formInputs.currency = getHeaderData.currency;
    formInputs.currencyCode = getHeaderData.currencyCode;
    formInputs.sumOfSes = subTotalValue;

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addEntrysheet(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();

        // Update response value in Redux Store
        dispatch(getSesList());
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const navigateToWorkflow = () => {
    window.history.back();
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} lg={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={12} lg={12} className="mb-2">
                <h2 className="mainTitle">
                  {subTransactionActivity === "PO" && (
                    <>
                      Create SES for {getHeaderData?.purchasingDocumentNr}
                      <span>
                        {getHeaderData?.statusHeader === "New" ? (
                          <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                            {getHeaderData?.statusHeader}
                          </span>
                        ) : getHeaderData?.statusHeader === "In Release" ? (
                          <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                            {getHeaderData?.statusHeader}
                          </span>
                        ) : getHeaderData?.statusHeader === "Acknowledged" ? (
                          <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                            {getHeaderData?.statusHeader}
                          </span>
                        ) : (
                          getHeaderData?.statusHeader
                        )}
                      </span>
                    </>
                  )}
                </h2>
                <p className="subText">Fill SES Details</p>
              </Col>
              <hr />
            </Row>

            <Col xs={12} md={4} lg={4} className="text-left mb-4">
              <button className="btnBack" onClick={() => navigateToWorkflow()}>
                <i className="bi bi-arrow-return-left"></i> Go Back
              </button>
            </Col>

            {/*Document Header Details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DH</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">
                        {" "}
                        Document Header Details
                      </span>
                      <span className="subHeaderTitle">
                        Purchase Order Header Details
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Number"
                          name="purchasingDocumentNr"
                          placeholder=""
                          value={getHeaderData?.purchasingDocumentNr}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status:"
                          name="statusHeader"
                          placeholder=""
                          value={getHeaderData?.statusHeader}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col> */}

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Date"
                          name="purchasingDocumentDate"
                          placeholder=""
                          value={getHeaderData?.purchasingDocumentDate}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      {/* {getHeaderData?.purchasingDocumentValue && ( */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Net Value"
                          name="purchasingDocumentValue"
                          placeholder=""
                          value={currencyFormat(
                            Number(
                              getHeaderData?.purchasingDocumentValue
                            ).toFixed(2)
                          )}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          placeholder=""
                          value={`${getHeaderData?.currencyCode} - ${getHeaderData?.currency}`}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      {/* )} */}

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivered Value:"
                          name="deliveredValue"
                          placeholder=""
                          id="deliveredValue"
                          value={formInputs.deliveredValue}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivered Status"
                          name="deliveredStatus"
                          placeholder=""
                          id="deliveredStatus"
                          value={formInputs.deliveredStatus}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoiced Value:"
                          name="invoicedValue"
                          placeholder=""
                          id="invoicedValue"
                          value={formInputs.invoicedValue}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoiced Status"
                          name="invoicedStatus"
                          placeholder=""
                          id="invoicedStatus"
                          value={formInputs.invoicedStatus}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Paid Value"
                          name="paidValue"
                          placeholder=""
                          id="paidValue"
                          value={formInputs.paidValue}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Code"
                          name="vendorCode"
                          id="vendorCode"
                          value={getHeaderData?.vendorCode}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Name"
                          name="vendorName"
                          id="vendorName"
                          value={getHeaderData?.vendorName}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          name="incoTerms"
                          id="incoTerms"
                          value={getHeaderData?.incoTerms}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="incopaymentTermsTerms"
                          id="paymentTerms"
                          value={getHeaderData?.paymentTerms}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Transaction Activity"
                          name="subTransactionActivity"
                          id="subTransactionActivity"
                          value={subTransactionActivity}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SES Total Amount"
                          name="sumOfSes"
                          id="sumOfSes"
                          value={subTotalValue}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery / Invoice No"
                          name="deliveryNoteNr"
                          id="deliveryNoteNr"
                          value={formInputs.deliveryNoteNr}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bill Of Lading"
                          name="billOfLading"
                          id="billOfLading"
                          value={formInputs.billOfLading}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Delivery Remarks "
                          name="sesRemark"
                          value={formInputs.sesRemark}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Price Shedule / Line Item */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={8} md={6} lg={6}>
                      <span className="headerTitle">
                        Price Shedule / Line Item
                      </span>
                      <span className="subHeaderTitle">
                        Price Shedule / Line Item
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2} className="">
                      <button className="btnTable">
                        <i className="bi bi-file-excel"></i>Template
                      </button>
                    </Col>
                    <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-file-excel"></i> Upload
                      </button>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={PriceSheduleColumns}
                          data={itemTableData}
                          progressPending={loading}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/*ses attachments*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">SES Attachment</span>
                      <span className="subHeaderTitle">SES Attachment</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4 d-flex justify-content-between">
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Shipping Documents "
                          name=""
                          type="file"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Other Attachments "
                          name=""
                          type="file"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* buttons */}
          <div className="pageFooter">
            <Row className="rowDirection">
              <Col xs={12} md={2} lg={2} className="">
                <button
                  className="btn w-full"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE}
      />
    </main>
  );
};

export default CreateSES;
