import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../services/supplier_appCommonService";

//Components
import Layout from "../../../Layout/Layout";
import CreateDocTypeModal from "../../../Components/Modal/supplier_app/CreateDocTypeModal";
import Constants from "../../../common/Constants";
import CommonItemLevelDatatableModal from "../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { addRfxNr } from "../../../redux/feature/supplier_app/sourcingSlice";

const BuyerRequestForQuotationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Buyer Request for Quotation");
  let rfqData = useSelector((state) => state?.sourcing?.rfq?.data); // User Details from Store


  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Buyer RFQ Details",
      cell: (row) => (
        <button className="btnInfo" onClick={() => detailedPageBuyer(row.rfxNr)}>
          {row.rfxNr}{" "}
        </button>
      ),
      width: "160px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemLevelShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },

    {
      name: "Version",
      selector: (row) => row.rfxVersion,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.rfxHeaderStatus === "In Release" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "RFQ Created" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.rfxHeaderStatus}
            </span>
          ) : (
            row.rfxHeaderStatus
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Title",
      selector: (row) => row.rfxTitle,
      sortable: true,
      width: "250px",
    },

    {
      name: "Supplier List",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => supplierListShowModal(row.supplierList)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.supplierList?.length}{" "}
          </span>
        </>
      ),
      width: "120px",
    },

    {
      name: "SAP RFQ",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SAP Requisition",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: " SAP Order ",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: " Value",
      selector: (row) => row.prValue,
      sortable: true,
      width: "120px",
    },
    {
      name: "Currency",
      selector: (row) => row.rfxCurrency,
      sortable: true,
      width: "100px",
    },

    {
      name: "Countdown Timer",
      selector: (row) => row.closingDate + " - " + row.openingDate,
      sortable: true,
      width: "270px",
    },
    {
      name: "Open Date ",
      selector: (row) => row.openingDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Close Date",
      selector: (row) => row.closingDate,
      sortable: true,
      width: "160px",
    },
    {
      name: "Procurment Category",
      selector: (row) => row.rfxCategory,
      sortable: true,
      width: "200px",
    },
    {
      name: "Technical User",
      selector: (row) => row.rfxTechFocalPerson,
      sortable: true,
      width: "130px",
    },
    {
      name: "Tech Target Date",
      selector: (row) => row.rfxTechTargetDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Extension Count",
      selector: (row) => row.extensionCount,
      sortable: true,
      width: "140px",
    },

    {
      name: "Publish",
      cell: (row) => (
        <>
          <button className="btnTable">Approved {row.publish}</button>
        </>
      ),
      width: "140px",
    },
    {
      name: "Technical",
      cell: (row) => (
        <>
          <button className="btnTable" onClick={() => technicalEvaluation()}>
            TE Prepare
          </button>
          {/* <button className="btnTable">Approved {row.techOpen}</button> */}
        </>
      ),
      width: "140px",
    },
    {
      name: "Commercial",
      cell: (row) => (
        <>
          <button className="btnTable" onClick={() => commercialEvaluation()}>
            CE Prepare
          </button>
          {/* <button className="btnTable">Approved {row.commOpen}</button> */}
        </>
      ),
      width: "140px",
    },
    {
      name: "Order",
      cell: (row) => (
        <>
          <button className="btnTable" onClick={() => createPO()}>
            Create PO
          </button>
          {/* <button className="btnTable" onClick={() => createInvoice() >Approved {row.order}</button> */}
        </>
      ),
      width: "140px",
    },
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.legalEntity} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Buyer Responsible",
      selector: (row) => row.buyerName,
      sortable: true,
      width: "190px",
    },
    {
      name: "Buyer Contact",
      selector: (row) => row.buyeremailId,
      sortable: true,
      width: "210px",
    },
    {
      name: "Envelope Step",
      selector: (row) => row.rfqEnvelopType,
      sortable: true,
      width: "125px",
    },
    {
      name: "Envelope Type",
      cell: (row) => (
        <>
          <span className="badge bg-danger">{row.bidType}</span>
        </>
      ),
      width: "115px",
    },
    {
      name: "Product",
      selector: (row) => row.productType,
      sortable: true,
      width: "110px",
    },
  ];

  const supplierListColInfo = [
    {
      name: "Supplier Name",
      selector: (row) => row.AssociateNumber,
      sortable: true,
      width: "180px",
    },
    {
      name: "Supplier Name",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Supplier Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Non Invited" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.status}
            </span>
          ) : row.status === "Invited" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.status}
            </span>
          ) : row.status === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.status}
            </span>
          ) : row.status === "Registered" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.status}
            </span>
          ) : row.status === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.status}
            </span>
          ) : (
            row.status
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Quotation Status",
      cell: (row) => (
        <>
          <span className="badge rounded-pill bg-info text-dark">
            {row.quotationStatus}
          </span>
        </>
      ),
      width: "180px",
    },
    {
      name: "Mail",
      selector: (row) => row.email || "--",
      sortable: true,
      width: "250px",
    },
    {
      name: "Contact",
      selector: (row) => row.contactNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      width: "140px",
    },
    {
      name: "ICV Validity",
      selector: (row) => row.icvValidity,
      sortable: true,
      width: "160px",
    },
    {
      name: "ICV Value",
      selector: (row) => row.icvValue,
      sortable: true,
      width: "150px",
    },
  ];

  const LineItemsColInfo = [
    {
      name: "RFQ Item Number",
      selector: (row) => row.schRfqItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "PR Number",
      cell: (row) => (
        <>
          <span className="badge bg-warning text-dark">
            {row.purchaseDocumentNr}
          </span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Material Code",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "250px",
    },
    {
      name: "Material Desc",
      selector: (row) => row.briefDescription,
      sortable: true,
      width: "250px",
    },
    {
      name: "Quantity Unit",
      selector: (row) => row.prQuantityUnit || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      width: "250px",
    },
    {
      name: "Item Rate",
      selector: (row) => row.internalRate,
      sortable: true,
      width: "250px",
    },
  ];


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  // const [perPage, setPerPage] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [getPurchaseDocType, setPurchaseDocType] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on Line Item Level Data
  const lineItemLevelShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(LineItemsColInfo);
    setModalTitle("Line Item Level Details");
    setItemLevelModalShow(true);
  };

  // OnClick on Supplier List Data
  const supplierListShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(supplierListColInfo);
    setModalTitle("Supplier List Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  /* Modal Function */
  let obj = {
    modalTitle: "Create Manual RFQ",
    navigate: Constants.SOURCING_CREATE_RFQ
  };
  const createRFQ = () => {
    setModalData(obj);
    setModalShow(true);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  // eslint-disable-next-line no-unused-vars
  const detailedPageVendor = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_VENDOR_RFQ_DETAILS);
  };
  const detailedPageBuyer = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_BUYER_RFQ_DETAILS);
  };
  
  const fetchUsers = async (page) => {
    setLoading(true);
    // console.log(">> Here is printing > ", page);
    // const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`);

    setData(rfqData);
    // setTotalRows(rfqData?.length);
    setLoading(false);
  };

  //Create TE
  const technicalEvaluation = () => {
    navigate(Constants.SOURCING_TECHNICAL_EVALUATION);
  };
  //Create CE
  const commercialEvaluation = () => {
    navigate(Constants.SOURCING_COMMERCIAL_EVALUATION);
  };
  //Create PO
  const createPO = () => {
    navigate(Constants.SOURCING_PURCHASE_ORDER_CREATION);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqData]);

  const getMasterData = async () => {
    let obj = {
      codeType: "RFQCategory",
    };
    await ItemService.getMasterData(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setPurchaseDocType(item?.data);
      } else {
        setPurchaseDocType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  //on page load
  useEffect(() => {
    getMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Buyer Request For Quotation List</h2>
            <p className="subText">Buyer Request For Quotation List</p>
            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RQ</span>
                    </Col>
                    <Col xs={12} md={5} lg={5}>
                      <span className="headerTitle">Buyer Request For Quotation</span>
                      <span className="subHeaderTitle">
                        View of Request For Quotation{" "}
                      </span>
                    </Col>

                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-plus-lg"></i> Change Buyer
                      </button>
                    </Col> */}

                    <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable" onClick={() => createRFQ()}>
                        <i className="bi bi-plus-lg"></i> Create Manual
                      </button>
                    </Col>

                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-copy"></i> Copy
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-person"></i> Buyer Change
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-recycle"></i> Status Change
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-x-circle"></i> Cancel
                      </button>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CreateDocTypeModal
        show={modalShow}
        onHide={() => hideModal()}
        modaldata={modalData}
        dropdowndata={getPurchaseDocType}
        navigate={Constants.SOURCING_CREATE_RFQ}
      />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default BuyerRequestForQuotationList;
