/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../Components/formElements/TextAreaField";
import RiverseTimer from "../../../Components/RiverseTimer";
import Constants from "../../../common/Constants";
import CreateOtherChanges from "../../../Components/Modal/supplier_app/CreateQuotation/CreateOtherChanges";
import PriceShedule from "../../../Components/Modal/supplier_app/CreateQuotation/PriceShedule";
import DynamicForm from "../../../Components/Modal/supplier_app/CreateQuotation/DynamicForm";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";

const CreateQuotation = () => {
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");

  // let supplierData = useSelector(
  //   (state) => state?.supplier.data
  // ); /* suplierlist  data from suplier */

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  /*  let dynamicFormOption=[
    {id:"1",name:'Vertical'},
    {id:"2",name:'Sequetional'}, 
  ]
  console.log('dynamicform:',dynamicFormOption.name) */
  const [dynamicFormOption, setDynamicFormOption] = useState([
    { id: "1", name: "Vertical" },
    { id: "2", name: "Sequetional" },
  ]);
  const [selectedDynamicForm, setSelectedDynamicForm] = useState(
    dynamicFormOption[0]
  ); // Default is 'Vertical'
  const handleOptionChange = (option) => {
    setSelectedDynamicForm(option);
    // console.log("selectedDynamicForm::", selectedDynamicForm);
  };
  const createDynamicForm = () => {
    // console.log("create new dyanmic form");
    setdyanmicformCount(dyanmicformCount + 1);
    setdyanamicFormData([...dyanamicFormData, null]);
  };
  const delteDyanmicForm = (index) => {
    if (dyanmicformCount > 1) {
      // Prevent deleting the only div
      const updatedyanmicFormdata = [...dyanamicFormData];
      updatedyanmicFormdata.splice(index, 1); // Remove element at specified index
      setdyanamicFormData(updatedyanmicFormdata);
      setdyanmicformCount(dyanmicformCount - 1); // Decrement div count
    } else {
      alert("Cannot delete the last div!"); // Inform user about limitation
    }
  };

  // const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);

  const [getAccessData, setAccessData] = useState("");
  const [getDisabledStatus, setDisabledStatus] = useState(true);

  const [loading, setLoading] = useState(false);
  const [otherchangesmodelshow, setotherchangesmodelshow] = useState(false);
  const [DynamicFormModelshow, setDynamicFormModelshow] = useState(false);
  const [PriceShedulemodalShow, setPriceShedulemodalShow] = useState(false);
  const [PriceSheduleModuledata, setPriceSheduleModuledata] = useState([]);
  const [dyanmicformCount, setdyanmicformCount] = useState(1);
  const [dyanamicFormData, setdyanamicFormData] = useState(
    Array(dyanmicformCount).fill(null)
  );
  const [toggleDynamicform, settoggleDynamicform] = useState(false);

  // console.log("PriceSheduleModuledata>>", PriceSheduleModuledata);
  /* data from createotherchanges */
  const [CreateOtherchangedata, setCreateOtherchangedata] = useState([]);
  // console.log('CreateOtherchangedata:',CreateOtherchangedata)
  const handleDataFromCreateOtherChanges = (data) => {
    // console.log("Data received from child:", data);
    setCreateOtherchangedata((prevData) => [...prevData, data]);
  };
  const handleDelete = (indexToDelete) => {
    setCreateOtherchangedata((prevData) =>
      prevData.filter((_, index) => index !== indexToDelete)
    );
  };

  /*  */
  const handelDataPriceShedule = (data) => {
    // console.log('priceSheduleData>>',data)
    setPriceSheduleModuledata(data);
  };

  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  /* other changes popup  Modal Function */
  const createotherchangesmodel = () => {
    setotherchangesmodelshow(true);
  };

  const hideOtherChangesModel = () => {
    setotherchangesmodelshow(false);
  };
  /* dyanamic form popup  Modal Function */
  const createDynamicformModel = () => {
    setDynamicFormModelshow(true);
  };

  const hideDynamicFormModel = () => {
    setDynamicFormModelshow(false);
  };

  /* Price Shedule handling function */
  const CreatePriceSheduleModel = () => {
    setPriceShedulemodalShow(true);
  };
  const hidePriceSheduleModal = () => {
    setPriceShedulemodalShow(false);
  };
  /* dynami form  */

  /* table 1 */
  const PriceSheduleDatas = [
    {
      editeIcon: "",
      profile: 1,
      REQitem: 1,
      PRNUMBER: "4509164863",
      PRLine: "00025",
      Mat_serCode_desc: "4600084 CUTNG FLD-LOW VISCOSITY",
      Headere: "--",
      ItemText:
        "FUCHSECOCout FE1520(SUPERESEDED)FUCHS ECOCUT 1520 (205L/DRUM)HOUGHTON MACRON EDM 130(209L/DRUM)TOTAL DIEL MS 5000 (FLUXELF 2)(208L/DRUM)",
      materialPOtext:
        "CUTTING FLUD SPECIFICATION:SPARK EROSION:205/208/209 LTR/DRUM,VISCOSITY RATING:LOW VISCOSITY,",
      RFQ_quantity: "100.000",
      UOM: "DR",
      Priceunit_qty: "--",
      unitPrice: "input box",
      itemValue: "0.00",
      RequirementDate: "Mar 29, 2024",
      DeliveryLeadTime_days: "input box",
      serviceDate: "Date input",
    },
    {
      editeIcon: "",
      profile: 1,
      REQitem: 1,
      PRNUMBER: "4509164863",
      PRLine: "00025",
      Mat_serCode_desc: "4600084 CUTNG FLD-LOW VISCOSITY",
      Headere: "--",
      ItemText:
        "FUCHSECOCout FE1520(SUPERESEDED)FUCHS ECOCUT 1520 (205L/DRUM)HOUGHTON MACRON EDM 130(209L/DRUM)TOTAL DIEL MS 5000 (FLUXELF 2)(208L/DRUM)",
      materialPOtext:
        "CUTTING FLUD SPECIFICATION:SPARK EROSION:205/208/209 LTR/DRUM,VISCOSITY RATING:LOW VISCOSITY,",
      RFQ_quantity: "100.000",
      UOM: "DR",
      Priceunit_qty: "--",
      unitPrice: "input box",
      itemValue: "0.00",
      RequirementDate: "Mar 29, 2024",
      DeliveryLeadTime_days: "input box",
      serviceDate: "Date input",
    },
    {
      editeIcon: "",
      profile: 1,
      REQitem: 1,
      PRNUMBER: "4509164863",
      PRLine: "00025",
      Mat_serCode_desc: "4600084 CUTNG FLD-LOW VISCOSITY",
      Headere: "--",
      ItemText:
        "FUCHSECOCout FE1520(SUPERESEDED)FUCHS ECOCUT 1520 (205L/DRUM)HOUGHTON MACRON EDM 130(209L/DRUM)TOTAL DIEL MS 5000 (FLUXELF 2)(208L/DRUM)",
      materialPOtext:
        "CUTTING FLUD SPECIFICATION:SPARK EROSION:205/208/209 LTR/DRUM,VISCOSITY RATING:LOW VISCOSITY,",
      RFQ_quantity: "100.000",
      UOM: "DR",
      Priceunit_qty: "--",
      unitPrice: "input box",
      itemValue: "0.00",
      RequirementDate: "Mar 29, 2024",
      DeliveryLeadTime_days: "input box",
      serviceDate: "Date input",
    },
  ];

  const PriceSheduleColumns = [
    {
      name: "",
      cell: (row) => (
        <>
          <span onClick={CreatePriceSheduleModel}>
            <i className="bi bi-pencil-square"></i>
          </span>
        </>
      ),
      width: "50px",
    },
    {
      name: "Profile",
      width: "100px",
      cell: (row) => (
        <div className="createquotation">
          <p className="fs-4 fw-medium otherChanges">1</p>
          {/* <img
          src="assets/img/profile-img.png"
          alt="Profile"
          className="rounded-circle profileImageCss"
          style={{ width: "40px" }}
        /> */}
        </div>
      ),
    },
    {
      name: "RFQ Item",
      selector: (row) => row.REQitem || "--",
      sortable: true,
      width: "120px",
    },
    {
      name: "PR Number",
      cell: (row) => (
        <>
          <p>{row.PRNUMBER}</p>
        </>
      ),
      width: "120px",
    },
    {
      name: "PR Line",
      //selector: (row) => row.version || "--",
      // sortable: true,
      cell: (row) => (
        <>
          <p>{row.PRLine}</p>
        </>
      ),
      width: "120px",
    },
    {
      name: "Mat/Ser Code/Desc",
      cell: (row) => (
        <>
          <p>{row.Mat_serCode_desc}</p>
        </>
      ),
      width: "120px",
    },

    {
      name: "Header Mat/Ser Code/Desc",
      cell: (row) => (
        <>
          <p>{row.Headere}</p>
        </>
      ),
      // sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <p>{row.ItemText}</p>
        </>
      ),
      // sortable: true,
      width: "200px",
    },
    {
      name: "Material PO Text",
      cell: (row) => (
        <>
          <p>{row.materialPOtext}</p>
        </>
      ),
      // sortable: true,
      width: "200px",
    },

    {
      name: "RFQ Quantity",
      cell: (row) => (
        <>
          <p>{row.RFQ_quantity}</p>
        </>
      ),
      // sortable: true,
      width: "150px",
    },
    {
      name: "UOM",
      cell: (row) => (
        <>
          <p>{row.UOM}</p>
        </>
      ),
      // sortable: true,
      width: "100px",
    },
    {
      name: "Price Unit Qty",
      cell: (row) => (
        <>
          <p>{row.Priceunit_qty}</p>
        </>
      ),
      // sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      cell: (row) => (
        <>
          <InputField
            className="inputBox"
            /* label="Closing Date:" */
            name="UnitPrice"
            // placeholder=""
            // value={PriceSheduleModuledata.UnitPrice || ""}

            onChange={onInputChange}
            disabled={false}
          />
        </>
      ),
      // sortable: true,
      width: "200px",
    },

    {
      name: "Item Value",
      cell: (row) => (
        <>
          <p>{row.itemValue}</p>
        </>
      ),
      // sortable: true,
      width: "150px",
    },
    {
      name: "Requirement Date",
      cell: (row) => (
        <>
          <p>{row.RequirementDate}</p>
        </>
      ),
      // sortable: true,
      width: "150px",
    },
    {
      name: "Delivery Lead Time (Days)",
      cell: (row) => (
        <>
          <InputField
            className="inputBox"
            /* label="Closing Date:" */
            name="DeliveryLeadTimeDays"
            // placeholder=""
            // value={PriceSheduleModuledata.DeliveryLeadTimeDays||""}
            // onChange={onInputChange}
            disabled={false}
          />
        </>
      ),
      // sortable: true,
      width: "200px",
    },
    {
      name: "Service Start Date",
      cell: (row) => (
        <>
          <InputField
            className="inputBox"
            /* label="Closing Date:" */
            type="date"
            name="Date"
            //  placeholder=""
            value={PriceSheduleModuledata.Date || ""}
            //  onChange={onInputChange}
            disabled={true}
          />
        </>
      ),
      // sortable: true,
      width: "200px",
    },
  ];
  /* table for other changes */
  const OtherChangesColumns = [
    {
      name: "",
      cell: (row) => (
        <>
          <span></span>
        </>
      ),
      width: "5rem",
    },
    {
      name: "name",
      cell: (row) => (
        <>
          <span>{row.description}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Vlaue",
      cell: (row) => (
        <>
          <span>{row.value}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Delete",
      cell: (row, index) => (
        <>
          <span>
            <i
              onClick={() => handleDelete(index)}
              className="bi bi-trash text-danger fs-3"
            ></i>
          </span>
        </>
      ),
      width: "10rem",
    },
  ];

  /* handel dynamic form new */
  const [elements, setElements] = useState([
    { type: "input", label: "Input 1" },
    { type: "textarea", label: "Textarea 1" },
    { type: "select", label: "Select 1" },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  console.log("editdata", editData);
  /*  const handleAddElement = () => {
    setEditData(null);
    setShowModal(true);
  }; */
  const handleEditButtonClick = (index) => {
    setEditData({ ...elements[index], index });
    // setShowModal(true);
    setDynamicFormModelshow(true);
  };
  const handleSaveModal = (data) => {
    if (editData && editData.index !== undefined) {
      const updatedElements = elements.map((element, i) =>
        i === editData.index ? data : element
      );
      setElements(updatedElements);
    } else {
      setElements([...elements, data]);
    }
    setDynamicFormModelshow(false);
  };
  const renderElement = (element, index) => {
    return (
      <div key={index}>
        {element.type === "input" && (
          <Col
            xs={12}
            md={4}
            lg={selectedDynamicForm.name === "Vertical" ? 12 : 3}
            className="commTopButtonRightLeftPadding "
          >
            <InputField
              className="inputBox"
              // label="1.4Completion/Delivery Period from the date of OA/PO (Days)"
              id={`${element.type}-${index}`}
              // placeholder={element.label}
              label={element.label}
              name="UnitPrice"
              // placeholder=""
              // value={PriceSheduleModuledata.UnitPrice || ""}

              onChange={onInputChange}
              disabled={false}
            />
          </Col>
        )}
        {element.type === "textarea" && (
          /*   <textarea
            id={`${element.type}-${index}`}
            placeholder={element.label}
          /> */
          <Col
            xs={12}
            md={4}
            lg={selectedDynamicForm.name === "Vertical" ? 12 : 3}
            className="commTopButtonRightLeftPadding "
          >
            <TextAreaField
              className="inputBox"
              // label="Notes "
              label={element.label}
              name=""
              id={`${element.type}-${index}`}
              // placeholder={element.label}
              // value={""}
              onChange={onInputChange}
              disabled={false}
            />
          </Col>
        )}
        {element.type === "select" && (
          <Col
            xs={12}
            md={4}
            lg={selectedDynamicForm.name === "Vertical" ? 12 : 3}
            className="commTopButtonRightLeftPadding "
          >
            <ComboboxField
              // label="1.3 Bid/Offer Validity in Days."
              id={`${element.type}-${index}`}
              placeholder={element.label}
              data={dropdownOption}
              label={element.label}
              iconClassName="dropdownIcon"
              name="INCOTermslocation"
              setValue={formInputs.INCOTermslocation || ""}
              getvalue={setAccessData}
              // className="dropdownOption"
            />
          </Col>
        )}
        <button variant="link" onClick={() => handleEditButtonClick(index)}>
          Edit
        </button>
      </div>
    );
  };
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Quotation</h2>
            <p className="subText">Fil Quotatoin Details</p>
            <hr />

            {/*Remaining Time for submission  */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={6} md={5} lg={5} className="">
                      <p className="fs-4">Remaining Time for submission</p>
                    </Col>
                    <Col xs={6} md={1} lg={1} className="">
                      <i className="bi bi-clock fs-1"></i>
                    </Col>
                    <Col className="d-flex" xs={12} md={4} lg={4}>
                      <RiverseTimer days={7} />
                    </Col>
                  </Row>
                </h2>
              </div>
            </div>
            {/*quotation Header */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Header</span>
                      <span className="subHeaderTitle">Header Description</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code"
                          name="firstName"
                          placeholder=""
                          value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="firstName"
                          placeholder=""
                          value={"ESA7954"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Number"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Address"
                          name="firstName"
                          placeholder=""
                          value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Version"
                          name="firstName"
                          placeholder=""
                          value={" v1"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Created On"
                          name="firstName"
                          placeholder=""
                          value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Billing Address"
                          name="firstName"
                          placeholder=""
                          value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Type"
                          name="firstName"
                          placeholder=""
                          value={" Technical and Commercial"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Version"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="firstName"
                          placeholder=""
                          value={"Matatha Babu"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Product Type"
                          name="firstName"
                          placeholder=""
                          value={"Service"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Submitted By"
                          name="firstName"
                          placeholder=""
                          value={" VEND3019"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="firstName"
                          placeholder=""
                          value={"saranaya.m@eimsolutions.com / 123-09879"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number Of Evaluation"
                          name="firstName"
                          placeholder=""
                          value={"2"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Submitted On"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bid Type"
                          name="firstName"
                          placeholder=""
                          value={"Closed"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Currency"
                          name="firstName"
                          placeholder=""
                          value={"AED"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Status"
                          name="firstName"
                          placeholder=""
                          value={"- -"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Quotation overview */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QO</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Overview</span>
                      <span className="subHeaderTitle">Quotation Overview</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title:"
                          name="firstName"
                          placeholder=""
                          value={"Testing SV Template"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Opening Date:"
                          name="firstName"
                          placeholder=""
                          value={"2024-05-14"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Instructions Attchements:"
                          name="firstName"
                          placeholder=""
                          value=""
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Description:"
                          name="firstName"
                          placeholder=""
                          value={"Mail"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Closing Date:"
                          name="firstName"
                          placeholder=""
                          value={"2024-05-14"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Quotation Header Info */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Header Info</span>
                      <span className="subHeaderTitle">
                        Quotation Header Information
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="ICV Value"
                          name="ICV Value"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Reference"
                          name="Quotation Reference"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          type="date"
                          label="Quotation Date"
                          name="description"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="ICV Validity"
                          name="description"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Payment term"
                          placeholder=""
                          data={dropdownOption}
                          id="INCOTermslocation"
                          iconClassName="dropdownIcon"
                          name="INCOTermslocation"
                          setValue={formInputs.INCOTermslocation || ""}
                          getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Bid Currency"
                          placeholder=""
                          data={dropdownOption}
                          id="INCOTermslocation"
                          iconClassName="dropdownIcon"
                          name="INCOTermslocation"
                          setValue={formInputs.INCOTermslocation || ""}
                          getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="ICV CERTIFICATE"
                          placeholder=""
                          data={dropdownOption}
                          id="paymentterm"
                          iconClassName="dropdownIcon"
                          name="paymentterm"
                          setValue={formInputs.paymentterm || ""}
                          getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-dark">
                          Milestone / Scheduled payments
                        </p>
                        <span>No</span>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="TECHNICAL PROPOSAL"
                          type="file"
                          name="description"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-dark">Link</p>
                        <a href="https://www.emiratessteelarkan.com/supplier-relations/">
                          https://www.emiratessteelarkan.com/supplier-relations/
                        </a>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Pricing Notes"
                          type="text"
                          name="description"
                          placeholder=""
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="UNPRICED COMMERCIAL PROPOSAL"
                          type="file"
                          name="description"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-dark">
                          Acceptance to Terms & Conditions
                        </p>
                        <div>
                          <input
                            type="radio"
                            name="gender"
                            value="Yes"
                            className="me-2"
                          />
                          <span className="me-2">yes</span>
                          <input
                            type="radio"
                            name="gender"
                            value="No"
                            className="me-2"
                          />
                          <span className="me-2">No</span>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Quotation Notes"
                          type="text"
                          name="description"
                          placeholder=""
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="COMMERIAL PROPOSAL"
                          type="file"
                          name="description"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Price Shedule / Line Item */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">
                        Price Shedule / Line Item
                      </span>
                      <span className="subHeaderTitle">
                        Price Shedule / Line Item
                      </span>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable">Download Template</button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        Upload Date
                      </button>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={PriceSheduleColumns}
                          data={PriceSheduleDatas}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* other changes table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle">Other Charges</span>
                      <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={createotherchangesmodel}
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={OtherChangesColumns}
                          data={CreateOtherchangedata}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Pricing Summary */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PS</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">Pricing Summary</span>
                      <span className="subHeaderTitle">Pricing Summary </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                >
                  <Row className="my-4 ms-3 ">
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <p className="fw-medium">
                        SubTotal Pricing Schedule / Line Items (A){" "}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      lg={6}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <InputField
                        className="inputBox"
                        /* label="Closing Date:" */
                        name="firstName"
                        placeholder=""
                        value={"0"}
                        onChange={onInputChange}
                        disabled={getDisabledStatus}
                      />
                      <p className="ms-4 fw-medium"> AED</p>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center "
                    >
                      <p className="fw-medium"> SubTotal Other Charges (C)</p>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      lg={7}
                      className="d-flex mb-2 jsutify-content-center align-items-center "
                    >
                      <InputField
                        className="inputBox"
                        /* label="Closing Date:" */
                        name="firstName"
                        placeholder=""
                        value={"0"}
                        onChange={onInputChange}
                        disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <p className="fw-medium">
                        Quotation Gross Total (F = D+E)
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      lg={7}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <InputField
                        className="inputBox"
                        /* label="Closing Date:" */
                        name="firstName"
                        placeholder=""
                        value={"0"}
                        onChange={onInputChange}
                        disabled={getDisabledStatus}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            {/* dyanamic form creation */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Form: COMM- Spares & Operation Maintenance
                      </span>
                      {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                    </Col>

                    <Col xs={4} md={1} lg={2} className="me-3">
                      <ComboboxField
                        index={0}
                        id="INCOTermslocation"
                        name="dynamicFormOption"
                        // label="Select Option"
                        placeholder={selectedDynamicForm.name}
                        data={dynamicFormOption}
                        setValue={selectedDynamicForm}
                        getvalue={(obj) => {
                          const option = dynamicFormOption.find(
                            (opt) => opt.name === obj.name
                          );
                          if (option) {
                            handleOptionChange(option);
                          }
                        }}
                        onChangeValue={(index, name, value) => {
                          const option = dynamicFormOption.find(
                            (opt) => opt.name === value
                          );
                          if (option) {
                            handleOptionChange(option);
                          }
                        }}
                        disabled={false}
                      />
                    </Col>

                    <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable" onClick={createDynamicForm}>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* new forms */}
                    {dyanamicFormData.map((_, index) => (
                      <div key={index}>
                        <div className="accordion p-4 " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <Row className="accordion-button">
                                <Col
                                  xs={1}
                                  md={1}
                                  lg={1}
                                  className="text-left arrowBtn"
                                >
                                  <i
                                    className={`bi ${
                                      isCardVisible5
                                        ? "bi-chevron-right"
                                        : "bi-chevron-down"
                                    }`}
                                    onClick={toggleCardVisibility5}
                                  ></i>
                                </Col>
                                <Col
                                  xs={1}
                                  md={1}
                                  lg={1}
                                  className="text-center logoBtn"
                                >
                                  <span className="iconLogo">OC</span>
                                </Col>

                                <Col xs={4} md={8} lg={8}>
                                  <span className="headerTitle">new form</span>
                                  {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                                </Col>
                                <Col xs={4} md={1} lg={1} className="">
                                  <button
                                    className="btnTable"
                                    onClick={createDynamicformModel}
                                  >
                                    <i className="bi bi-plus-lg"></i>
                                  </button>
                                </Col>
                                <Col xs={4} md={1} lg={1} className="">
                                  <button
                                    className="btnTable"
                                    onClick={() => delteDyanmicForm(index)}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </Col>
                                <Col xs={1} md={1} lg={1} className="">
                                  <SourcingCommonFilter />
                                </Col>
                              </Row>
                            </h2>

                            <div
                              // id="acc"
                              className={`accordion-collapse collapse show ${
                                isCardVisible5 ? "visible" : ""
                              }`}
                            >
                              <div className="accordion-body p-3 ">
                                <Row>
                                  <Col lg={12}>
                                    <div
                                      className={`${
                                        selectedDynamicForm.name === "Vertical"
                                          ? "d-flex-column w-25"
                                          : "d-flex-column"
                                      } `}
                                    >
                                      <Row>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={
                                            selectedDynamicForm.name ===
                                            "Vertical"
                                              ? 12
                                              : 3
                                          }
                                          className="commTopButtonRightLeftPadding "
                                        >
                                          <label>
                                            <span>1.1</span>
                                            Bidder shall verify latest the in
                                            Country Value, Validity and
                                            Certificate
                                          </label>

                                          <input
                                            className="mx-3"
                                            type="radio"
                                            id="bid1"
                                            name="biddingChoice"
                                            value="option1"
                                          ></input>
                                          <input
                                            className="mx-3"
                                            type="radio"
                                            id="bid2"
                                            name="biddingChoice"
                                            value="option2"
                                          ></input>
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={
                                            selectedDynamicForm.name ===
                                            "Vertical"
                                              ? 12
                                              : 3
                                          }
                                          className="commTopButtonRightLeftPadding "
                                        >
                                          <InputField
                                            className="inputBox"
                                            label="1.2 payment Terme in Days "
                                            name="UnitPrice"
                                            // placeholder=""
                                            // value={PriceSheduleModuledata.UnitPrice || ""}

                                            onChange={onInputChange}
                                            disabled={false}
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={
                                            selectedDynamicForm.name ===
                                            "Vertical"
                                              ? 12
                                              : 3
                                          }
                                          className="commTopButtonRightLeftPadding "
                                        >
                                          <ComboboxField
                                            label="1.3 Bid/Offer Validity in Days."
                                            placeholder=""
                                            data={dropdownOption}
                                            id="INCOTermslocation"
                                            iconClassName="dropdownIcon"
                                            name="INCOTermslocation"
                                            setValue={
                                              formInputs.INCOTermslocation || ""
                                            }
                                            getvalue={setAccessData}
                                            // className="dropdownOption"
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={
                                            selectedDynamicForm.name ===
                                            "Vertical"
                                              ? 12
                                              : 3
                                          }
                                          className="commTopButtonRightLeftPadding "
                                        >
                                          <InputField
                                            className="inputBox"
                                            label="1.4Completion/Delivery Period from the date of OA/PO (Days)"
                                            name="UnitPrice"
                                            // placeholder=""
                                            // value={PriceSheduleModuledata.UnitPrice || ""}

                                            onChange={onInputChange}
                                            disabled={false}
                                          />
                                        </Col>

                                        {elements.map((element, index) =>
                                          renderElement(element, index)
                                        )}
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CreateOtherChanges
        show={otherchangesmodelshow}
        onHide={hideOtherChangesModel}
        onData={handleDataFromCreateOtherChanges}
      />
      <PriceShedule
        show={PriceShedulemodalShow}
        onHide={hidePriceSheduleModal}
        submitPriceShedule={handelDataPriceShedule}
      />
      <DynamicForm
        show={DynamicFormModelshow}
        onHide={hideDynamicFormModel}
        //  dropdownoption={dropdownoption.name}
        onSave={handleSaveModal}
        editData={editData}
      />
    </main>
  );
};

export default CreateQuotation;
