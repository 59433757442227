/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/supplier_appCommonService";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch
import {
  getSupplierCategorySelection,
  getSupplierList,
} from "../../../../redux/feature/supplier_app/supplierSlice";
import { getLoginUserData } from "../../../../redux/feature/userSlice";
import { addCategorySelectedData } from "../../../../redux/feature/supplier_app/supplierSlice";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import CategorySelectionModal from "../../../../Components/Modal/supplier_app/CategorySelectionModal";
import DataTable from "react-data-table-component";
import Constants from "../../../../common/Constants";
import CommonFillter from "../../../../Components/Filter/Supplier/SupplierCommonFillter";


const SupplierRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Supplier");
  localStorage.setItem("submenu", "Supplier Registration");

  let selectedCategoryData = useSelector(
    (state) => state?.supplier?.selectedCategory
  ); // User Details from Store
  let userInfo = useSelector((state) => state?.user?.data); // User Details from Store
  let supplierData = useSelector((state) => state?.supplier?.data); // User Details from Store
  let supplierID = useSelector((state) => state?.supplier?.supplierID); // User Details from Store

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  let dropdownOption = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible7, setCardVisible7] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);
  const [isCardVisible10, setCardVisible10] = useState(true);
  const [isCardVisible11, setCardVisible11] = useState(true);
  const [isCardVisible12, setCardVisible12] = useState(true);
  const [isCardVisible13, setCardVisible13] = useState(true);
  const [isCardVisible14, setCardVisible14] = useState(true);
  const [isCardVisible15, setCardVisible15] = useState(true);
  const [isCardVisible16, setCardVisible16] = useState(true);
  const [isCardVisible17, setCardVisible17] = useState(true);

  const [getDropdownData, setDropdownData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [getSelectedData, setSelectedData] = useState([]);
  const [getDisabledRemove, setDisabledRemove] = useState(true);
  const [getHeaderData, setHeaderData] = useState(false);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility7 = () => {
    setCardVisible7(!isCardVisible7);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const toggleCardVisibility10 = () => {
    setCardVisible10(!isCardVisible10);
  };
  const toggleCardVisibility11 = () => {
    setCardVisible11(!isCardVisible11);
  };
  const toggleCardVisibility12 = () => {
    setCardVisible12(!isCardVisible12);
  };
  const toggleCardVisibility13 = () => {
    setCardVisible13(!isCardVisible13);
  };
  const toggleCardVisibility14 = () => {
    setCardVisible14(!isCardVisible14);
  };
  const toggleCardVisibility15 = () => {
    setCardVisible15(!isCardVisible15);
  };
  const toggleCardVisibility16 = () => {
    setCardVisible16(!isCardVisible16);
  };
  const toggleCardVisibility17 = () => {
    setCardVisible17(!isCardVisible17);
  };
  /* Modal Function */
  const categorySelection = () => {
    setModalShow(true);
  };
  const hideModal = () => {
    setModalShow(false);
  };

  const [formInputs, setFormInputs] = useState({
    organisationName: "",
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
    roles: ["VENDOR"],
    category: ["VENDOR"],
    address: "",
    address2: "",
    city: "",
    countryCode: "",
    country: "",
    stateRegion: "",
    regionCode: "",
    cityPostalCode: "",
    contactTitle: "",
    contactNumber: "",
    contactfirstName: "",
    contactlastName: "",
    contactEmail: "",
    contactPhoneNr: "",
    companyOwner: "",
    companyLogo: "",
    CompanyBrochure: "",
    commodityServices: "",
    haveICVCertificate: "",
    nameOwer: "",
    icvScore: "",
    icvCertificate: "",
    icvExpiryDate: "",
    vatRegistrationNumber: "",
    vatRegistrationExpiryDate: "",
    tradeLicenseNumber: "",
    tradeLicenseExpiryDate: "",
    BCMPlanPolicy: "",
    dealershipSetupType: "",
    MROcommoditybrand: "",
    brandName: "",
    OverseasTaxRegistrationNumber: "",
    issuingAuthorityAccreditation: "",
    chamberIncorporationCertificate: "",
    chamberIncorporationExpiryDate: "",
    humanRightsIssue: {
      HarassmentPolicy: "",
      ChildLabourPolicy: "",
      ForcedLabourPolicy: "",
      WorkingHoursPolicy: "",
      DiscriminationAtWorkPolicy: "",
      FreedomOfAssociationPolicy: "",
      CommuncationFullyUnderstood: "",
      ConflictMineralsIndigenousPeople: "",
    },
    financialDetails: {
      turnover1: "",
      turnover2: "",
    },
    categorySpecificInformation: {
      havingIso17025: "",
      safetyReqs: "",
      iso45001certificate: "",
      iso9001certificate: "",
      iso14001certificate: "",
      PoAinstituteIssued: "",
      isHseManagementManual: "",
      haveHseAuditProcedure: "",
      haveSkillTrainingSystem: "",
    },
    responsibleSourcing: {
      haveWrittenCodeOfConduct: "",
      alignedWithScComplianceEthics: "",
      haveWrittenRiskAssesProcessInSc: "",
      involvedInLocalCommunityProjects: "",
      haveWrittenPolicyOnAntiCorruption: "",
    },
    environmentalStewardship: {
      biodiversity: "",
      energyConsumption: "",
      wasteGeneration: "",
      climateChange: "",
      waterConsumption: "",
    },
    bankDetails: [],
    contactDetails: [
      {
        lastName: "",
        firstName: "",
        contactEmail: "",
        contactTitle: "",
        contactNumber: "",
        contactPosition: "",
      },
    ],
  });

  // Don't remove this
  const contactAddressData = {
    lastName: "",
    firstName: "",
    contactEmail: "",
    contactTitle: "",
    contactNumber: "",
    contactPosition: "",
  };

  const bankInfoInitialData = {
    bankNotListed: "",
    bankAccountNumber: "",
    bankKey: "",
    bankName: "",
    bankCountry: "",
    bankAddress: "",
    iban: "",
    AccountHolderName: "",
    bankCompanyletterhead: "",
    bankConfirmationLetter: "",
    chipsCode: "",
    swiftIfscCode: "",
    bankInfoTemplate: "",
    preferredCurrency: "",
  };

  const [getBankDetails, setBankDetails] = useState([]);
  const [getContactAddress, setContactAddress] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [getDisabledStatus, setDisabledStatus] = useState(true);
  const [getBankDetailsData, setBankDetailsData] = useState([]);
  const [getJobTitleData, setJobTitleData] = useState([]);
  const [getContactTitleData, setContactTitleData] = useState([]);
  const [getCountry, setCountry] = useState([]);
  const [getState, setState] = useState([]);

  // Contact Address Section
  const addContactAddress = () => {
    setContactAddress([...getContactAddress, contactAddressData]); // Add an empty object to the forms array
  };

  const removeContactAddress = (indexToRemove) => {
    setContactAddress(
      getContactAddress.filter((form, index) => index !== indexToRemove)
    );
  };

  const handleContactAddress = (index, key, value) => {
    // console.log(">> value", value);
    // console.log(">> getContactAddress", getContactAddress);
    // console.log(">> index", index);
    // console.log(">> key", key);

    if (value) {
      const newForms = [...getContactAddress];
      newForms[index][key] = value;
      setContactAddress(newForms);
    }
    // console.log(">> newForms", newForms);
  };
  // Contact Address Section Ends

  // Bank Info Section
  const addBankForm = () => {
    setBankDetails([...getBankDetails, bankInfoInitialData]); // Add an empty object to the forms array
  };

  const removeBankForm = (indexToRemove) => {
    setBankDetails(
      getBankDetails.filter((form, index) => index !== indexToRemove)
    );
  };

  const handleChangeBankInfo = (index, key, value) => {
    if (value) {
      const newForms = [...getBankDetails];
      newForms[index][key] = value;
      setBankDetails(newForms);
      // console.log(">> newForms", newForms);

      if (key === "bankKey") {
        // eslint-disable-next-line array-callback-return
        getBankDetailsData.filter((item) => {
          if (item.bankKey === value) {
            const newForms = [...getBankDetails];
            newForms[index]["bankCountryDescription"] =
              item.bankCountryDescription;
            newForms[index]["bankAccountName"] = item.bankAccountName;
            newForms[index]["bankAddress"] = item.bankAddress;
            newForms[index]["bankBranchName"] = item.bankBranchName;
            newForms[index]["bankCurrency"] = item.bankCurrency;
            setBankDetails(newForms);
          }
        });
      }
    }
  };
  // Bank Info Section ends

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> data ss", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
      getStateData(getDropdownData?.code);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  useEffect(() => {
    dispatch(getSupplierCategorySelection()); // getSupplierCategorySelection store
    getBankMasterData(); // Load Bank Details
    getMasterData(); // Load Master Data

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFirstLevelData = async (page) => {
    setLoading(true);
    console.log(">> supplierID", supplierID);
    if (supplierID) {
      let filteredData = supplierData.filter(
        (item) => item.AssociateNumber === supplierID
      );
      console.log(">> filteredData", filteredData);
      setHeaderData(filteredData[0]);

      setDisabledStatus(true);
      setFormInputs(filteredData[0]);
      setData(filteredData[0]?.vendorCategory);
      filteredData[0]?.bankDetails
        ? setBankDetails(filteredData[0]?.bankDetails)
        : setBankDetails([...getBankDetails, bankInfoInitialData]);
      filteredData[0]?.contactDetails
        ? setContactAddress(filteredData[0]?.contactDetails)
        : setContactAddress([...getContactAddress, contactAddressData]);

      setLoading(false);
    } else {
      navigate(Constants.SUPPILER_LIST);
    }
  };

  useEffect(() => {
    // if (userInfo?.roles?.length > 0) {
    loadFirstLevelData();
    // if (userInfo?.roles[0] !== "VENDOR") {
    //   setDisabledStatus(true);

    //   setFormInputs(userInfo);
    //   setData(userInfo?.vendorCategory);
    //   userInfo?.bankDetails ? setBankDetails(userInfo?.bankDetails) : setBankDetails([...getBankDetails, bankInfoInitialData]);
    //   userInfo?.contactDetails ? setContactAddress(userInfo?.contactDetails) : setContactAddress([...getContactAddress, contactAddressData]);

    // } else {
    //   setDisabledStatus(false);
    //   setData([]);
    //   setBankDetails([...getBankDetails, bankInfoInitialData]);
    //   setContactAddress([...getContactAddress, contactAddressData]);
    // }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Datatables
  const fetchUsers = async (page) => {
    setLoading(true);
    // const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`);
    // setData(selectedCategoryData);

    setData((prevData) => {
      // Assuming you have a new array of data
      const newDataArray = [
        ...prevData,
        ...selectedCategoryData.filter(
          (item) =>
            !prevData.some(
              (prevItem) =>
                prevItem.vendorCategoryText === item.vendorCategoryText
            )
        ),
      ];
      return newDataArray;
    });
    // setTotalRows(selectedCategoryData?.length);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryData]);

  const columns = [
    {
      name: "Category Code",
      selector: (row) => row.vendorCategoryCode || "--",
      sortable: true,
      width: "250px",
    },
    {
      name: "Category",
      selector: (row) => row.vendorCategoryText || "--",
      sortable: true,
      width: "280px",
    },

    {
      name: "SubCategory",
      selector: (row) => row.vendorSubCategoryText || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Code",
      selector: (row) => row.vendorSubCategoryCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Description",
      selector: (row) => row.vendorSubCategoryLongText || "--",
      sortable: true,
      width: "400px",
    },
  ];

  const handleChange = ({ selectedRows }) => {
    let res = selectedCategoryData.filter(
      (item) => !selectedRows.some((obj) => obj._id === item._id)
    );
    setSelectedData(res);
    if (res.length === selectedRows.length || res.length === 0) {
      setDisabledRemove(false);
    } else {
      setDisabledRemove(true);
    }
  };

  const removeCategory = () => {
    dispatch(addCategorySelectedData(getSelectedData));
    setDisabledRemove(true);
  };

  //Load Bank Master Data
  const getBankMasterData = async () => {
    await ItemService.getBankMasterData().then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        setBankDetailsData(item?.data);
      } else {
        setBankDetailsData([]);
        console.log(">> Error: Loading getBankMasterData");
      }
    });
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {
      codeType: "jobtitle",
    };
    await ItemService.getMasterData(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setJobTitleData(item?.data);
      } else {
        setJobTitleData([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    let obj1 = {
      codeType: "title",
    };
    await ItemService.getMasterData(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setContactTitleData(item?.data);
      } else {
        setContactTitleData([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    let obj0 = {
      codeType: "country",
    };
    await ItemService.getMasterData(obj0).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCountry(item?.data);
      } else {
        setCountry([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  // Onselect of Country state will load
  const getStateData = async (code) => {
    let obj1 = {
      codeType: "region",
      code: code,
    };
    await ItemService.getMasterData(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setState(item?.data);
      } else {
        setState([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Supplier Details : {supplierID}</h2>
            <p className="subText">Supplier Information </p>
            <hr />
            {(formInputs.status === "Non Invited" || formInputs.status === "Registered" || formInputs.status === "Invited" ||
              formInputs.status === "In Registration") && (
              <>
                {/* 01 Basic Information */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">BI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Basic Information</span>
                          <span className="subHeaderTitle">
                            Supplier Basic Information
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Supplier Name"
                              name="organisationName"
                              extraLabel="As per the Trade license"
                              placeholder=""
                              value={formInputs.organisationName || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Status"
                              name="status"
                              placeholder=""
                              value={formInputs.status || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Purpose/Justification"
                              type="text"
                              name="purposeJustification"
                              placeholder=""
                              value={formInputs.purposeJustification || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="initiator"
                              name="initiator"
                              placeholder=""
                              value={formInputs.initiator || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="initiator By"
                          name="initiatedBy"
                          placeholder=""
                          value={formInputs.initiatedBy || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="initiator Name"
                              name="initiatorName"
                              placeholder=""
                              value={formInputs.initiatorName || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 02 General Information */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">GI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            General Information
                          </span>
                          <span className="subHeaderTitle">
                            Supplier General Information
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label=" Name of Company Owner as on license "
                              extraLabel="Name of Company Owner as on license"
                              name="companyOwner"
                              placeholder=""
                              value={formInputs.companyOwner || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="file"
                              label="Upload Company Logo"
                              name="companyLogo"
                              placeholder=""
                              value={formInputs.companyLogo || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Do you have ISO (9001, 14001, 45001) Certification?"
                          placeholder=""
                          data={dropdownOption}
                          id="rfqCategory"
                          iconClassName="dropdownIcon"
                          name="rfqCategory"
                          setValue={formInputs.rfqCategory || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col> */}
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Website and Company Brochure"
                              name="CompanyBrochure"
                              placeholder=""
                              value={formInputs.CompanyBrochure || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 03 Company Address */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible3
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility3}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CA</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Company Address</span>
                          <span className="subHeaderTitle">
                            Address of the Company
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible3 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label=" Address Lines 1 / Street"
                              name="address"
                              placeholder=""
                              value={formInputs.address || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Address Line 2"
                              name="address2"
                              placeholder=""
                              value={formInputs.address2 || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="City"
                              name="city"
                              placeholder=""
                              value={formInputs.city || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Country"
                              placeholder=""
                              data={getCountry}
                              id="country"
                              iconClassName="dropdownIcon"
                              name="country"
                              setValue={formInputs.country}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="State / Emirate / Province (Region)"
                              placeholder=""
                              data={getState}
                              id="state"
                              iconClassName="dropdownIcon"
                              name="state"
                              setValue={formInputs.state}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Postal Code/PO Box"
                              name="cityPostalCode"
                              placeholder=""
                              value={formInputs.cityPostalCode || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 04 Contact Person */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CP</span>
                        </Col>
                        <Col xs={12} md={8} lg={8}>
                          <span className="headerTitle">Contact Person</span>
                          <span className="subHeaderTitle">
                            Contact Person Details
                          </span>
                        </Col>
                        {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => addContactAddress()}
                        disabled={getDisabledStatus}
                      >
                        <i className="bi bi-plus-lg"></i> Add Contact
                      </button>
                    </Col> */}

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        {/* To Load Dynamic Contact Person Details */}
                        {getContactAddress &&
                          getContactAddress?.map((item, index) => (
                            <div key={index}>
                              <Row className="pt-2 pb-2">
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <h6>Contact Address Section {index + 1}</h6>
                                  {/* <span
                                className="deleteIconAccordion"
                                onClick={() => removeContactAddress(index)}
                              >
                                {" "}
                                <i className="bi bi-x-circle"></i>{" "}
                              </span> */}
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Job Title"
                                    placeholder=""
                                    data={getJobTitleData}
                                    id="contactPosition"
                                    iconClassName="dropdownIcon"
                                    name="contactPosition"
                                    setValue={item.contactPosition}
                                    getvalue={setDropdownData}
                                    disabled={getDisabledStatus}
                                    index={index}
                                    onChangeValue={handleContactAddress}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Contact Title"
                                    placeholder=""
                                    data={getContactTitleData}
                                    id="contactTitle"
                                    iconClassName="dropdownIcon"
                                    name="contactTitle"
                                    setValue={item.contactTitle}
                                    getvalue={setDropdownData}
                                    disabled={getDisabledStatus}
                                    index={index}
                                    onChangeValue={handleContactAddress}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Contact First Name"
                                    name="contactfirstName"
                                    placeholder=""
                                    value={item.firstName || ""}
                                    onChange={(e) =>
                                      handleContactAddress(
                                        index,
                                        "contactfirstName",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Contact Last Name"
                                    name="contactlastName"
                                    placeholder=""
                                    value={item.lastName || ""}
                                    onChange={(e) =>
                                      handleContactAddress(
                                        index,
                                        "contactlastName",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Contact Email"
                                    name="contactEmail"
                                    placeholder=""
                                    value={item.contactEmail || ""}
                                    onChange={(e) =>
                                      handleContactAddress(
                                        index,
                                        "contactEmail",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Contact Number"
                                    name="contactNumber"
                                    placeholder=""
                                    value={item.contactNumber || ""}
                                    onChange={(e) =>
                                      handleContactAddress(
                                        index,
                                        "contactNumber",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                </Col>
                                {/* <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Contact Number"
                                placeholder=""
                                data={dropdownOption}
                                id="contactNumber"
                                iconClassName="dropdownIcon"
                                name="contactNumber"
                                setValue={item.contactNumber || ""}
                                getvalue={setDropdownData}
                                disabled={getDisabledStatus}
                                index={index}
                                onChangeValue={handleContactAddress}
                              />
                            </Col>{" "} */}
                                {/* <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Additional User ID"
                                name="contactEmail"
                                placeholder=""
                                value={item.contactEmail || ""}
                                onChange={(e) =>
                                  handleContactAddress(
                                    index,
                                    "contactEmail",
                                    e.target.value
                                  )
                                }
                                disabled={getDisabledStatus}
                              />
                            </Col> */}
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <hr />
                                </Col>
                              </Row>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 06 Category Selection */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible6
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility6}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CS</span>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                          <span className="headerTitle">
                            Category Selection
                          </span>
                          <span className="subHeaderTitle">
                            Select Required Category
                          </span>
                        </Col>
                        {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => categorySelection()}
                        disabled={getDisabledStatus}
                      >
                        <i className="bi bi-plus-lg"></i> Add Category
                      </button>
                    </Col>

                    <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => removeCategory()}
                        disabled={getDisabledRemove}
                      >
                        <i className="bi bi-trash3"></i> Remove Category
                      </button>
                    </Col> */}

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible6 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={columns}
                              data={data}
                              progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                              onSelectedRowsChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {(formInputs.status === "In Registration" || formInputs.status === "Registered") && (
              <>
                {/* 05 Commodity / Service Details*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible5
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility5}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CD</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Commodity / Service Details
                          </span>
                          <span className="subHeaderTitle">
                            Commodity and Service Description
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible5 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Commodity / services offered"
                              placeholder=""
                              data={dropdownOption}
                              id="commodityServices"
                              iconClassName="dropdownIcon"
                              name="commodityServices"
                              setValue={formInputs.commodityServices || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* 07 AE Country Specific Information*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible7
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility7}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">AI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            AE Country Specific Information
                          </span>
                          <span className="subHeaderTitle">
                            Country Specific Information
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible7 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label=" Name of Owner"
                              name="nameOwer"
                              placeholder=""
                              value={formInputs.nameOwer}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label=" is company FreeZone Listed?"
                              name="freeZone"
                              placeholder=""
                              value={formInputs.freeZone || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="In Country Value (ICV) Certificate"
                              extraLabel="Does your Company have a In Country Value (ICV) Certificate?"
                              placeholder=""
                              data={dropdownOption}
                              id="haveICVCertificate"
                              iconClassName="dropdownIcon"
                              name="haveICVCertificate"
                              setValue={formInputs.haveICVCertificate || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="ICV score % and Certificate "
                              name="icvScore"
                              placeholder=""
                              value={formInputs.icvScore || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="date"
                              label="ICV Expiration Date "
                              name="icvExpiryDate"
                              placeholder=""
                              value={formInputs.icvExpiryDate || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="ICV Expiration Date"
                          placeholder=""
                          data={dropdownOption}
                          id="rfqCategory"
                          iconClassName="dropdownIcon"
                          name="icvExpiryDate"
                          setValue={formInputs.icvExpiryDate || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col> */}
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="VAT Registration Number Certificate "
                              name="vatRegistrationNumber"
                              placeholder=""
                              value={formInputs.vatRegistrationNumber || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>

                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="VAT Registration Expiration Date"
                          placeholder=""
                          data={dropdownOption}
                          id="vatRegistrationExpiryDate"
                          iconClassName="dropdownIcon"
                          name="vatRegistrationExpiryDate"
                          setValue={formInputs.vatRegistrationExpiryDate || ""}
                          getvalue={setDropdownData}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="date"
                              label="VAT Registration Expiration Date"
                              id="vatRegistrationExpiryDate"
                              name="vatRegistrationExpiryDate"
                              placeholder=""
                              value={formInputs.vatRegistrationExpiryDate}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Trade License Number and Certificate "
                              name="tradeLicenseNumber"
                              placeholder=""
                              value={formInputs.tradeLicenseNumber || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="date"
                              label="Trade License Expiry Date"
                              id="tradeLicenseExpiryDate"
                              name="tradeLicenseExpiryDate"
                              placeholder=""
                              value={formInputs.tradeLicenseExpiryDate}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Trade License Expiry Date"
                          placeholder=""
                          data={dropdownOption}
                          id="tradeLicenseExpiryDate"
                          iconClassName="dropdownIcon"
                          name="tradeLicenseExpiryDate"
                          setValue={formInputs.tradeLicenseExpiryDate || ""}
                          getvalue={setDropdownData}
                          disabled={getDisabledStatus}
                          extraLabel="alsdhakj dhklhaklshd"
                        />
                      </Col> */}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 08 Banker Info*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible8
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility8}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">BK</span>
                        </Col>
                        <Col xs={12} md={8} lg={8}>
                          <span className="headerTitle">Bank Information</span>
                          <span className="subHeaderTitle">
                            Bank Information (Please add all your Bank Accounts)
                          </span>
                        </Col>
                        {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => addBankForm()}
                        disabled={getDisabledStatus}
                      >
                        <i className="bi bi-plus-lg"></i> Add Bank Info
                      </button>
                    </Col> */}

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    {/* Banker Information */}
                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible8 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        {/* To Load Dynamic Bank Info Form */}
                        {getBankDetails &&
                          getBankDetails?.map((item, index) => (
                            <div key={index}>
                              <Row className="pt-2 pb-2">
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <h6>Bank Information Section {index + 1}</h6>
                                  {/* <span
                                className="deleteIconAccordion"
                                onClick={() => removeBankForm(index)}
                              >
                                {" "}
                                <i className="bi bi-x-circle"></i>{" "}
                              </span> */}
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Is the Company bank included on list?"
                                    placeholder=""
                                    data={dropdownOption}
                                    id="bankNotListed"
                                    iconClassName="dropdownIcon"
                                    name="bankNotListed"
                                    setValue={item?.bankNotListed || ""}
                                    getvalue={setDropdownData}
                                    index={index}
                                    onChangeValue={handleChangeBankInfo}
                                    disabled={getDisabledStatus}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="BankID"
                                    placeholder=""
                                    data={getBankDetailsData}
                                    id="bankKey"
                                    iconClassName="dropdownIcon"
                                    name="bankKey"
                                    setValue={item?.bankKey}
                                    getvalue={setDropdownData}
                                    index={index}
                                    onChangeValue={handleChangeBankInfo}
                                    disabled={getDisabledStatus}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Bank A/C Number"
                                    name="bankAccountNumber"
                                    placeholder=""
                                    value={item?.bankAccountNumber}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "bankAccountNumber",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  {/* <ComboboxField
                                label="Bank Name"
                                placeholder=""
                                data={dropdownOption}
                                id="bankName"
                                iconClassName="dropdownIcon"
                                name="bankName"
                                setValue={item?.bankName || ""}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                disabled={getDisabledStatus}
                              /> */}

                                  <InputField
                                    className="inputBox"
                                    label="Bank Name"
                                    name="bankBranchName"
                                    placeholder=""
                                    value={item?.bankBranchName}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "bankBranchName",
                                        e.target.value
                                      )
                                    }
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  {/* <ComboboxField
                                label="Bank Country"
                                placeholder=""
                                data={dropdownOption}
                                id="bankCountry"
                                iconClassName="dropdownIcon"
                                name="bankCountry"
                                setValue={item?.bankCountry || ""}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                disabled={getDisabledStatus}
                              /> */}
                                  <InputField
                                    className="inputBox"
                                    label="Bank Country"
                                    name="bankCountryDescription"
                                    placeholder=""
                                    value={item?.bankCountryDescription}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "bankCountryDescription",
                                        e.target.value
                                      )
                                    }
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  {/* <ComboboxField
                                label="Branch Address"
                                placeholder=""
                                data={dropdownOption}
                                id="bankAddress"
                                iconClassName="dropdownIcon"
                                name="bankAddress"
                                setValue={item?.bankAddress || ""}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                disabled={getDisabledStatus}
                              /> */}
                                  <InputField
                                    className="inputBox"
                                    label="Branch Address"
                                    name="bankAddress"
                                    placeholder=""
                                    value={item?.bankAddress}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "bankAddress",
                                        e.target.value
                                      )
                                    }
                                    disabled={true}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  {/* <ComboboxField
                                label="Preferred Currency"
                                placeholder=""
                                data={dropdownOption}
                                id="preferredCurrency"
                                iconClassName="dropdownIcon"
                                name="preferredCurrency"
                                setValue={item?.preferredCurrency || ""}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                disabled={getDisabledStatus}
                              /> */}
                                  <InputField
                                    className="inputBox"
                                    label="Preferred Currency"
                                    name="bankCurrency"
                                    placeholder=""
                                    value={item?.bankCurrency}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "bankCurrency",
                                        e.target.value
                                      )
                                    }
                                    disabled={true}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="IBAN"
                                    name="iban"
                                    placeholder=""
                                    value={item?.iban}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "iban",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Name of Beneficiary"
                                    extraLabel="Name of Beneficiary (Bank Account Holder Name) as per company /trade license"
                                    name="AccountHolderName"
                                    placeholder=""
                                    value={item?.AccountHolderName}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "AccountHolderName",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                  {/* <ComboboxField
                                label="Name of Beneficiary"
                                placeholder=""
                                extraLabel="Name of Beneficiary (Bank Account Holder Name) as per company /trade license"
                                data={dropdownOption}
                                id="AccountHolderName"
                                iconClassName="dropdownIcon"
                                name="AccountHolderName"
                                setValue={item?.AccountHolderName || ""}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                disabled={getDisabledStatus}
                              /> */}
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Swift IFSC Code"
                                    placeholder=""
                                    data={dropdownOption}
                                    id="swiftIfscCode"
                                    iconClassName="dropdownIcon"
                                    name="swiftIfscCode"
                                    setValue={item?.swiftIfscCode || ""}
                                    getvalue={setDropdownData}
                                    index={index}
                                    onChangeValue={handleChangeBankInfo}
                                    disabled={getDisabledStatus}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Company letterhead"
                                    extraLabel="Upload the filled template on company letterhead , signed by authorized person with company seal "
                                    name="bankCompanyletterhead"
                                    placeholder=""
                                    value={item?.bankCompanyletterhead}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "bankCompanyletterhead",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                  <div className="col-sm-10">
                                    <input
                                      className="form-control"
                                      type="file"
                                      id="formFile"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Confirmation letter"
                                    extraLabel="Do you have a Confirmation letter from the bank( mandatory for bank updates ) "
                                    name="bankConfirmationLetter"
                                    placeholder=""
                                    value={item?.bankConfirmationLetter}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "bankConfirmationLetter",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                  <div className="col-sm-10">
                                    <input
                                      className="form-control"
                                      type="file"
                                      id="formFile"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Correspondence Bank Details"
                                    extraLabel="Correspondence Bank Details /Chips code/ ACH/ EFT/ ABA/ FED "
                                    name="chipsCode"
                                    placeholder=""
                                    value={item?.chipsCode}
                                    onChange={(e) =>
                                      handleChangeBankInfo(
                                        index,
                                        "chipsCode",
                                        e.target.value
                                      )
                                    }
                                    disabled={getDisabledStatus}
                                  />
                                  <div className="col-sm-10">
                                    <input
                                      className="form-control"
                                      type="file"
                                      id="formFile"
                                    />
                                  </div>
                                </Col>

                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <hr />
                                </Col>
                              </Row>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 09 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible9
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility9}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">FI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Financial Information
                          </span>
                          <span className="subHeaderTitle">
                            Please provide your company's turnover for the last
                            2 years, supported by documentary evidence
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible9 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Financial Year"
                          placeholder=""
                          data={dropdownOption}
                          id="rfqCategory"
                          iconClassName="dropdownIcon"
                          name="rfqCategory"
                          setValue={formInputs.rfqCategory || ""}
                          getvalue={setDropdownData}
                        // className="dropdownOption"
                        />
                      </Col> */}

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Year 1 - Turnover  "
                              extraLabel="Year 1 - Company turnover supported by documentary evidence"
                              name="turnover1"
                              placeholder=""
                              value={formInputs.turnover1 || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Year 2 - Turnover "
                              extraLabel="Year 2 - Company turnover supported by documentary evidence"
                              name="turnover2"
                              placeholder=""
                              value={formInputs.turnover2 || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 10 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible10
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility10}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CD</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Company Documents & Certificates
                          </span>
                          <span className="subHeaderTitle">
                            Company Documents & Certificates
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible10 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Overseas Tax Registration Number "
                              placeholder=""
                              data={dropdownOption}
                              id="OverseasTaxRegistrationNumbe"
                              iconClassName="dropdownIcon"
                              name="OverseasTaxRegistrationNumbe"
                              setValue={
                                formInputs.OverseasTaxRegistrationNumbe || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="text"
                              label="Issuing Authority / Accreditation Body for Overseas suppliers"
                              id="issuingAuthorityAccreditation"
                              name="issuingAuthorityAccreditation"
                              placeholder=""
                              value={formInputs.issuingAuthorityAccreditation}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="text"
                              label="Chamber Incorporation Certificate Copy"
                              id="chamberIncorporationCertificate"
                              name="chamberIncorporationCertificate"
                              placeholder=""
                              value={formInputs.chamberIncorporationCertificate}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="date"
                              label="Chamber Incorporation Expiry Date"
                              id="chamberIncorporationExpiryDate"
                              name="chamberIncorporationExpiryDate"
                              placeholder=""
                              value={formInputs.chamberIncorporationExpiryDate}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 11 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible11
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility11}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">BM</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Business Continuity Management
                          </span>
                          <span className="subHeaderTitle">
                            Business Continuity Management
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible11 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Does your Company have a Business Continuity Management (BCM) Plan / Policy"
                              placeholder=""
                              data={dropdownOption}
                              id="BCMPlanPolicy"
                              iconClassName="dropdownIcon"
                              name="BCMPlanPolicy"
                              setValue={formInputs.BCMPlanPolicy || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 12 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible12
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility12}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">BI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Business & Brand Information
                          </span>
                          <span className="subHeaderTitle">
                            Category Specific Information
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible12 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Dealership Set-up Type"
                              placeholder=""
                              data={dropdownOption}
                              id="dealershipSetupType"
                              iconClassName="dropdownIcon"
                              name="dealershipSetupType"
                              setValue={formInputs.dealershipSetupType || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="MRO commodity / category for the selected brand "
                              placeholder=""
                              data={dropdownOption}
                              id="MROcommoditybrand"
                              iconClassName="dropdownIcon"
                              name="MROcommoditybrand"
                              setValue={formInputs.MROcommoditybrand || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              type="text"
                              label="Brand Name"
                              id="brandName"
                              name="brandName"
                              placeholder=""
                              value={formInputs.brandName}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 13 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible13
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility13}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Category Specific Information
                          </span>
                          <span className="subHeaderTitle">
                            Category Specific Information
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible13 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have ISO 17025 certificate?"
                              placeholder=""
                              data={dropdownOption}
                              id="havingIso17025"
                              iconClassName="dropdownIcon"
                              name="havingIso17025"
                              setValue={formInputs.havingIso17025 || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have qualified safety officer?"
                              placeholder=""
                              data={dropdownOption}
                              id="safetyReqs"
                              iconClassName="dropdownIcon"
                              name="safetyReqs"
                              setValue={formInputs.safetyReqs || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have ISO 45001 certificate?"
                              placeholder=""
                              data={dropdownOption}
                              id="iso45001certificate"
                              iconClassName="dropdownIcon"
                              name="iso45001certificate"
                              setValue={formInputs.iso45001certificate || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have ISO 9001 certificate?"
                              placeholder=""
                              data={dropdownOption}
                              id="iso9001certificate"
                              iconClassName="dropdownIcon"
                              name="iso9001certificate"
                              setValue={formInputs.iso9001certificate || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have ISO 14001 certificate?"
                              placeholder=""
                              data={dropdownOption}
                              id="iso14001certificate"
                              iconClassName="dropdownIcon"
                              name="iso14001certificate"
                              setValue={formInputs.iso14001certificate || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have Power of Attorney Certificate?"
                              placeholder=""
                              data={dropdownOption}
                              id="PoAinstituteIssued"
                              iconClassName="dropdownIcon"
                              name="PoAinstituteIssued"
                              setValue={formInputs.PoAinstituteIssued || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have a company HSE Management System manual?"
                              placeholder=""
                              data={dropdownOption}
                              id="isHseManagementManual"
                              iconClassName="dropdownIcon"
                              name="isHseManagementManual"
                              setValue={formInputs.isHseManagementManual || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have a procedure for HSE Auditing and Inspection?"
                              placeholder=""
                              data={dropdownOption}
                              id="haveHseAuditProcedure"
                              iconClassName="dropdownIcon"
                              name="haveHseAuditProcedure"
                              setValue={formInputs.haveHseAuditProcedure || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Does your organization have a skills and competency training/development system in place (Technical and HSE)?"
                              placeholder=""
                              data={dropdownOption}
                              id="haveSkillTrainingSystem"
                              iconClassName="dropdownIcon"
                              name="haveSkillTrainingSystem"
                              setValue={
                                formInputs.haveSkillTrainingSystem || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 14 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible14
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility14}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">RS</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Responsible Sourcing
                          </span>
                          <span className="subHeaderTitle">
                            Category Specific Information
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible14 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you stay up to date with local laws / regulations and any changes to ensure that your supply chain is compliant with environmental and ethical laws?"
                              placeholder=""
                              data={dropdownOption}
                              id="alignedWithScComplianceEthics"
                              iconClassName="dropdownIcon"
                              name="alignedWithScComplianceEthics"
                              setValue={
                                formInputs.alignedWithScComplianceEthics || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have a written Business Ethics or business integrity policy/ procedure or Code of Conduct in place?"
                              placeholder=""
                              data={dropdownOption}
                              id="haveWrittenCodeOfConduct"
                              iconClassName="dropdownIcon"
                              name="haveWrittenCodeOfConduct"
                              setValue={
                                formInputs.haveWrittenCodeOfConduct || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have a written policy/procedure on prohibiting bribery, corruption and fraud within your business?"
                              placeholder=""
                              data={dropdownOption}
                              id="haveWrittenPolicyOnAntiCorruption"
                              iconClassName="dropdownIcon"
                              name="haveWrittenPolicyOnAntiCorruption"
                              setValue={
                                formInputs.haveWrittenPolicyOnAntiCorruption ||
                                ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Do you have a written procedure to assess and address risk across your supply chain? "
                              placeholder=""
                              data={dropdownOption}
                              id="haveWrittenRiskAssesProcessInSc"
                              iconClassName="dropdownIcon"
                              name="haveWrittenRiskAssesProcessInSc"
                              setValue={
                                formInputs.haveWrittenRiskAssesProcessInSc || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label=" Are you involved in projects or efforts to improve the local community"
                              placeholder=""
                              data={dropdownOption}
                              id="involvedInLocalCommunityProjects"
                              iconClassName="dropdownIcon"
                              name="involvedInLocalCommunityProjects"
                              setValue={
                                formInputs.involvedInLocalCommunityProjects ||
                                ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 15 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible15
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility15}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">HR</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Human Rights</span>
                          <span className="subHeaderTitle">
                            For which of the following working conditions and
                            human rights issues does your company have a
                            policy/procedure in place?
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible15 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Child Labour"
                              placeholder=""
                              data={dropdownOption}
                              id="ChildLabourPolicy"
                              iconClassName="dropdownIcon"
                              name="ChildLabourPolicy"
                              setValue={formInputs.ChildLabourPolicy || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Working hours / Remuneration"
                              placeholder=""
                              data={dropdownOption}
                              id="WorkingHoursPolicy"
                              iconClassName="dropdownIcon"
                              name="WorkingHoursPolicy"
                              setValue={formInputs.WorkingHoursPolicy || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Conflict minerals and indigenous people"
                              placeholder=""
                              data={dropdownOption}
                              id="ConflictMineralsIndigenousPeople"
                              iconClassName="dropdownIcon"
                              name="ConflictMineralsIndigenousPeople"
                              setValue={
                                formInputs.ConflictMineralsIndigenousPeople ||
                                ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Discrimination at work"
                              placeholder=""
                              data={dropdownOption}
                              id="DiscriminationAtWorkPolicy"
                              iconClassName="dropdownIcon"
                              name="DiscriminationAtWorkPolicy"
                              setValue={
                                formInputs.DiscriminationAtWorkPolicy || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Freedom of association"
                              placeholder=""
                              data={dropdownOption}
                              id="FreedomOfAssociationPolicy"
                              iconClassName="dropdownIcon"
                              name="FreedomOfAssociationPolicy"
                              setValue={
                                formInputs.FreedomOfAssociationPolicy || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Communication in a language that is fully understood by your employees"
                              placeholder=""
                              data={dropdownOption}
                              id="CommuncationFullyUnderstood"
                              iconClassName="dropdownIcon"
                              name="CommuncationFullyUnderstood"
                              setValue={
                                formInputs.CommuncationFullyUnderstood || ""
                              }
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Forced labour"
                              placeholder=""
                              data={dropdownOption}
                              id="ForcedLabourPolicy"
                              iconClassName="dropdownIcon"
                              name="ForcedLabourPolicy"
                              setValue={formInputs.ForcedLabourPolicy || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Harassment"
                              placeholder=""
                              data={dropdownOption}
                              id="HarassmentPolicy"
                              iconClassName="dropdownIcon"
                              name="HarassmentPolicy"
                              setValue={formInputs.HarassmentPolicy || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 16 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible16
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility16}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">ES</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Environmental Stewardship
                          </span>
                          <span className="subHeaderTitle">
                            For which of the following do you conduct
                            environmental impact assessment?
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible16 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Biodiversity"
                              placeholder=""
                              data={dropdownOption}
                              id="biodiversity"
                              iconClassName="dropdownIcon"
                              name="biodiversity"
                              setValue={formInputs.biodiversity || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Energy consumption"
                              placeholder=""
                              data={dropdownOption}
                              id="energyConsumption"
                              iconClassName="dropdownIcon"
                              name="energyConsumption"
                              setValue={formInputs.energyConsumption || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Waste generation"
                              placeholder=""
                              data={dropdownOption}
                              id="wasteGeneration"
                              iconClassName="dropdownIcon"
                              name="wasteGeneration"
                              setValue={formInputs.wasteGeneration || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Climate change"
                              placeholder=""
                              data={dropdownOption}
                              id="climateChange"
                              iconClassName="dropdownIcon"
                              name="climateChange"
                              setValue={formInputs.climateChange || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Water consumption"
                              placeholder=""
                              data={dropdownOption}
                              id="waterConsumption"
                              iconClassName="dropdownIcon"
                              name="waterConsumption"
                              setValue={formInputs.waterConsumption || ""}
                              getvalue={setDropdownData}
                              disabled={getDisabledStatus}
                              // className="dropdownOption"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 17 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible17
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility17}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">SI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">SAP Information</span>
                          <span className="subHeaderTitle">
                            SAP Information of Supplier
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                        <CommonFillter/>

                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible17 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="SAP ID"
                              name="sapAssociateNumber"
                              extraLabel="SAP Vendor ID"
                              placeholder=""
                              value={formInputs.sapAssociateNumber || ""}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Account Group"
                              name="accountGroup"
                              placeholder=""
                              value={formInputs.accountGroup || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Company Code"
                              extraLabel="SAP Company Code"
                              placeholder=""
                              id="companyCode"
                              iconClassName="dropdownIcon"
                              name="companyCode"
                              setValue={formInputs.companyCode || ""}
                              getvalue={setDropdownData}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Purchase Organization"
                              extraLabel="SAP Purchase organization"
                              placeholder=""
                              id="purchaseOrg"
                              iconClassName="dropdownIcon"
                              name="purchaseOrg"
                              setValue={formInputs.purchaseOrg || ""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Account Group"                          
                          name="accountGroup"
                          placeholder=""
                          value={formInputs.accountGroup || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>                    */}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      <div className="pageFooter">
        <Row className="rowDirection">
          {/* <Col xs={12} md={2} lg={2} className="">
            <button
              className="btnTable"
              onClick={handleSave}
              disabled={disabled}
            >
              <i className="bi bi-save"></i> Save
            </button>
          </Col>
          <Col xs={12} md={2} lg={2} className="">
            <button className="btnTable" onClick={handleModify}>
              <i className="bi bi-save"></i> Modify
            </button>
          </Col> */}
          {/* <Col xs={12} md={2} lg={2} className="">
            <button className="btnTable">
              <i className="bi bi-save"></i> Save as Draft
            </button>
          </Col> */}
          {/* <Col xs={12} md={2} lg={2} className="">
            <button className="btnTable">
              <i className="bi bi-save"></i> Excel Download
            </button>
          </Col> */}
        </Row>
      </div>

      <CategorySelectionModal show={modalShow} onHide={() => hideModal()} />
    </main>
  );
};

export default SupplierRegistration;
