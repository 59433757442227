/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/supplier_appCommonService";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch
import {
  getSupplierCategorySelection,
  getSupplierList,
} from "../../../../redux/feature/supplier_app/supplierSlice";
import { addCategorySelectedData } from "../../../../redux/feature/supplier_app/supplierSlice";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import CategorySelectionModal from "../../../../Components/Modal/supplier_app/CategorySelectionModal";
import DataTable from "react-data-table-component";
import Constants from "../../../../common/Constants";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";

const SupplierMasterCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Supplier");
  localStorage.setItem("submenu", "List");

  let userInfo = useSelector((state) => state?.user?.data); // User Details from Store
  let selectedCategoryData = useSelector(
    (state) => state?.supplier?.selectedCategory
  ); // User Details from Store
  let accountGroupName = useSelector((state) => state?.supplier?.accountGroup); // User Details from Store

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });
  const modalData = {
    title: "Supplier Registration",
    successMsg: "",
  };
  let dropdownOption = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];

  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  let requiredFields = [];

  const [getDropdownData, setDropdownData] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [getAccessData, setAccessData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [getSelectedData, setSelectedData] = useState([]);
  const [getDisabledRemove, setDisabledRemove] = useState(true);

  const [getPurchaseOrg, setPurchaseOrg] = useState([]);
  const [getCompanyCode, setCompanyCode] = useState([]);
  const [getCountry, setCountry] = useState([]);
  const [getState, setState] = useState([]);
  const [getTitle, setTitle] = useState([]);
  const [getcontactPosition, setcontactPosition] = useState([]);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);

  const [getFailureModal, setFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };

  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };

  /* Modal Function */
  const categorySelection = () => {
    setModalShow(true);
  };
  const hideModal = () => {
    setModalShow(false);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const [formInputs, setFormInputs] = useState({
    email: "",
    organisationName: "",
    firstName: "",
    version: "v0",
    status: "Non Invited",
    contactfirstName: "",
    contactlastName: "",
    contactNumber: "",
    category: "VENDOR",
    subCategory: [
      {
        categoryId: "TEMP-VENDOR",
        active: true,
      },
    ],
    roles: [
      {
        roleId: "TEMP-VENDOR",
        active: true,
      },
    ],
    accountGroup: accountGroupName,
    // initiatorName: "Admin",
    // initiator: "Admin",
    companyName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    contactTitle: "",
    cityPostalCode: "",
    ISOCertification: "",
    ICVCertificate: "",
    CompanyBrochure: "",
    purposeJustification: "",
    vendorCategory: [
      {
        categoryCode: "",
        subCategoryCode: "",
        vendorSubCategoryLongText: "",
        vendorCategoryText: "",
      },
    ],
    purchaseOrg: "",
    companyCode: "",
    contactDetails: [
      {
        lastName: "",
        firstName: "",
        contactEmail: "",
        contactTitle: "",
        contactNumber: "",
        contactPosition: "",
      },
    ],
  });
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(getSupplierCategorySelection()); // getSupplierCategorySelection store
    getMasterData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> data ss", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
      // setStateCode(getDropdownData?.code);
      getStateData(getDropdownData?.code);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // console.log(">> formInputs", formInputs);

  // Datatables
  const fetchUsers = async (page) => {
    setLoading(true);
    setData(selectedCategoryData);
    formInputs.vendorCategory = selectedCategoryData;
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryData]);

  const columns = [
    {
      name: "Category Code",
      selector: (row) => row.vendorCategoryCode || "--",
      sortable: true,
      width: "250px",
    },
    {
      name: "Category Desc",
      selector: (row) => row.vendorCategoryText || "--",
      sortable: true,
      width: "280px",
    },

    {
      name: "SubCategory",
      selector: (row) => row.vendorSubCategoryText || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Code",
      selector: (row) => row.vendorSubCategoryCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Desc",
      selector: (row) => row.vendorSubCategoryLongText || "--",
      sortable: true,
      width: "400px",
    },
  ];

  const handleChange = ({ selectedRows }) => {
    let res = selectedCategoryData.filter(
      (item) => !selectedRows.some((obj) => obj._id === item._id)
    );
    setSelectedData(res);
    if (res.length === selectedRows.length || res.length === 0) {
      setDisabledRemove(false);
    } else {
      setDisabledRemove(true);
    }
  };

  const removeCategory = () => {
    dispatch(addCategorySelectedData(getSelectedData));
    setDisabledRemove(true);
  };

  // SAVE
  const handleSave = async () => {
    if (formInputs.organisationName === "") {
      requiredFields.push("*Please enter Supplier Name");
      console.log(">> requiredFields", requiredFields);
      setErrorFields(requiredFields);

      setShowFailureModal(true);
      return;
    }

    console.log(">> handleSave", formInputs);
    formInputs.firstName = formInputs.organisationName;

    formInputs.initiatorName = "Self";
    formInputs.initiator = "Self";

    // initiatedBy: "Admin",
    // initiator: "Admin",

    let objContactDetails = {
      lastName: formInputs?.contactlastName,
      firstName: formInputs?.contactfirstName,
      contactEmail: formInputs?.email,
      contactTitle: formInputs?.contactTitle,
      contactNumber: formInputs?.contactNumber,
      contactPosition: formInputs?.contactPosition,
    };

    formInputs.contactDetails = [objContactDetails];

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });
    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addSelfSupplier(obj).then((item) => {
      console.log(">> item!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        // toast.success(item?.message || "Deleted successfully", {
        //   duration: 3000,
        //   position: "top-right",
        // });

        setModalInfo(modalData);
        onSuccessfullyRegistered();
        // Update response value in Redux Store
        dispatch(getSupplierList()); // event store
      } else {
        toast.error("Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {
      codeType: "title",
    };
    await ItemService.getMasterDataSelf(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setTitle(item?.data);
      } else {
        setTitle([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj0 = {
      codeType: "country",
    };
    await ItemService.getMasterDataSelf(obj0).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCountry(item?.data);
      } else {
        setCountry([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj1 = {
      codeType: "companyCode",
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCompanyCode(item?.data);
      } else {
        setCompanyCode([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj2 = {
      codeType: "purchaseOrganisation",
    };
    await ItemService.getMasterDataSelf(obj2).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setPurchaseOrg(item?.data);
      } else {
        setPurchaseOrg([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj3 = {
      codeType: "jobtitle",
    };
    await ItemService.getMasterDataSelf(obj3).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setcontactPosition(item?.data);
      } else {
        setcontactPosition([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  // Onselect of Country state will load
  const getStateData = async (code) => {
    let obj1 = {
      codeType: "region",
      code: code,
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setState(item?.data);
      } else {
        setState([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  const navigateToLogin = () => {
    navigate(Constants.LOGIN);
  };

  return (
    <main className="p-6">
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Self Supplier Registration Form</h2>
            <p className="subText">Supplier Details</p>
            <hr />

            <Row className="mb-4">
              <Col xs={12} md={2} lg={2} className="text-left">
                <button className="btnBack" onClick={() => navigateToLogin()}>
                  <i className="bi bi-arrow-return-left"></i> Go Back Login
                </button>
              </Col>
              <Col
                xs={12}
                md={{ span: 2, offset: 8 }}
                lg={{ span: 2, offset: 8 }}
                className="text-left"
              >
                <button
                  className="btnTable"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
            </Row>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Primary Data</span>
                      <span className="subHeaderTitle">Primary Details</span>
                    </Col>

                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Supplier Name"
                          extraLabel="As per the trade license"
                          name="organisationName"
                          placeholder=""
                          value={formInputs.organisationName || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label=" Address Lines 1 / Street"
                          name="address"
                          placeholder=""
                          value={formInputs.address || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Address Line 2"
                          name="address2"
                          placeholder=""
                          value={formInputs.address2 || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="City"
                          name="city"
                          placeholder=""
                          value={formInputs.city || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Country"
                          placeholder=""
                          data={getCountry}
                          id="country"
                          iconClassName="dropdownIcon"
                          name="country"
                          setValue={formInputs.country}
                          getvalue={setDropdownData}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="State"
                          placeholder=""
                          data={getState}
                          id="state"
                          iconClassName="dropdownIcon"
                          name="state"
                          setValue={formInputs.state}
                          getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Postal Code/PO Box"
                          name="cityPostalCode"
                          placeholder=""
                          value={formInputs.cityPostalCode || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Contact Person</span>
                      <span className="subHeaderTitle">
                        Contact Person Details
                      </span>
                    </Col>

                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Job Title"
                          placeholder=""
                          data={getcontactPosition}
                          id="contactPosition"
                          iconClassName="dropdownIcon"
                          name="contactPosition"
                          setValue={formInputs.contactPosition}
                          getvalue={setDropdownData}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Contact Title"
                          placeholder=""
                          data={getTitle}
                          id="contactTitle"
                          iconClassName="dropdownIcon"
                          name="contactTitle"
                          setValue={formInputs.contactTitle}
                          getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact First Name"
                          name="contactfirstName"
                          placeholder=""
                          value={formInputs.contactfirstName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact Last Name"
                          name="contactlastName"
                          placeholder=""
                          value={formInputs.contactlastName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact Email"
                          name="email"
                          placeholder=""
                          value={formInputs.email || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact Number"
                          name="contactNumber"
                          placeholder=""
                          value={formInputs.contactNumber || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">
                      More Information
                      </span>
                      <span className="subHeaderTitle">
                        Additional Information 
                      </span>
                    </Col>

                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Do you have ISO (9001, 14001, 45001) Certification?"
                          extraLabel="ICV Certification?"
                          name="ISOCertification"
                          data={dropdownOption}
                          id="ISOCertification"
                          iconClassName="dropdownIcon"
                          value={formInputs.ISOCertification || ""}
                          onChange={onInputChange}

                          // className="dropdownOption"
                        />
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Do you have a valid ICV Certification?"
                          extraLabel="ICV Certification?"
                          name="ICVCertificate"
                          data={dropdownOption}
                          id="ICVCertificate"
                          iconClassName="dropdownIcon"
                          value={formInputs.ICVCertificate || ""}
                          onChange={onInputChange}

                          // className="dropdownOption"
                        />
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Website and Company Brochure"
                          extraLabel="Website URL and Company Brochure Attachment"
                          name="CompanyBrochure"
                          placeholder=""
                          value={formInputs.CompanyBrochure || ""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Purpose/Justification- Please explain benefits Our Company would get from registering your company?"
                          extraLabel="Please explain benefits Our Company would get from registering your company?"
                          type="text"
                          name="purposeJustification"
                          id="purposeJustification"
                          value={formInputs.purposeJustification || ""}
                          onChange={onInputChange}

                          // className="dropdownOption"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CS</span>
                    </Col>
                    <Col xs={8} md={6} lg={6}>
                      <span className="headerTitle">Classification</span>
                      <span className="subHeaderTitle">
                        Select Required Group
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2} className="btnSpaceInMobile">
                      <button
                        className="btnTable"
                        onClick={() => categorySelection()}
                      >
                        <i className="bi bi-plus-lg"></i> Ad
                      </button>
                    </Col>

                    <Col xs={5} md={2} lg={2} className="btnSpaceInMobile">
                      <button
                        className="btnTable"
                        onClick={() => removeCategory()}
                        disabled={getDisabledRemove}
                      >
                        <i className="bi bi-trash3"></i> Remove Category
                      </button>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          onSelectedRowsChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">SAP Information</span>
                      <span className="subHeaderTitle">
                        SAP Information of Supplier
                      </span>
                    </Col>

                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Account Group"
                          name="accountGroup"
                          placeholder=""
                          value={formInputs.accountGroup || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Company Code"
                          extraLabel="SAP Company Code"
                          placeholder=""
                          data={getCompanyCode}
                          id="companyCode"
                          iconClassName="dropdownIcon"
                          name="companyCode"
                          setValue={formInputs.companyCode || ""}
                          getvalue={setDropdownData}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Purchase Organization"
                          extraLabel="SAP Purchase organization"
                          placeholder=""
                          data={getPurchaseOrg}
                          id="purchaseOrg"
                          iconClassName="dropdownIcon"
                          name="purchaseOrg"
                          setValue={formInputs.purchaseOrg || ""}
                          getvalue={setDropdownData}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </Col>
      </Row>

      {/* <Row className="rowDirection">
        <Col xs={12} md={2} lg={2} className="">
          <button className="btnTable" onClick={handleSave} disabled={disabled}>
            <i className="bi bi-save"></i> Save
          </button>
        </Col>
      </Row> */}

      <CategorySelectionModal show={modalShow} onHide={() => hideModal()} />

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SUPPILER_LIST}
      />

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default SupplierMasterCreate;
