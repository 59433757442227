import React, { useState, useEffect } from "react";

const RiverseTimer = ({ hours }) => {

  // State to hold the total seconds remaining
  const [totalSeconds, setTotalSeconds] = useState();

  // Calculate days, hours, minutes, and seconds from total seconds
  const daysRemaining = Math.floor(totalSeconds / (24 * 60 * 60));
  const hoursRemaining = Math.floor(
    (totalSeconds % (24 * 60 * 60)) / (60 * 60)
  );
  const minutesRemaining = Math.floor((totalSeconds % (60 * 60)) / 60);
  const secondsRemaining = totalSeconds % 60;

  // Helper function to pad numbers with leading zeros
  const padNumber = (num) => {
    return String(num).padStart(2, "0");
  };

  // Pad the numbers
  const paddedDays = padNumber(daysRemaining);
  const paddedHours = padNumber(hoursRemaining);
  const paddedMinutes = padNumber(minutesRemaining);
  const paddedSeconds = padNumber(secondsRemaining);

  useEffect(() => {
    // Function to update the timer every second
    const countdown = setInterval(() => {
      setTotalSeconds((prevTotalSeconds) => {
        if (prevTotalSeconds <= 0) {
          clearInterval(countdown);
          return 0;
        }
        return prevTotalSeconds - 1;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    // console.log(">> hours", hours);
    if(hours){
      const initialTotalSeconds = Number(hours).toFixed(2) * 60 * 60;
      setTotalSeconds(Number(initialTotalSeconds))
    }else{
      setTotalSeconds(0)
    }
   

  }, [hours]);

  return (
    <>
      <div className="d-flex rivers-timer">
        <div className="d-flex-column  align-items-center">
          <div className="d-flex align-items-center fs-4 timer-box">
            <p className="border  p-2 m-1">{paddedDays[0]}</p>
            <p className="border  p-2 m-1">{paddedDays[1]}</p>
            <span className="mx-2">:</span>
          </div>
          <span className="me-3 d-flex justify-content-center">Days</span>
        </div>

        <div className="d-flex-column align-items-center">
          <div className="d-flex align-items-center fs-4 timer-box">
            <p className="border  p-2 m-1">{paddedHours[0]}</p>
            <p className="border  p-2 m-1">{paddedHours[1]}</p>
            <span className="mx-2">:</span>
          </div>
          <span className="me-3 d-flex justify-content-center">Hours</span>
        </div>

        <div className="d-flex-column align-items-center">
          <div className="d-flex  align-items-center fs-4 timer-box">
            <p className="border  p-2 m-1">{paddedMinutes[0]}</p>
            <p className="border  p-2 m-1">{paddedMinutes[1]}</p>
            <span className="mx-2">:</span>
          </div>
          <span className="me-3 d-flex justify-content-center">Mins</span>
        </div>

        <div className="d-flex-column align-items-center">
          <div className="d-flex justify-content-center align-items-center fs-4 timer-box">
            <p className="border p-2 m-1">{paddedSeconds[0]}</p>
            <p className="border p-2 m-1">{paddedSeconds[1]}</p>
          </div>
          <span className="d-flex justify-content-center">Secs</span>
        </div>
      </div>
    </>
  );
};
export default RiverseTimer;
