/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { InputField } from "../../../formElements/InputField";
import { ComboboxField } from "../../../formElements/ComboboxField";
import Constants from "../../../../common/Constants";

const CreateOtherChanges = React.forwardRef((props, ref) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [description, setAccessData] = useState("");
  
  let dropdownOption = [
    {
      id: "1",
      name: "200-Spares and Operations Maintenance",
    },
  ];

  const [formInputs, setFormInputs] = useState({
    description: "",
    value: ""
  });

  const handleSubmit = async () => {
    if (formInputs.description === "" || formInputs.value === "") {
      setErrorMsg("* Fields are mandatory");
      return;
    } else {
      setErrorMsg("");
    }

    const data = {
      description: formInputs.description.name || formInputs.description,
      value: formInputs.value
    };
    // console.log(">> data ", data);
    props.onData(data);  
    props.onHide();      
  };

  useEffect(() => {
    setFormInputs({
      description: "",
      value: ""
    });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  useEffect(() => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      description: description,
    }));
  }, [description]);

  return (
    <Modal
      // {...props}
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      dialogClassName="modal-90w"
      //ref={ref}
      // animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> Create Other Changes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col xs={12} md={12} lg={6} className="commLeftRightPadding">
              <ComboboxField
                label="Description"
                placeholder="Description"
                data={dropdownOption}
                id="description"
                iconClassName="dropdownIcon"
                name="description"
                setValue={formInputs.description || ""}
                getvalue={setAccessData}
                className="dropdownOption"
              />
            </Col>
            <Col xs={12} md={12} lg={6} className="commLeftRightPadding">
              <InputField
                type="text"
                className="inputBox"
                label="Value"
                name="value"
                placeholder=""
                value={formInputs.value || ""}
                onChange={onInputChange}
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CreateOtherChanges;
