import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { useNavigate } from "react-router-dom";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//API
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import Constants from "../../../../common/Constants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { formatDate } from "../../../../common/Validations";
import ApproveRemarksModal from "../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import SuccessModal from "../../../../Components/Modal/supplier_app/SuccessModal";
import toast from "react-hot-toast";

const GRNDetails = () => {
  // const navigate = useNavigate();
  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "");

  // let grn_data = useSelector(
  //   (state) => state?.purchasing?.goods_received_note?.data
  // ); // User Details from Store

  let grnNumber = useSelector(
    (state) => state?.purchasing?.goods_received_note?.grnNumber
  ); // User Details from Store

  let workflow = useSelector(
    (state) => state?.purchasing?.purchase_order?.workflowPO
  ); // workflowPO from Store

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getHeaderData, setHeaderData] = useState({
    grnStatusHeader: "",
  });

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);

  const [getItemHeaderTextData, setItemHeaderTextData] = useState("");
  const [getItemHeaderTextColumns, setItemHeaderTextColumns] = useState("");
  const [getItemHeaderTextModalTitle, setItemHeaderTextModalTitle] =
    useState(false);
  const [getItemTextModalShow, setItemTextModalShow] = useState(false);

  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  // Workflow
  const [modalShow, setModalShow] = useState(false);
  const [getAction, setAction] = useState("");

  /* success modal */
  const successModalData = {
    title: "Message",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getsucceModalData, setsucceModalData] = useState(successModalData);
  const [getNextNavigate, setNextNavigate] = useState("");
  const onSuccessmodel = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const itemHeaderColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  // OnClick on ITEM Text Data
  const itemTextShowModal = (itemTextData) => {
    setItemHeaderTextData(itemTextData);
    setItemHeaderTextColumns(itemHeaderColInfo);
    setItemHeaderTextModalTitle("Item Text Details");
    setItemTextModalShow(true);
  };
  const itemTextHideModal = () => {
    setItemTextModalShow(false);
  };

  const columns = [
    {
      name: "GRN Item Nr",
      selector: (row) => row.grnItemNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "PO Item",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "100px",
    },
    {
      name: "PO Number",
      selector: (row) => row.purchasingDocumentNr,
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.grnStatusItem,
      cell: (row) => (
        <>
          {row.grnStatusItem === "GRN Created" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.grnStatusItem}
            </span>
          ) : row.grnStatusItem === "Pending for Approval" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.grnStatusItem}
            </span>
          ) : row.grnStatusItem === "GRN Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.grnStatusItem}
            </span>
          ) : row.grnStatusItem === "Invoice Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.grnStatusItem}
            </span>
          ) : row.grnStatusItem === "GRN Rejected" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.grnStatusItem}
            </span>
          ) : row.grnStatusItem === "Posted" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.grnStatusItem}
            </span>
          ) : row.grnStatusItem === "Invoice Created" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.grnStatusItem}
            </span>
          ) : row.grnStatusItem === "Approve" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.grnStatusItem}
            </span>
          ) : (
            row.grnStatusItem
          )}
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => itemTextShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Schedule Number",
      selector: (row) => row.scheduleNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "180px",
    },

    {
      name: "Material Desc",
      selector: (row) => row.materialDescription,
      sortable: true,
      width: "180px",
    },

    {
      name: "Order Quantity",
      selector: (row) => row.asnQuantity,
      sortable: true,
      width: "150px",
    },

    {
      name: "UOM",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "150px",
    },

    {
      name: "Plant",
      selector: (row) => row.businessLocation,
      sortable: true,
      width: "200px",
    },
    {
      name: "ShortageLocation",
      selector: (row) => row.storagePlace,
      sortable: true,
      width: "200px",
    },
  ];

  const loadInitialPageData = async () => {
    setLoading(true);
    if (grnNumber) {
      let obj = {
        documentNumber: grnNumber,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });

      await ItemService.getGrnDatail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setHeaderData(item?.data[0]);
          let itemData = item?.data[0]?.items;
          setFormInputs(item?.data[0]);
          setData(itemData);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grnNumber]);

  const navigateToWorkflow = () => {
    window.history.back();
  };

  const showApproveModal = async (name) => {
    setModalShow(true);
    setAction(name);
  };

  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {};
    let newObj = { ...bodyObj, ...obj };

    //Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        successModalData.successMsg =
          getAction === "Approve"
            ? "Approved Successfully !!"
            : "Rejected Successfully !!";
        setsucceModalData(successModalData);
        setNextNavigate(Constants.INBOX);
        onSuccessmodel();
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };

  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              GRN Details: {grnNumber}
              <span>
                {getHeaderData?.grnStatusHeader === "GRN Created" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : getHeaderData?.grnStatusHeader ===
                  "Pending for Approval" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : getHeaderData?.grnStatusHeader === "GRN Cancelled" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : getHeaderData?.grnStatusHeader === "Invoice Cancelled" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : getHeaderData?.grnStatusHeader === "GRN Rejected" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : getHeaderData?.grnStatusHeader === "Posted" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : getHeaderData?.grnStatusHeader === "Invoice Created" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : getHeaderData?.grnStatusHeader === "Approve" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.grnStatusHeader}
                  </span>
                ) : (
                  getHeaderData.grnStatusHeader
                )}
              </span>
            </h2>
            <p className="subText">GRN Information </p>
            <hr />

            <Row className="mb-4">
              <Col xs={12} md={4} lg={4} className="text-left">
                <button
                  className="btnBack"
                  onClick={() => navigateToWorkflow()}
                >
                  <i className="bi bi-arrow-return-left"></i> Go Back
                </button>
              </Col>
              <Col xs={12} md={8} lg={8} className="text-right">
                {workflow?.workflow_record?.decision?.length > 0 &&
                  workflow?.workflow_record?.decision?.map((item, index) => (
                    <button
                      className={
                        item === "Approve" || item === "Submit"
                          ? "btnCommon btn-approve"
                          : "btnCommon btn-danger"
                      }
                      onClick={() => showApproveModal(item)}
                      key={index}
                    >
                      <i
                        className={
                          item === "Approve" || item === "Submit"
                            ? "bi bi-check2-circle"
                            : "bi bi-x-circle"
                        }
                      ></i>{" "}
                      {item}
                    </button>
                  ))}
              </Col>
            </Row>

            {/* grn details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">ID</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">GRN Details</span>
                      {/*  <span className="subHeaderTitle">
                        RFQ Header Information
                      </span> */}
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="GRN Number"
                          name="grnNr"
                          id="grnNr"
                          value={formInputs.grnNr}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SAP GRN No"
                          name="sapGrnNr"
                          id="sapGrnNr"
                          value={formInputs.grnNr}
                          // //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Number"
                          name="purchasingDocumentNr"
                          id="purchasingDocumentNr"
                          value={formInputs.purchasingDocumentNr}
                          // //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivered On"
                          name="createdAt"
                          id="createdAt"
                          value={formatDate(formInputs.createdAt)}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Created On"
                          name="createdAt"
                          id="createdAt"
                          value={formatDate(formInputs.createdAt)}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Note"
                          name="deliveryNoteNr"
                          id="deliveryNoteNr"
                          value={formInputs.deliveryNoteNr}
                          // value={"400000 - Vasanthraman"}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Remarks"
                          name="grnRemark"
                          id="grnRemark"
                          value={formInputs.grnRemark}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bill Of Lading"
                          name="billOfLading"
                          id="billOfLading"
                          value={formInputs.billOfLading || "--"}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Requestor"
                          name="requestor"
                          id="requestor"
                          value={formInputs.requestor || "--"}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Incoterm"
                          name="incoTerms"
                          id="incoTerms"
                          value={formInputs.incoTerms}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="paymentTerms"
                          id="paymentTerms"
                          value={formInputs.paymentTerms}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status "
                          name="firstName"
                          placeholder=""
                          value={formatDate(formInputs.createdAt)}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Value"
                          name="createdBy"
                          id="createdBy"
                          value={formInputs.createdBy}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer"
                          name="createdBy"
                          id="createdBy"
                          value={formInputs.createdBy}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Value"
                          name="purchasingDocumentValue"
                          id="purchasingDocumentValue"
                          value={formInputs.purchasingDocumentValue}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          id="currency"
                          value={formInputs.currency}
                          disabled={true}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Pdf"
                          name="firstName"
                          placeholder=""
                          // value={"400000 - Vasanthraman"}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Line Items 2*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">ID</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">GRN Details</span>
                      {/* <span className="subHeaderTitle">
                        Material/ Service Details
                      </span> */}
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}

                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* GRN Attachments 3*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">GA</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">GRN Attachments</span>
                      {/*  <span className="subHeaderTitle">
                        RFQ Header Information
                      </span> */}
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Shipping Document"
                          name="UnitPrice"
                          type="file"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          //onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={{ span: 4, offset: 4 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Other Attachments"
                          name="UnitPrice"
                          type="file"
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Historical Log of Approval 4 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LG</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Historical Log of Approval
                      </span>
                      {/*  <span className="subHeaderTitle">
                        RFQ Header Information
                      </span> */}
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* Historical Log of Approval */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={getItemTextModalShow}
        onHide={() => itemTextHideModal()}
        columns={getItemHeaderTextColumns}
        data={getItemHeaderTextData}
        title={getItemHeaderTextModalTitle}
      />

      <ApproveRemarksModal
        show={modalShow}
        onHide={() => hideModal()}
        onvaluechange={handleValueChange}
      />

      {/* Success Modal Workflow */}
      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
        nextnavigate={getNextNavigate}
      />
    </main>
  );
};

export default GRNDetails;
