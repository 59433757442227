import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

const DynamicForm = React.forwardRef((props, ref) => {
  const [dropdownOption] = useState([
    { id: '1', name: 'input' },
    { id: '2', name: 'textarea' },
    { id: '3', name: 'select' },
  ]);

  const [description, setDescription] = useState('');
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    if (props.editData) {
      setDescription(props.editData.label);
      setSelectedType(props.editData.type);
    } else {
      setDescription('');
      setSelectedType('');
    }
  }, [props.editData]);

  const handleSave = () => {
    props.onSave({ type: selectedType, label: description });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add/Edit Element
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="space-y-4">
          <Row>
            <Col xs={12}>
              <textarea
                className="inputBox"
                label="Label"
                placeholder="Enter label"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: '100%', height: '60px' }}
              />
            </Col>
            <Col xs={12}>
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                style={{ width: '100%', marginTop: '10px' }}
              >
                <option value="">Select Element Type</option>
                {dropdownOption.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="justify-end">
            <Col xs={6}>
              <button
                type="button"
                onClick={props.onHide}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6}>
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
              >
                Save
              </button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default DynamicForm;
