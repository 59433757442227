/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../services/supplier_appCommonService";

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
// import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import RiverseTimer from "../../../Components/RiverseTimer";
import DataTable from "react-data-table-component";
import { TextAreaField } from "../../../Components/formElements/TextAreaField";
import CommonItemLevelDatatableModal from "../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { formatDateTime } from "../../../common/Validations";
import { Button, Card } from "react-bootstrap";

const BuyerRFQDetails = () => {
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Buyer Request for Quotation");

  let rfxNr = useSelector((state) => state?.sourcing?.rfq?.rfxNr); // User Details from Store

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  const [data, setData] = useState([]);
  const [getAccessData, setAccessData] = useState("");
  const [loading, setLoading] = useState(false);
  const [getHeaderData, setHeaderData] = useState(false);

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);

  const [formInputs, setFormInputs] = useState({
    rfxType: "",
    productType: "",
    rfxValue: "",
    rfxHeaderStatus: "",
    rfxbidType: "",
    rfxNoOfEvaluationType: "",
    rfxCurrency: "",
    rfxTechFocalPersonId: "",
    technicalApprovalType: "",
    plant: "",
    buyerResponsible: "",
    companyAddress: "",
    rfxVersion: "",
    buyerEmail: "",
    initiatorName: "",
    initiator: "",
    billingAddressCodeDesc: "",
    createdOn: "",
    buyerConact: "",
    deliveryAddressCodeDesc: "",
    rfxCategoryType: "",
    rfxCategoryCode: "",
    rfxCategoryDesc: "",
    rfqCreation: "",
    rfxTitle: "",
    openingdate: "",
    openingtime: "",
    closingdate: "",
    closingtime: "",
    rfxDescription: "",
    typeOfPurchase: "",
    otherCurrencies: "",
    headerIncoTerms: "",
    incoTermsLocation: "",
    procurementStrategies: "",
    items: "",
    formCOMM: "",
    formTECH: "",
    termsConditions: [],
    supplierList:[],

    openingDateTime: "",
    closingDateTime: "",
    totalHour: "",
  });

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };

  const [isCardVisible9_1, setCardVisible9_1] = useState(true);

  const [isCardVisible8_1, setCardVisible8_1] = useState(true);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  /*  PRLineItem*/
  let PRLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service ",
      selector: (row) => row.materialCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      width: "200px",
    },
    {
      name: "Unit Price ",
      selector: (row) => row.orderPriceUnit || "--",
      width: "200px",
    },
    {
      name: "Item Value ",
      selector: (row) => row.netValue || "--",
      width: "200px",
    },
    {
      name: "Currency ",
      selector: (row) => row.currencyCode || "INR",
      width: "200px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "VAT Code % ",
      selector: (row) => row.taxCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.plant || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Storage Location",
      selector: (row) => row.storageLocn || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      width: "120px",
    },
  ];

  const suppliersColumns = [
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Non Invited" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.status}
            </span>
          ) : row.status === "Invited" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.status}
            </span>
          ) : row.status === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.status}
            </span>
          ) : row.status === "Registered" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.status}
            </span>
          ) : row.status === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.status}
            </span>
          ) : (
            row.status
          )}
        </>
      ),
      width: "170px",
    },

    {
      name: "Supplier openDate",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP Number",
      selector: (row) => row.sapAssociateNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Person",
      selector: (row) => row.contactfirstName || "--",
      sortable: true,
      width: "150px",
    },

    {
      name: "Contact Number",
      selector: (row) => row.contactNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "EMail",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "initiator",
      selector: (row) => row.initiator || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "ICV Score %",
      selector: (row) => row.totalScore || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Category",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            // onClick={() => categoryItemShowModal(row.vendorCategory)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.vendorCategory?.length || 0}{" "}
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt,
      sortable: true,
      width: "160px",
    },
    {
      name: "Company Code",
      cell: (row) => (
        <>
          <span> {row.companyCode} </span>
        </>
      ),
      width: "150px",
    },
  ];

  const loadInitialPageData = async () => {
    setLoading(true);
    if (rfxNr) {
      let obj = {
        documentNumber: rfxNr,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getRfxDocumentDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setHeaderData(item.data);
          setFormInputs(item.data);
          setData(item?.data?.items);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      window.history.back();
    }
  };

  // PO items columns Details
  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  // Show Item level data
  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Item Text Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  useEffect(() => {
    loadInitialPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxNr]);

  // Render Questions
  const RenderInputField = ({ questionData }) => {
    const { componentId, mandatory, question, answerDefinition, serialNo } =
      questionData;

    switch (answerDefinition?.type) {
      case "textarea":
        return (
          <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
            <TextAreaField
              label={serialNo + ". " + question?.label}
              id={componentId}
              name={componentId}
              className="inputBox"
              placeholder=""
              // value={getRemarks}
              // onChange={handleInputChange}
              required={mandatory}
            />
          </Col>
        );
      case "select":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <ComboboxField
              label={serialNo + ". " + question?.label}
              placeholder=""
              data={answerDefinition?.options}
              id={componentId}
              iconClassName="dropdownIcon"
              name={componentId}
              code={componentId}
              required={mandatory}
              // setValue={formData.type || ""}
              // getvalue={setDropdownData}
            />
          </Col>
        );
      case "radio":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
              {serialNo + ". " + question?.label} {mandatory && "*"}
            </label>

            {answerDefinition?.options.map((option, index) => {
              return (
                <div key={index} className="block">
                  <input
                    type="radio"
                    className="form-check-input fs-5 ml-3"
                    id={`${componentId}-${index}`}
                    name={componentId}
                    // value={option?.value}
                    required={mandatory}
                  />
                  <span className="ml-3">{option?.value} </span>
                </div>
              );
            })}
          </Col>
        );
      case "checkbox":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
              {serialNo + ". " + question?.label} {mandatory && "*"}
            </label>

            {answerDefinition?.options.map((option, index) => {
              return (
                <div key={index} className="block">
                  <input
                    type="checkbox"
                    className="form-check-input fs-5 ml-3"
                    id={`${componentId}-${index}`}
                    name={componentId}
                    // value={option?.value}
                    required={mandatory}
                  />
                  <span className="ml-3">{option?.value} </span>
                </div>
              );
            })}
          </Col>
        );
      case "text":
        return (
          <Col
            xs={12}
            md={6}
            lg={6}
            className="commTopButtonRightLeftPadding block"
          >
            <InputField
              label={serialNo + ". " + question?.label}
              type="text"
              className="inputBox"
              id={componentId}
              name={componentId}
              placeholder=""
              // value={option.value}
              // onChange={(e) =>
              //   handleOptionChange(index, e.target.value)
              // }
              required={mandatory}
            />
          </Col>
        );
      case "number":
        return (
          <Col
            xs={12}
            md={6}
            lg={6}
            className="commTopButtonRightLeftPadding block"
          >
            <InputField
              label={serialNo + ". " + question?.label}
              type="number"
              className="inputBox"
              id={componentId}
              name={componentId}
              placeholder=""
              // value={option.value}
              // onChange={(e) =>
              //   handleOptionChange(index, e.target.value)
              // }
              required={mandatory}
            />
          </Col>
        );
      default:
        return "";
    }
  };

  const files = [
    { name: "1.1. SCOPE OF WORK", url: "" },
    { name: "1.2. DRAWING", url: "" },
    { name: "1.3. ANNEXURES", url: "" },
    { name: "1.4. OTHERS", url: "" },
  ];

  // Attachments
  const Attachments = ({ files }) => {
    return (
      <div className="attachments-list">
        <Row>
          {files.map((file, index) => (
            <Col key={index} md={4} className="commLeftRightPadding">
              <Card>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={2} className="text-center">
                      <i
                        className="bi bi-file-earmark-text"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    </Col>
                    <Col xs={6}>
                      <p
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        className="pt-2"
                      >
                        {file.name}
                      </p>
                    </Col>
                    <Col xs={4} className="text-end">
                      <button className="btn btnTable" href={file.url} download>
                        <i className="bi bi-download"></i> Download
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Buyer RFQ : {formInputs?.rfxNr}
            <span>
                {formInputs?.rfxHeaderStatus === "Published" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.rfxHeaderStatus}
                  </span>
                ) : formInputs?.rfxHeaderStatus === "Cancelled" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.rfxHeaderStatus}
                  </span>
                ) : formInputs?.rfxHeaderStatus === "RFQ Created" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.rfxHeaderStatus}
                  </span>
                ) : formInputs?.rfxHeaderStatus === "Acknowledged" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.rfxHeaderStatus}
                  </span>
                ) : (
                  formInputs?.rfxHeaderStatus
                )}
              </span>
            </h2>
            <p className="subText">Buyer RFQ Details</p>
            <hr />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={12} md={6} lg={6} className="">
                      <p className="fs-4">Remaining Time for submission</p>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <i className="bi bi-clock fs-1"></i>
                    </Col>
                    <Col className="d-flex" xs={9} md={4} lg={4}>
                      <RiverseTimer hours={formInputs.totalHour} />
                    </Col>
                  </Row>
                </h2>
              </div>
            </div>

            {/*RFQ Type  */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RT</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">RFQ Type </span>
                      <span className="subHeaderTitle">
                        RFQ Type Information
                      </span>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="headerButtonAlign">
                      <Col xs={4} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                          onClick={() => {
                            alert("asd");
                          }}
                        >
                          <i className="bi bi-x-circle"></i> Regert
                        </button>
                      </Col>
                      <Col xs={4} md={3} lg={3} className="">
                        <button
                          className="btnTable"
                          onClick={() => {
                            alert("asd");
                          }}
                        >
                          <i className="bi bi-clipboard-check"></i> Create
                          Quotation
                        </button>
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Category"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.rfxCategoryDesc || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Type"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.rfxTypeDesc || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Header User id"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}

                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number Of Evaluation"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.rfxNoOfEvaluationTypeDesc || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Product Type"
                          name="firstName"
                          placeholder=""
                          value={formInputs.productTypeDesc || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/*RFQ Type  */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">RFQ Header </span>
                      <span className="subHeaderTitle">
                        RFQ Header Information
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="rfxNr"
                          // placeholder=""
                          value={formInputs.rfxNr || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.rfxCurrencyCode || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.rfxCurrencyCode || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.initiatorName || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Version"
                          name="firstName"
                          placeholder=""
                          value={formInputs.rfxVersion || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Billing Address"
                          name="firstName"
                          placeholder=""
                          value={formInputs.billingAddressDesc || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Address"
                          name="firstName"
                          placeholder=""
                          value={formInputs.companyAddressDesc || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Address"
                          name="firstName"
                          placeholder=""
                          value={formInputs.companyAddressDesc || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/*Header Info */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Header Info</span>
                      <span className="subHeaderTitle">
                        RFQ Header Information
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.rfxTitle || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Description"
                          name="UnitPrice"
                          // placeholder=""
                          value={formInputs.rfxDescription || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Opening Date (UAE Time)"
                          name="UnitPrice"
                          // placeholder=""
                          value={
                            formatDateTime(formInputs.openingDateTime) || ""
                          }
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Closing Date (UAE Time)"
                          name="UnitPrice"
                          // placeholder=""
                          value={
                            formatDateTime(formInputs.closingDateTime) || ""
                          }
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="INCOTerms"
                          name="firstName"
                          placeholder=""
                          value={formInputs.INCOTermslocation || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="INCOTerms Location"
                          name="firstName"
                          placeholder=""
                          value={formInputs.INCOTermslocation || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="firstName"
                          placeholder=""
                          // value={"400000 - Vasanthraman"}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Other Currencies"
                          name="firstName"
                          placeholder=""
                          // value={"400000 - Vasanthraman"}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Milestone Payments"
                          name="firstName"
                          placeholder=""
                          // value={"400000 - Vasanthraman"}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Instruction Attachments"
                          name="firstName"
                          placeholder=""
                          // value={"400000 - Vasanthraman"}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Tender Documents */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Tender Documents</span>
                      <span className="subHeaderTitle">
                        Tender Documents Form
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <h1 className="card-title">Tender Documents :</h1>

                        <Attachments files={files} />
                        <h5 className="card-title"> Terms & Conditions</h5>
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            lg={8}
                            className="commTopButtonRightLeftPadding"
                          >
                            {formInputs.termsConditions.map((item, i) => (
                              <div className="form-check" key={item.id}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`gridCheck${item.id}`}
                                  checked={item.status}
                                  // onChange={() =>
                                  //   handelTermsConditionCheckBox(item.id)
                                  // }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`gridCheck${item.id}`}
                                >
                                  {item.name}
                                </label>
                              </div>
                            ))}
                          </Col>
                          <Col xs={12} md={12} lg={3}>
                            <ComboboxField
                              label="Acceptance To Terms & Conditions"
                              placeholder=""
                              data={dropdownOption}
                              id=""
                              iconClassName="dropdownIcon"
                              name=""
                              // setValue={formInputs.INCOTermslocation || ""}
                              disabled={true}
                              // getvalue={setDropdownData}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 5 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SD</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Suppliers</span>
                      <span className="subHeaderTitle">
                        Selected Suppliers Details
                      </span>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={suppliersColumns}
                          data={formInputs.supplierList}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 6 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle">
                        Material/ Service Details
                      </span>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={PRLineItemInitialCol}
                          data={data}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* COMM - Spares & Operation Maintenance 08*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Form: COMM- Spares & Operation Maintenance
                      </span>
                      {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="commTopButtonRightLeftPadding">
                      {formInputs.formCOMM.length > 0 &&
                        formInputs.formCOMM.map((formJsonData, index) => {
                          return (
                            <RenderInputField
                              questionData={formJsonData}
                              key={index}
                            />
                          );
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Form :TECH - Spares & Operation Maintenance09*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible9
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility9}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TS</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Form : TECH - Spares & Operation Maintenance
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible9 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="commTopButtonRightLeftPadding">
                      {formInputs.formTECH.length > 0 &&
                        formInputs.formTECH.map((formJsonData, index) => {
                          return (
                            <RenderInputField
                              questionData={formJsonData}
                              key={index}
                            />
                          );
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default BuyerRFQDetails;
