import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";

//Redux
import { useSelector, useDispatch } from "react-redux";

//API
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import Constants from "../../../../common/Constants";
import { currencyFormat, getCurrentDate } from "../../../../common/Validations";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import HeaderText from "../../../../Components/Modal/supplier_app/PO_Collabration/HeaderText";
import { getInvoiceList } from "../../../../redux/feature/supplier_app/purchasingSlice";

const CreateInvoice = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "Purchase Order");

  let selectedPoDetails = useSelector(
    (state) => state?.purchasing?.purchase_order?.selectedPoDetails
  ); // selectedPoDetails Details from Store

  let poNumber = useSelector(
    (state) => state?.purchasing?.purchase_order?.poNumber
  ); // purchase_order from Store

  let userData = useSelector((state) => state?.user.data); // User Details from Store

  // invoice columns
  const invoiceDataColumns = [
    {
      name: "Invoice Item Nr",
      selector: (row) => row.invoiceItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "PO Item Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "120px",
    },
    {
      name: "GRN Item Nr",
      selector: (row) => row.grnItemNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "GRN Nr",
      selector: (row) => row.grnNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-info-status">{"New"}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },
    {
      name: "Purchase Qty",
      selector: (row) => row.purchaseQuantity,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="purchaseQuantity"
            value={row.purchaseQuantity}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "GRN Qty",
      selector: (row) => row.grnQuantity,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="grnQuantity"
            value={row.grnQuantity}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Invoice Qty",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="invoiceQuantity"
            name="invoiceQuantity"
            value={row.invoiceQuantity}
            onChange={(e) =>
              handleInputChangeTable(
                row.local_id,
                e.target.value,
                row.grnQuantity,
                row.orderPriceUnit,
                row.invBalancePurchaseQuantity,
                row.originalBalancePurchaseQuantity
              )
            }
            disabled={row.originalBalancePurchaseQuantity === 0 ? true : false}
          />
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Invoice Balance Qty",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="invBalancePurchaseQuantity"
            value={Number(row?.invBalancePurchaseQuantity) || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      // selector: (row) => row.orderPriceUnit,
      cell: (row) => (
        <span className="numberFormate">
          {currencyFormat(Number(row.orderPriceUnit).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      selector: (row) => row.totalPrice,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="totalPrice"
            value={row.totalPrice || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Inv Item TAX",
      selector: (row) => row.invItemTaxValue,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="invItemTaxValue"
            value={
              Number(
                Number(row.taxCode / 100) * Number(row.invoiceQuantity)
              ) || 0
            }
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Tax Code",
      selector: (row) => row.taxCode,
      sortable: true,
      width: "130px",
    },
    {
      name: "Total Value",
      selector: (row) => row.netValue,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="netValue"
            value={row.netValue || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "PReq Nr",
      selector: (row) => row.purchaseReqNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "PReq Item Nr ",
      selector: (row) => row.purchaseReqitemNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "ExcessTolerance",
      selector: (row) => row.excessTolerance,
      sortable: true,
      width: "200px",
    },
    {
      name: "ShortageTolerance",
      selector: (row) => row.shortageTolerance,
      sortable: true,
      width: "200px",
    },
  ];

  const modalData = {
    title: "Invoice Creation",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [disabled, setDisabled] = useState(false);

  // const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  // const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);

  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [getDisabledStatus, setDisabledStatus] = useState(true);
  const [getHeaderData, setHeaderData] = useState(false);
  const [itemTableData, setItemTableData] = useState([]);

  const [getInvoiceValue, setInvoiceValue] = useState(0);
  const [currentDate] = useState(getCurrentDate("default"));
  const [subTotalValue, setSubTotalValue] = useState(0);
  const [getInvTaxTotalValue, setInvTaxTotalValue] = useState(0);
  const [getOtherChargesValue, setOtherChargesValue] = useState(0);

  const [getSupplierBankData, setSupplierBankData] = useState(0);
  const [getDropdownData, setDropdownData] = useState([]);

  const [formInputs, setFormInputs] = useState({
    transactionActivity: "INV",
    subTransactionActivity: "GRN",
    vendorCode: "",
    purchasingDocumentNr: "",
    purchasingDocumentDate: "",
    purchasingDocumentValue: "",
    currency: "",
    currencyCode:"",
    paymentTerms: "",
    totalInvoiceAmount: "",
    sumOfCurrentAmt: "",
    notes: "",
    weighSlipQty: "",
    itemClass: "",
    vendorInvoiceNo: "",
    vendorInvoiceDate: "",
    vendorInvoiceCurrency: "",
    vendorBasicValue: "",
    vendorFreightValue: "",
    vendorTaxValue: "",
    vendorOtherChargesValue: "",
    vendorTotalInvoiceValue: "",
    invoicedValue: "",
    generatedBy: "",
    createdBy: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible4);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible4);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  // Get Dropdown data select
  useEffect(() => {
    if (getDropdownData?.textField === "selectedBankDetails") {
      delete getDropdownData.textCode;
      delete getDropdownData.index;
      
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.selected,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // Function to handle text changes
  const handleInputChangeTable = (
    id,
    value,
    grnQuantity,
    orderPriceUnit,
    invBalancePurchaseQuantity,
    originalBalancePurchaseQuantity
  ) => {
    if (Number(originalBalancePurchaseQuantity) >= Number(value)) {
      let totalPriceVal = Number(orderPriceUnit) * Number(value);
      setInvoiceValue(totalPriceVal);

      const updatedData = itemTableData.map((item) => {
        return item.local_id === id
          ? {
              ...item,
              invoiceQuantity: value,
              invBalancePurchaseQuantity: (
                Number(originalBalancePurchaseQuantity) - Number(value)
              ).toFixed(2),
              totalPrice: totalPriceVal,
              invItemTaxValue: Number( Number(item.taxCode / 100) * Number(value) )
            }
          : item;
      });

      setItemTableData(updatedData);
      getTotalCount(updatedData);
    } else {
      // console.log("Do Nothing");
    }
  };

  // Get Total for SubTotal Pricing Schedule
  const getTotalCount = (data) => {
    const getTotal = data.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.totalPrice || 0),
      0
    );
    setSubTotalValue(getTotal);

    let invTotalTaxVa = 0;
    data.map((item) => {
      return (invTotalTaxVa +=
        (Number(item.taxCode) / 100) *
        Number(item.invoiceQuantity || 0));
    });
    setInvTaxTotalValue(Number(invTotalTaxVa));
  };

  // load invoice data on page load
  const loadInitalPageData = async () => {
    setLoading(true);
    if (poNumber) {
      setHeaderData(selectedPoDetails);

      let obj = {
        purchasingDocumentNr: poNumber,
      };

      await ItemService.createInvoice(obj).then((item) => {
        // console.log(">> res! createInvoice", item);
        if (item?.status === 200) {
          const updatedItemData = item?.data.map((obj, index) => ({
            ...obj,
            invoiceItemNr: index + 1,
            local_id: index + 1,
            originalBalancePurchaseQuantity: obj.invBalancePurchaseQuantity,
            invItemTaxValue: 0,
          }));

          setItemTableData(updatedItemData);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
      });

      setLoading(false);
    } else {
      window.history.back();
    }
  };

  // load SupplierBank data on page load
  const loadSupplierBank = async () => {
    if (poNumber) {
      let obj = {
        userId: userData?.userId,
      };

      await ItemService.getSupplierBank(obj).then((item) => {
        // console.log(">> res! getSupplierBank", item);
        if (item?.status === 200) {
          setSupplierBankData(item.data);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
      });
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    loadInitalPageData();
    loadSupplierBank(); // load bank
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poNumber, selectedPoDetails]);

  // const [getHeaderTextIndex, setHeaderTextIndex] = useState(0);
  const [HeaderTextModel, setHeaderTextModel] = useState(false);

  /* Header text selected data */
  const [getHeaderTextSelectedData, setHeaderTextSelectedData] = useState([]);
  const handleHeaderTextSelectedData = (data) => {
    setHeaderTextSelectedData(data);
  };

  useEffect(() => {
    const getTotal = getHeaderTextSelectedData.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue?.documentText || 0),
      0
    );
    setOtherChargesValue(getTotal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHeaderTextSelectedData]);

  /* Create Header Text Modal Function */
  const showsetHeaderText = () => {
    setHeaderTextModel(true);
  };
  const hideHeaderTextModel = () => {
    setHeaderTextModel(false);
  };

  const HeaderTextColumns = [
    {
      name: "Code",
      cell: (row) => (
        <>
          <span>{row.documentId}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Value",
      cell: (row) => (
        <>
          <span>{row.documentText}</span>
        </>
      ),
      width: "30rem",
    },
    // {
    //   name: "Delete",
    //   cell: (row, index) => (
    //     <>
    //       <span>
    //         <i
    //           onClick={() => handleDelete(index)}
    //           className="bi bi-trash text-danger fs-3"
    //         ></i>
    //       </span>
    //     </>
    //   ),
    //   width: "10rem",
    // },
  ];

  // SAVE
  const handleSave = async () => {
    // let requiredFields = [];

    // if(!validateRequired(formInputs?.organisationName)) {
    //   requiredFields.push('*Please enter Supplier Name');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!validateEmail(formInputs?.email)) {
    //   requiredFields.push('*Invalid Contact Email address');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!getPOLineItemsData[0]?.materialCode) {
    //   toast.error("Alteast one PO Line item should be added.", {
    //     duration: 4000,
    //     position: "top-right",
    //   });
    //   return;
    // }

    formInputs.items = itemTableData;
    formInputs.vendorCode = getHeaderData.vendorCode;
    formInputs.vendorName = getHeaderData.vendorName;
    formInputs.vendorInvoiceDate = currentDate;
    formInputs.deliveredValue = getHeaderData.deliveredValue;
    formInputs.deliveredStatus = getHeaderData.deliveredStatus;
    formInputs.invoicedValue = getHeaderData.invoicedValue;
    formInputs.invoicedStatus = getHeaderData.invoicedStatus;
    formInputs.paidValue = getHeaderData.paidValue;
    formInputs.vendorInvoiceCurrency = getHeaderData.currencyCode;
    formInputs.currency = getHeaderData.currencyCode;
    formInputs.purchasingDocumentDate = getHeaderData.purchasingDocumentDate;
    formInputs.incoTerms = getHeaderData.incoTerms;
    formInputs.paymentTerms = getHeaderData.paymentTerms;
    formInputs.purchasingDocumentNr = getHeaderData.purchasingDocumentNr;
    formInputs.purchasingDocumentValue = getHeaderData.purchasingDocumentValue;
    formInputs.currency = getHeaderData.currency;
    formInputs.currencyCode = getHeaderData.currencyCode;
    formInputs.vendorOtherChargesValue = Number(
      getOtherChargesValue.toFixed(2)
    );
    formInputs.vendorTotalInvoiceValue = Number(
      (
        Number(subTotalValue) +
        Number(getOtherChargesValue) +
        Number(getInvTaxTotalValue) +
        Number(formInputs.vendorFreightValue)
      ).toFixed(2)
    );
    formInputs.vendorBasicValue = Number(subTotalValue.toFixed(2));
    formInputs.vendorTaxValue = Number(getInvTaxTotalValue);

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addInvoice(obj).then((item) => {
      console.log(">> res!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();

        // Update response value in Redux Store
        dispatch(getInvoiceList());
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />

      <Row className="justify-center subContent">
        <Col xs={12} md={12} lg={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Create-Invoice for {getHeaderData?.purchasingDocumentNr}
              <span>
                {getHeaderData?.statusHeader === "New" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : getHeaderData?.statusHeader === "In Release" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : getHeaderData?.statusHeader === "Acknowledged" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : (
                  getHeaderData?.statusHeader
                )}
              </span>
            </h2>
            <p className="subText">Fill Invoice Details</p>
            <hr />

            {itemTableData.length === 0 && (
              <>
                <h1>
                  GRN not available for this PO -{" "}
                  {getHeaderData?.purchasingDocumentNr}
                </h1>
              </>
            )}

            {itemTableData.length > 0 && (
              <>
                {/* Invoice header */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">GH</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Invoice Header</span>
                          <span className="subHeaderTitle">Invoice Header</span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col> */}
                      </Row>
                    </h2>
                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Invoice Date"
                              name="vendorInvoiceDate"
                              placeholder=""
                              value={currentDate}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Invoice Value"
                              name="invoiceValue"
                              placeholder=""
                              value={getInvoiceValue}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Weigh Slip Qty"
                              name=""
                              placeholder=""
                              // value={""}
                              onChange={onInputChange}
                              disabled={false}
                            />
                          </Col>
                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice No "
                          name=""
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col> */}
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Currency"
                              name="currency"
                              placeholder=""
                              value={`${getHeaderData?.currencyCode} - ${getHeaderData?.currency}`}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Invoice"
                              name=""
                              type="file"
                              placeholder=""
                              // value={""}
                              onChange={onInputChange}
                              disabled={false}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="PO Number"
                              name="purchasingDocumentNr"
                              placeholder=""
                              value={getHeaderData.purchasingDocumentNr}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="PO Value"
                              name="purchasingDocumentValue"
                              placeholder=""
                              value={getHeaderData.purchasingDocumentValue}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Supporting Document"
                              name=""
                              type="file"
                              placeholder=""
                              // value={""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Vendor"
                              name="vendorCode"
                              placeholder=""
                              value={getHeaderData.vendorCode}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Vendor Name"
                              name="vendorName"
                              placeholder=""
                              value={getHeaderData.vendorName}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Tax Amount"
                              name=""
                              placeholder=""
                              value={Number(getInvTaxTotalValue)}
                              // onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Vendor Name"
                              name="vendorName"
                              placeholder=""
                              value={getHeaderData.vendorName}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <TextAreaField
                              className="inputBox"
                              label="Notes "
                              name=""
                              placeholder=""
                              // value={""}
                              onChange={onInputChange}
                              disabled={false}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* invoice service itme details table*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">PI</span>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                          <span className="headerTitle">
                            Invoice Service Item Details
                          </span>
                          <span className="subHeaderTitle">
                            Invoice Service Item Details
                          </span>
                        </Col>
                        <Col xs={4} md={2} lg={2} className="">
                          <button className="btnTable">
                            <i className="bi bi-file-excel"></i>Template
                          </button>
                        </Col>
                        <Col xs={4} md={2} lg={2} className="">
                          <button
                            className="btnTable"
                            onClick={() => {
                              alert("asd");
                            }}
                          >
                            <i className="bi bi-file-excel"></i> Upload
                          </button>
                        </Col>
                        <Col xs={2} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col>
                      </Row>
                    </h2>
                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              // title=""
                              columns={invoiceDataColumns}
                              data={itemTableData}
                              progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* other changes table */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible5
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility5}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">OC</span>
                        </Col>

                        <Col xs={5} md={9} lg={9}>
                          <span className="headerTitle">Other Charges</span>
                          <span className="subHeaderTitle">
                            Other Charges Table{" "}
                          </span>
                        </Col>
                        <Col xs={2} md={1} lg={1} className="">
                          <button
                            className="btnTable"
                            onClick={showsetHeaderText}
                          >
                            <i className="bi bi-plus-lg"></i>
                          </button>
                        </Col>
                        <Col xs={1} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible5 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              // title=""
                              columns={HeaderTextColumns}
                              data={getHeaderTextSelectedData}
                              // progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Pricing Summary */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible6
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility6}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">PS</span>
                        </Col>
                        <Col xs={8} md={4} lg={4}>
                          <span className="headerTitle">Pricing Summary</span>
                          <span className="subHeaderTitle">
                            Pricing Summary{" "}
                          </span>
                        </Col>
                        <Col xs={2} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible6 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body p-2">
                        <Row className=" ms-3 ">
                          <Col
                            xs={8}
                            md={4}
                            lg={4}
                            className="d-flex my-2 jsutify-content-center align-items-center"
                          >
                            <p className="fw-medium">
                              SubTotal Pricing Schedule / Line Items
                            </p>
                          </Col>
                          <Col
                            xs={4}
                            md={6}
                            lg={6}
                            className="d-flex my-2 jsutify-content-center align-items-center"
                          >
                            <InputField
                              className="inputBox"
                              name="subTotalValue"
                              placeholder=""
                              value={subTotalValue}
                              disabled={true}
                            />
                            {/* <p className="ms-4 fw-medium"> AED</p> */}
                          </Col>
                          <Col
                            xs={8}
                            md={4}
                            lg={4}
                            className="d-flex my-2 jsutify-content-center align-items-center "
                          >
                            <p className="fw-medium"> SubTotal Other Charges</p>
                          </Col>
                          <Col
                            xs={4}
                            md={7}
                            lg={7}
                            className="d-flex my-2 jsutify-content-center align-items-center "
                          >
                            <InputField
                              className="inputBox"
                              name="getOtherChargesValue"
                              placeholder=""
                              value={getOtherChargesValue}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={8}
                            md={4}
                            lg={4}
                            className="d-flex my-2 jsutify-content-center align-items-center"
                          >
                            <p className="fw-medium">Gross Total</p>
                          </Col>
                          <Col
                            xs={4}
                            md={7}
                            lg={7}
                            className="d-flex jsutify-content-center align-items-center"
                          >
                            <InputField
                              className="inputBox"
                              name="grossTotalValue"
                              placeholder=""
                              value={subTotalValue + getOtherChargesValue}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* invoice details */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">ID</span>
                        </Col>

                        <Col xs={4} md={8} lg={8}>
                          <span className="headerTitle"> Invoice details</span>
                          <span className="subHeaderTitle">
                            Invoice details
                          </span>
                        </Col>
                        <Col xs={1} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <div className="accordion-body">
                              <Row className="pt-2 pb-4">
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Invoice Number *"
                                    name="vendorInvoiceNo"
                                    id="vendorInvoiceNo"
                                    value={formInputs.vendorInvoiceNo}
                                    onChange={onInputChange}
                                    disabled={false}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Invoice Date*"
                                    name="vendorInvoiceDate"
                                    id="vendorInvoiceDate"
                                    type="date"
                                    value={formInputs.vendorInvoiceDate}
                                    onChange={onInputChange}
                                    disabled={false}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Basic Value *"
                                    name="vendorBasicValue"
                                    placeholder="0.00"
                                    id="vendorBasicValue"
                                    value={subTotalValue.toFixed(2)}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Tax Amount"
                                    name="vendorTaxValue"
                                    placeholder="0.00"
                                    id="vendorTaxValue"
                                    value={Number(getInvTaxTotalValue)}
                                    // onChange={onInputChange}
                                    disabled={true}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Other Charges"
                                    name="vendorOtherChargesValue"
                                    placeholder="0.00"
                                    id="vendorOtherChargesValue"
                                    value={getOtherChargesValue.toFixed(2)}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Freight Value"
                                    name="vendorFreightValue"
                                    placeholder="0.00"
                                    id="vendorFreightValue"
                                    value={formInputs.vendorFreightValue}
                                    onChange={onInputChange}
                                    disabled={false}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Total Invoice Value "
                                    name="vendorTotalInvoiceValue "
                                    placeholder="0.00"
                                    id="vendorTotalInvoiceValue"
                                    value={(
                                      Number(subTotalValue) +
                                      Number(getOtherChargesValue) +
                                      Number(getInvTaxTotalValue) +
                                      Number(formInputs.vendorFreightValue)
                                    ).toFixed(2)}
                                    // onChange={onInputChange}
                                    disabled={true}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Invoice Bank Details */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">ID</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Invoice Bank Details
                          </span>
                          <span className="subHeaderTitle">
                            Invoice Bank Details
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col>
                      </Row>
                    </h2>
                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Select Bank A/C Number "
                              placeholder=""
                              data={getSupplierBankData}
                              id="selectedBankDetails"
                              iconClassName="dropdownIcon"
                              name="selectedBankDetails"
                              setValue={formInputs?.selectedBankDetails?.name || []}
                              getvalue={setDropdownData}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Bank Account Holder Name"
                              name="AccountHolderName"
                              placeholder=""
                              value={getDropdownData?.AccountHolderName}
                              // onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Swift Code/ IFSC Code"
                              name="swiftIfscCode"
                              placeholder=""
                              value={getDropdownData?.swiftIfscCode}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Bank Name"
                              name="bankName2"
                              placeholder=""
                              value={getDropdownData?.bankName2}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Branch Address"
                              name="bankAddress"
                              placeholder=""
                              value={getDropdownData?.bankAddress}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Bank Country"
                              name="bankCountry"
                              placeholder=""
                              value={getDropdownData?.bankCountry}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Bank ID"
                              name="bankKey"
                              placeholder=""
                              value={getDropdownData?.bankKey}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="IBAN"
                              name="iban"
                              placeholder=""
                              value={getDropdownData?.iban}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/*footer create entry sheet button   */}
                <div className="pageFooter">
                  <Row className="rowDirection">
                    <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btn w-full"
                        onClick={handleSave}
                        disabled={disabled}
                      >
                        Create Invoice
                      </button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      {/* Header Text */}
      <HeaderText
        index={0}
        headername="Add Other Charges"
        show={HeaderTextModel}
        onHide={hideHeaderTextModel}
        hedertextdata={handleHeaderTextSelectedData}
        showcurrentdata={getHeaderTextSelectedData}
        masterdata="POHeaderTextID"
        type="Number"
      />

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE}
      />
    </main>
  );
};

export default CreateInvoice;
