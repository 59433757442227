/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import DataTable from "react-data-table-component";
import RiverseTimer from "../../../Components/RiverseTimer";

const RequestForQuotationDetailPage = () => {
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];
  let reminderDropDown = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];

  const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible7, setCardVisible7] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible8_1, setCardVisible8_1] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);
  const [isCardVisible9_1, setCardVisible9_1] = useState(true);
  const [getAccessData, setAccessData] = useState("");
  const [getInputFields, setInputFields] = useState(true);
  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility7 = () => {
    setCardVisible7(!isCardVisible7);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility8_1 = () => {
    setCardVisible8_1(!isCardVisible8_1);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const toggleCardVisibility9_1 = () => {
    setCardVisible9_1(!isCardVisible9_1);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };
  const lineItemsData = [
    {
      Profile: "Profile",
      RFQItem: "RFQ Item",
      PRNumber: "PR Number",
      PRLine: "PR Line",
      MatSerCode: "Mat/Ser Code",
      MatSerDesc: "Mat/Ser Desc*",
      HeaderMatSerCodeDesc: "Header Mat/Ser Code/Desc",
      ItemText: "Item Text",
      MaterialPOText: "Material PO Text",
      RFQQuantity: "RFQ Quantity*",
      UOM: "UOM*",
      PriceUnitQty: "Price Unit Qty",
      InternalRate: "Internal Rate",
      Currency: "Currency",
      RFQValue: "RFQ Value",
      RequirementDate: "Requirement Date",
      TrackingNumber: "Tracking Number",
      QCRequirements: "QC Requirements",
      ProjectWBS: "Project WBS",
      ItemAttachments: "Item Attachments",
      EstimatedRFQValue: "Estimated RFQ Value",
      Delete: "Delete",
    },
  ];

  const lineItemsColumns = [
    {
      name: "Profile",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "RFQ Item",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "PR Number",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "PR Line",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Mat/Ser Code",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Mat/Ser Desc*",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Header Mat/Ser Code/Desc",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Item Text",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Material PO Text",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "RFQ Quantity*",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "UOM*",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Price Unit Qty",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Internal Rate",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Currency",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "RFQ Value",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Requirement Date",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Tracking Number",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "QC Requirements",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Project WBS",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Item Attachments",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Estimated RFQ Value",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Delete",
      cell: (row) => <></>,
      width: "150px",
    },
  ];
  /* suppliers */
  const supplierSData = [
    {
      SNO: "S.NO",
      SupplierCodeAndName: "Supplier Code And Name",
      SupplierCategory: "Supplier Category",
      Status: "Status",
      ICVValue: "ICV Value",
      ICVValidity: "ICV Validity",
      SupplierUser: "Supplier User",
      SAPSupplierNumber: "SAP Supplier Number",
      SupplierAdditionalUser: "Supplier Additional User",
      EmailId: "Email Id*",
      ContactNo: "Contact No*",
      AllowPDFQuotation: "Allow PDF Quotation",
      Delete: "Delete",
    },
  ];
  const suppliersColumns = [
    {
      name: "S.NO",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Supplier Code And Name",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Supplier Category",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "ICV Value",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "ICV Validity",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Supplier User",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "SAP Supplier Number",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Supplier Additional User",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Email Id*",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Contact No*",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Allow PDF Quotation",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Delete",
      cell: (row) => <></>,
      width: "150px",
    },
  ];

  const [setHideForm, getHideForm] = useState(false);
  console.log("setToggleHideForm:", setHideForm);
  useEffect(() => {
    if (getAccessData.name === "Yes") {
      getHideForm(true);
    } else if (getAccessData.name === "No") {
      getHideForm(false);
    }
    console.log("setAccessData ", getAccessData.name);
  }, [getAccessData.name]);
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Invoicequisition supplier_app</h2>
            <p className="subText">Invoicehe Main title</p>
            <hr />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={6} md={3} lg={3} className="">
                      <p className="fs-4">Remaining Time for submission</p>
                    </Col>
                    <Col xs={6} md={1} lg={1} className="">
                      <i className="bi bi-clock fs-1"></i>
                    </Col>
                    <Col className="d-flex" xs={12} md={6} lg={6}>
                      <RiverseTimer days={7} />
                    </Col>
                  </Row>
                </h2>
              </div>
            </div>
            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RT</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">RFQ Type</span>
                      <span className="subHeaderTitle">Basic Information</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="RFQ Type"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Product Type"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Estimated RFQ Value *"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Bid Type"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Number Of Evaluation"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Currency"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Tech Focal Point *"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Technical Approval Type"
                          className="inputBox"
                          /* label="Closing Date:" */
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">RFQ Header</span>
                      <span className="subHeaderTitle">Header Description</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Plant"
                          /* label="Closing Date:" */
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Create / Initiator"
                          name="firstName"
                          placeholder=""
                          // value=""
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Version"
                          name="firstName"
                          placeholder=""
                          // value={"v0"}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Category"
                          name="firstName"
                          placeholder=""
                          // value={"200-SAPMAT"}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Buyer Responsible"
                          /* label="Closing Date:" */
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Address"
                          name="firstName"
                          placeholder=""
                          // value=""
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Email"
                          name="firstName"
                          placeholder=""
                          // value={"v0"}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Billing Address"
                          name="firstName"
                          placeholder=""
                          // value={"v0"}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Created On"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="firstName"
                          placeholder=""
                          // value={"9895656599"}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Address"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Tech open Status"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Comm Open Status"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* header info 03*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Header Info</span>
                      <span className="subHeaderTitle">Header Information</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title"
                          name="title"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Opening Date (Time)"
                          name="Opening Date"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Closing Date (Time)"
                          name="title"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Description"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Type of Purchase"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Other Currencies"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Term"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="INCO Terms"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="INCO Terms Location"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Procurement Strategy"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Milestone Payments"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        {" "}
                        {/*   <span> Instructions Attachment</span>
                        <input type="file" id="myfile" name="myfile" /> */}
                        <InputField
                          className="inputBox"
                          label="Instructions Attachment"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          type="file"
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={3} className="pb-3">
                        <InputField
                          className="inputBox"
                          label="Select an Option"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* COMM - Spares & Operation Maintenance 08*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Form: INTRO-Spares & Operations Maintenance
                      </span>
                      {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                    </Col>

                    <Col xs={1} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* Form: INTRO-Spares & Operations Maintenance */}
                  </div>
                </div>
              </div>
            </div>

            {/* Tender Documents 05 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Tender Documents</span>
                      <span className="subHeaderTitle">
                        Tender Documents Form
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <h5 className="card-title mb-2">Tender Documents :</h5>
                        <h5 className="card-title">
                          {" "}
                          Terms & Conditions and Company documents can be
                          downloaded{" "}
                        </h5>
                        <Row>
                          <Col xs={12} md={12} lg={8}>
                            <ul className="ml-3 mt-3">
                              <li className="mb-3">Terms and Conditions</li>
                              <li className="mb-3">Code of Conduct</li>
                              <li className="mb-3">HSE Requirements</li>
                            </ul>
                          </Col>
                        </Row>
                        <h5 className="card-title">
                          ESA Packaging Specifications and Trucking
                        </h5>
                        <Row>
                          <Col xs={12} md={12} lg={8}>
                            <ul className="ml-3 mt-3">
                              <li className="mb-3">
                                ESI Packaging Requirement
                              </li>
                              <li className="mb-3">
                                ESI Packaging Requirement
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Line Items 06*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle">
                        Material/ Service Details
                      </span>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable">Download Template</button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        Upload Date
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        Add PR Items
                      </button>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={lineItemsColumns}
                          data={lineItemsData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Suppliers 07*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible7
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility7}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Suppliers</span>
                      <span className="subHeaderTitle">
                        Material/ Service Details
                      </span>
                    </Col>

                    <Col xs={4} md={1} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        Add from Supplier Master
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        Initiate Supplier Request
                      </button>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible7 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={suppliersColumns}
                          data={supplierSData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Single Source Justification*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Single Source Justification
                      </span>
                      {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                    </Col>

                    <Col xs={1} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* Form: INTRO-Spares & Operations Maintenance */}
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Single Source Justification"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={getInputFields}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* COMM - Spares & Operation Maintenance 08*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Form: COMM- Spares & Operation Maintenance
                      </span>
                      {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                    </Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* new forms */}
                    <div className="accordion p-4 " id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <Row className="accordion-button">
                            <Col
                              xs={1}
                              md={1}
                              lg={1}
                              className="text-left arrowBtn"
                            >
                              <i
                                className={`bi ${
                                  isCardVisible8_1
                                    ? "bi-chevron-right"
                                    : "bi-chevron-down"
                                }`}
                                onClick={toggleCardVisibility8_1}
                              ></i>
                            </Col>
                            <Col
                              xs={1}
                              md={1}
                              lg={1}
                              className="text-center logoBtn"
                            >
                              <span className="iconLogo">OC</span>
                            </Col>

                            <Col xs={4} md={8} lg={8}>
                              <span className="headerTitle">new form</span>
                              {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                            </Col>
                            <Col xs={4} md={1} lg={1} className="">
                              <button
                                className="btnTable"
                                // onClick={createDynamicformModel}
                              >
                                <i className="bi bi-plus-lg"></i>
                              </button>
                            </Col>
                            <Col xs={4} md={1} lg={1} className="">
                              <button
                                className="btnTable"
                                // onClick={() => delteDyanmicForm(index)}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </Col>
                            <Col xs={1} md={1} lg={1} className="">
                              <SourcingCommonFilter />
                            </Col>
                          </Row>
                        </h2>

                        <div
                          // id="acc"
                          className={`accordion-collapse collapse show ${
                            isCardVisible8_1 ? "visible" : ""
                          }`}
                        >
                          <div className="accordion-body p-3">
                            <Row>
                              <Col
                                xs={12}
                                md={12}
                                lg={4}
                                className="commTopButtonRightLeftPadding"
                              >
                                <ComboboxField
                                  label="1.1Payment Terms in Days "
                                  placeholder=""
                                  data={dropdownOption}
                                  id="INCOTermslocation"
                                  iconClassName="dropdownIcon"
                                  name="INCOTermslocation"
                                  setValue={formInputs.INCOTermslocation || ""}
                                  getvalue={setAccessData}
                                  disabled={getInputFields}
                                  // className="dropdownOption"
                                />
                              </Col>

                              <Col
                                xs={12}
                                md={12}
                                lg={{ span: 4, offset: 0 }}
                                className="commTopButtonRightLeftPadding "
                              >
                                <ComboboxField
                                  label="1.2Bid/Offer Validity in Days"
                                  placeholder=""
                                  data={dropdownOption}
                                  id="INCOTermslocation"
                                  iconClassName="dropdownIcon"
                                  name="INCOTermslocation"
                                  setValue={formInputs.INCOTermslocation || ""}
                                  getvalue={setAccessData}
                                  disabled={getInputFields}
                                  // className="dropdownOption"
                                />
                              </Col>

                              <Col
                                xs={12}
                                md={12}
                                lg={{ span: 4, offset: 0 }}
                                className="commTopButtonRightLeftPadding "
                              >
                                <InputField
                                  className="inputBox"
                                  label="1.3Completion/Delivery Period from the date of LOA/PO (Days)"
                                  name="UnitPrice"
                                  // placeholder=""
                                  // value={PriceSheduleModuledata.UnitPrice || ""}

                                  onChange={onInputChange}
                                  disabled={getInputFields}
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                lg={4}
                                className="commTopButtonRightLeftPadding"
                              >
                                <p className="fw-medium text-dark">
                                  1.4Bidder shall verify latest the In Country
                                  Value(ICV) Value, Validity and Certificate
                                </p>
                                <div>
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="Yes"
                                    className="me-2"
                                  />
                                  <span className="me-2">yes</span>
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="No"
                                    className="me-2"
                                  />
                                  <span className="me-2">No</span>
                                </div>
                              </Col>
                              <Col
                                xs={12}
                                md={12}
                                lg={{ span: 4, offset: 0 }}
                                className="commTopButtonRightLeftPadding "
                              >
                                <ComboboxField
                                  label="1.5Packing and Forwarding"
                                  placeholder=""
                                  data={dropdownOption}
                                  id="INCOTermslocation"
                                  iconClassName="dropdownIcon"
                                  name="INCOTermslocation"
                                  setValue={formInputs.INCOTermslocation || ""}
                                  getvalue={setAccessData}
                                  disabled={getInputFields}
                                  // className="dropdownOption"
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* buttons */}
          <div className="pageFooter">
            <Row className="rowDirection">
              <Col xs={12} md={2} lg={2} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  Open TE Rejected Comm
                </button>
              </Col>
              <Col xs={12} md={2} lg={1} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  Pre Close
                </button>
              </Col>
              <Col xs={12} md={2} lg={1} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  Cancel RFQ
                </button>
              </Col>
              <Col xs={12} md={2} lg={1} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  Tech Open
                </button>
              </Col>
              <Col xs={12} md={2} lg={1} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  Comm Open
                </button>
              </Col>
              <Col xs={12} md={2} lg={1} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  RFQ Modify
                </button>
              </Col>
              <Col xs={12} md={2} lg={2} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  Excel Download
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default RequestForQuotationDetailPage;
