/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../Components/formElements/TextAreaField";
import RiverseTimer from "../../../Components/RiverseTimer";
import Constants from "../../../common/Constants";
import CreateOtherChanges from "../../../Components/Modal/supplier_app/CreateQuotation/CreateOtherChanges";
import PriceShedule from "../../../Components/Modal/supplier_app/CreateQuotation/PriceShedule";
import DynamicForm from "../../../Components/Modal/supplier_app/CreateQuotation/DynamicForm";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";

const TechnicalEvaluation = () => {
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Technical Evaluation");

  // let supplierData = useSelector(
  //   (state) => state?.supplier.data
  // ); /* suplierlist  data from suplier */

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  /*  let dynamicFormOption=[
    {id:"1",name:'Vertical'},
    {id:"2",name:'Sequetional'}, 
  ]
  console.log('dynamicform:',dynamicFormOption.name) */
  const [dynamicFormOption, setDynamicFormOption] = useState([
    { id: "1", name: "Vertical" },
    { id: "2", name: "Sequetional" },
  ]);
  const [selectedDynamicForm, setSelectedDynamicForm] = useState(
    dynamicFormOption[0]
  ); // Default is 'Vertical'
  const handleOptionChange = (option) => {
    setSelectedDynamicForm(option);
    // console.log("selectedDynamicForm::", selectedDynamicForm);
  };
  const createDynamicForm = () => {
    // console.log("create new dyanmic form");
    setdyanmicformCount(dyanmicformCount + 1);
    setdyanamicFormData([...dyanamicFormData, null]);
  };
  const delteDyanmicForm = (index) => {
    if (dyanmicformCount > 1) {
      // Prevent deleting the only div
      const updatedyanmicFormdata = [...dyanamicFormData];
      updatedyanmicFormdata.splice(index, 1); // Remove element at specified index
      setdyanamicFormData(updatedyanmicFormdata);
      setdyanmicformCount(dyanmicformCount - 1); // Decrement div count
    } else {
      alert("Cannot delete the last div!"); // Inform user about limitation
    }
  };

  // const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);

  const [getAccessData, setAccessData] = useState("");
  const [getDisabledStatus, setDisabledStatus] = useState(true);

  const [loading, setLoading] = useState(false);
  const [otherchangesmodelshow, setotherchangesmodelshow] = useState(false);
  const [DynamicFormModelshow, setDynamicFormModelshow] = useState(false);
  const [PriceShedulemodalShow, setPriceShedulemodalShow] = useState(false);
  const [PriceSheduleModuledata, setPriceSheduleModuledata] = useState([]);
  const [dyanmicformCount, setdyanmicformCount] = useState(1);
  const [dyanamicFormData, setdyanamicFormData] = useState(
    Array(dyanmicformCount).fill(null)
  );
  const [toggleDynamicform, settoggleDynamicform] = useState(false);

  // console.log("PriceSheduleModuledata>>", PriceSheduleModuledata);
  /* data from createotherchanges */
  const [CreateOtherchangedata, setCreateOtherchangedata] = useState([]);
  // console.log('CreateOtherchangedata:',CreateOtherchangedata)
  const handleDataFromCreateOtherChanges = (data) => {
    // console.log("Data received from child:", data);
    setCreateOtherchangedata((prevData) => [...prevData, data]);
  };
  const handleDelete = (indexToDelete) => {
    setCreateOtherchangedata((prevData) =>
      prevData.filter((_, index) => index !== indexToDelete)
    );
  };

  /*  */
  const handelDataPriceShedule = (data) => {
    // console.log('priceSheduleData>>',data)
    setPriceSheduleModuledata(data);
  };

  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  /* other changes popup  Modal Function */
  const createotherchangesmodel = () => {
    setotherchangesmodelshow(true);
  };

  const hideOtherChangesModel = () => {
    setotherchangesmodelshow(false);
  };
  /* dyanamic form popup  Modal Function */
  const createDynamicformModel = () => {
    setDynamicFormModelshow(true);
  };

  const hideDynamicFormModel = () => {
    setDynamicFormModelshow(false);
  };

  /* Price Shedule handling function */
  const CreatePriceSheduleModel = () => {
    setPriceShedulemodalShow(true);
  };
  const hidePriceSheduleModal = () => {
    setPriceShedulemodalShow(false);
  };
  /* dynami form  */

  /* suppliers  table  */
  const Suppliersdata = [
    {
      Profile: "Profile",
      Supplier: "Supplier",
      SAPSupplierNumber: "SAP Supplier Number",
      QuotationNumber: "Quotation Number",
      QuotationDate: "Quotation Date",
      QuotationVersion: "Quotation Version",
      TechProposal: "Tech Proposal",
      Score: "Score*",
      Status: "Status",
      Remarks: "Remarks"
    }
  ]
  const SuppliersColumns = [
    {
      name: "Profile",
      cell: (row) => (
        <>
          <span>{row.Profile}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Supplier",
      cell: (row) => (
        <>
          <span>{row.Supplier}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "SAP Supplier Number",
      cell: (row) => (
        <>
          <span>{row.SAPSupplierNumber}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Quotation Number",
      cell: (row) => (
        <>
          <span>{row.QuotationNumber}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Quotation Date",
      cell: (row) => (
        <>
          <span>{row.QuotationDate}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Quotation Version",
      cell: (row) => (
        <>
          <span>{row.QuotationVersion}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Tech Proposal",
      cell: (row) => (
        <>
          <span>{row.TechProposal}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Score*",
      cell: (row) => (
        <>
          <span>{row.Score}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <span>{row.Status}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Remarks",
      cell: (row) => (
        <>
          <span>{row.Remarks}</span>
        </>
      ),
      width: "200px",
    },
  ]
  /* table for other changes */
  const OtherChangesColumns = [
    {
      name: "",
      cell: (row) => (
        <>
          <span></span>
        </>
      ),
      width: "5rem",
    },
    {
      name: "name",
      cell: (row) => (
        <>
          <span>{row.description}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Vlaue",
      cell: (row) => (
        <>
          <span>{row.value}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Delete",
      cell: (row, index) => (
        <>
          <span>
            <i
              onClick={() => handleDelete(index)}
              className="bi bi-trash text-danger fs-3"
            ></i>
          </span>
        </>
      ),
      width: "10rem",
    },
  ];
  /* material info */
  const MaterialinfoColumns=[
    {
    name: "Profile",
    cell: (row) => (
      <>
        <span>{row.Profile}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "RFQ Item",
    cell: (row) => (
      <>
        <span>{row.RFQItem}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Mat/Ser Code/Desc",
    cell: (row) => (
      <>
        <span>{row.MatSerCodeDesc}</span>
      </>
    ),
    width: "200px",
  },
  {
    name: "Header Mat/Ser Code/Desc",
    cell: (row) => (
      <>
        <span>{row.HeaderMatSerCodeDesc}</span>
      </>
    ),
    width: "250px",
  },
  {
    name: "Item Text",
    cell: (row) => (
      <>
        <span>{row.ItemText}</span>
      </>
    ),
    width: "200px",
  },
  {
    name: "Material PO Text",
    cell: (row) => (
      <>
        <span>{row.MaterialPOText}</span>
      </>
    ),
    width: "200px",
  },
  {
    name: "RFQ Quantity",
    cell: (row) => (
      <>
        <span>{row.RFQQuantity}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Price Unit Qty",
    cell: (row) => (
      <>
        <span>{row.PriceUnitQty}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Currency",
    cell: (row) => (
      <>
        <span>{row.Currency}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "RFQ Value",
    cell: (row) => (
      <>
        <span>{row.RFQValue}</span>
      </>
    ),
    width: "150px",
  },

  ]
  
 const Materialinfodata =[
  {
    Profile: "Profile",
    RFQItem: "RFQ Item",
    MatSerCodeDesc: "Mat/Ser Code/Desc",
    HeaderMatSerCodeDesc: "Header Mat/Ser Code/Desc",
    ItemText: "Item Text",
    MaterialPOText: "Material PO Text",
    RFQQuantity: "RFQ Quantity",
    PriceUnitQty: "Price Unit Qty",
    Currency: "Currency",
    RFQValue: "RFQ Value",
  }
   ]
  /* handel dynamic form new */
  const [elements, setElements] = useState([
    { type: "input", label: "Input 1" },
    { type: "textarea", label: "Textarea 1" },
    { type: "select", label: "Select 1" },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  console.log("editdata", editData);
  const handleAddElement = () => {
    setEditData(null);
    setShowModal(true);
  };
  const handleEditButtonClick = (index) => {
    setEditData({ ...elements[index], index });
    setShowModal(true);
  };
  const handleSaveModal = (data) => {
    if (editData && editData.index !== undefined) {
      const updatedElements = elements.map((element, i) =>
        i === editData.index ? data : element
      );
      setElements(updatedElements);
    } else {
      setElements([...elements, data]);
    }
  };
  const renderElement = (element, index) => {
    return (
      <div key={index}>
        {element.type === "input" && (
          <Col
            xs={12}
            md={4}
            lg={selectedDynamicForm.name === "Vertical" ? 12 : 3}
            className="commTopButtonRightLeftPadding "
          >
            <InputField
              className="inputBox"
              label="1.4Completion/Delivery Period from the date of OA/PO (Days)"
              name="UnitPrice"
              // placeholder=""
              // value={PriceSheduleModuledata.UnitPrice || ""}

              onChange={onInputChange}
              disabled={false}
            />
          </Col>
        )}
        {element.type === "textarea" && (
          /*   <textarea
            id={`${element.type}-${index}`}
            placeholder={element.label}
          /> */
          <Col
            xs={12}
            md={4}
            lg={selectedDynamicForm.name === "Vertical" ? 12 : 3}
            className="commTopButtonRightLeftPadding "
          >
            <TextAreaField
              className="inputBox"
              label="Notes "
              name=""
              placeholder=""
              // value={""}
              onChange={onInputChange}
              disabled={false}
            />
          </Col>
        )}
        {element.type === "select" && (
          <Col
            xs={12}
            md={4}
            lg={selectedDynamicForm.name === "Vertical" ? 12 : 3}
            className="commTopButtonRightLeftPadding "
          >
            <ComboboxField
              label="1.3 Bid/Offer Validity in Days."
              placeholder=""
              data={dropdownOption}
              id="INCOTermslocation"
              iconClassName="dropdownIcon"
              name="INCOTermslocation"
              setValue={formInputs.INCOTermslocation || ""}
              getvalue={setAccessData}
              // className="dropdownOption"
            />
          </Col>
        )}
        <button variant="link" onClick={() => handleEditButtonClick(index)}>
          Edit
        </button>
      </div>
    );
  };
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Technical Evaluation</h2>
            <p className="subText">Technical Evalution</p>
            <hr />

           
            {/*RFQQ Header */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">RFQ Header</span>
                      <span className="subHeaderTitle">Header Description</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Plant"
                          name="firstName"
                          placeholder=""
                          // value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="firstName"
                          placeholder=""
                          // value={"ESA7954"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bid Type"
                          name="firstName"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="firstName"
                          placeholder=""
                          // value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Address "
                          name="firstName"
                          placeholder=""
                          // value={" v1"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Version"
                          name="firstName"
                          placeholder=""
                          // value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency "
                          name="firstName"
                          placeholder=""
                          // value={"Emirates Steel Industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Creator / Initiator"
                          name="firstName"
                          placeholder=""
                          // value={" Technical and Commercial"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Billing Address"
                          name="firstName"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title"
                          name="firstName"
                          placeholder=""
                          // value={"Matatha Babu"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Product Type"
                          name="firstName"
                          placeholder=""
                          // value={"Service"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="firstName"
                          placeholder=""
                          // value={" VEND3019"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Address"
                          name="firstName"
                          placeholder=""
                          // value={"saranaya.m@eimsolutions.com / 123-09879"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Type"
                          name="firstName"
                          placeholder=""
                          // value={"2"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number Of Evaluation"
                          name="firstName"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="TE Assigned"
                          name="firstName"
                          placeholder=""
                          // value={"Closed"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding d-flex justify-content-center align-items-center"
                      >
                        {/* <InputField
                          className="inputBox"
                          label="RFQ Currency"
                          name="firstName"
                          placeholder=""
                          value={"AED"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        /> */}
                        <button className="btnTable w-25 h-50">Under TE</button>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Created On"
                          name="firstName"
                          placeholder=""
                          // value={"- -"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Type Of Purchase"
                          name="firstName"
                          placeholder=""
                          // value={"- -"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="HSE Assigned "
                          name="firstName"
                          placeholder=""
                          // value={"- -"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Technical Evaluation Header */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Technical Evaluation Header</span>
                      <span className="subHeaderTitle">Technical Evaluation Header</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Technical Approval Type"
                          name="firstName"
                          placeholder=""
                          // value={"Testing SV Template"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Tech Evaluation Prepared On"
                          name="firstName"
                          type="date"
                          placeholder=""
                          // value={"2024-05-14"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Upload Tech Eval Report "
                          name="firstName"
                          placeholder=""
                          type="file"
                          // value=""
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Tech Focal point"
                          name="firstName"
                          placeholder=""
                          // value={"Mail"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                         <TextAreaField
                          className="inputBox"
                          label="Evaluator Comments"
                          name=""
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                    
                        <InputField
                          className="inputBox"
                          label="Tech Target Date"
                          name="firstName"
                          placeholder=""
                          type="date"
                          // value={"Mail"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                         <TextAreaField
                          className="inputBox"
                          label="HSE Comments"
                          name=""
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      </Row>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Suppliers*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">
                      Suppliers
                      </span>
                      <span className="subHeaderTitle">
                      Suppliers Section
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={SuppliersColumns}
                          data={Suppliersdata}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Technical Evaluation Questionnaire */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TQ</span>
                    </Col>

                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle">Technical Evaluation Questionnaire</span>
                      <span className="subHeaderTitle">
                      Technical Evaluation Questionnaire
                      </span>
                    </Col>
                   
                    <Col xs={1} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                   <Row>
                    {/* pending creating table */}
                   </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Material Info*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">MI</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">Material Info</span>
                      <span className="subHeaderTitle">Line Items Information</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                >
                   <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={MaterialinfoColumns}
                          data={Materialinfodata}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                </div>
              </div>
            </div>
           
          </div>
        </Col>
      </Row>
      <CreateOtherChanges
        show={otherchangesmodelshow}
        onHide={hideOtherChangesModel}
        onData={handleDataFromCreateOtherChanges}
      />
      <PriceShedule
        show={PriceShedulemodalShow}
        onHide={hidePriceSheduleModal}
        submitPriceShedule={handelDataPriceShedule}
      />
      <DynamicForm
        show={DynamicFormModelshow}
        onHide={hideDynamicFormModel}
        //  dropdownoption={dropdownoption.name}
        onSave={handleSaveModal}
        editData={editData}
      />
    </main>
  );
};

export default TechnicalEvaluation;
