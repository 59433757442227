/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../Layout/Layout";
import { useNavigate } from "react-router-dom";

//Componentd
import Loader from "../../Components/Loader";

//Redux
import { useSelector } from "react-redux"; //useDispatch

const Home = () => {
  const navigate = useNavigate();
  localStorage.setItem("menu", "Quick link");
  localStorage.setItem("submenu", "-");

  let menuData = useSelector((state) => state?.menu); // User Details from Store

  const handleClick = (hrefLink) => {
    navigate(hrefLink);
  };

  return (
    <main className="dashboard main homePage" id="main">
      <Layout />
      <Row className="homeScreen justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">Home</h2>
            <p className="subText">Complete Details of the Supply Chain Management</p>
            <hr />

            <Row>
              <Loader isLoading={!menuData?.status} className={"text-center"} />
              {menuData?.data && menuData?.data.length > 0
                ? menuData?.data.map((value, index) => {
                    if (value.children.length > 0) {
                      return value.children.map((value1, index1) => {
                        return (
                          <Col
                            xs={12}
                            md={4}
                            className="commLeftRightPadding"
                            key={index1}
                          >
                            <div
                              className="card info-card sales-card"
                              onClick={() => handleClick(value1.hrefLink)}
                            >
                              <div className="card-body">
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className={value1.icon}></i>
                                  </div>
                                  <div className="ps-3">
                                    <h6>{value1.name}</h6>
                                    <span className="text-muted small pt-2" style={{"fontWeight":"600"}}>
                                      {value1.description}
                                    </span>
                                  </div>
                                </div>
                              </div>
                          </div>
                          </Col>
                        );
                      });
                    } else {
                      if (value.name !== "Home") {
                        return (
                          <Col
                            xs={12}
                            md={4}
                            className="commLeftRightPadding"
                            key={index}
                          >
                            <div
                              className="card info-card sales-card"
                              onClick={() => handleClick(value.hrefLink)}
                            >
                              <div className="card-body">
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className={value.icon}></i>
                                  </div>
                                  <div className="ps-3">
                                    <h6>{value.name}</h6>
                                    <span className="text-muted small pt-2" style={{"fontWeight":"600"}}>
                                      {value.description}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      } else {
                        return "";
                      }
                    }
                  })
                : null}
            </Row>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default Home;
