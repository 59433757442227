/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../Components/formElements/TextAreaField";
import RiverseTimer from "../../../Components/RiverseTimer";
import Constants from "../../../common/Constants";
import CreateOtherChanges from "../../../Components/Modal/supplier_app/CreateQuotation/CreateOtherChanges";
import PriceShedule from "../../../Components/Modal/supplier_app/CreateQuotation/PriceShedule";
import CreatePOLineItem from "../../../Components/Modal/supplier_app/PO_Collabration/CreatePOLineItem";
import HeaderText from "../../../Components/Modal/supplier_app/PO_Collabration/HeaderText";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";

const CreatePO = () => {
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Create Purchase Order");

  // let supplierData = useSelector(
  //   (state) => state?.supplier.data
  // ); /* suplierlist  data from suplier */

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  // const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);

  const [getAccessData, setAccessData] = useState("");
  const [getDisabledStatus, setDisabledStatus] = useState(false);

  const [loading, setLoading] = useState(false);
  const [PoCreatelineItemModel, setPoCreatelineItemModel] = useState(false);
  const [HeaderTextModel, setHeaderTextModel] = useState(false);
  const [PriceSheduleModuledata, setPriceSheduleModuledata] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  // console.log("invoiceData>>", invoiceData);
  console.log("PriceSheduleModuledata>>", PriceSheduleModuledata);

  // console.log("PriceSheduleModuledata>>", PriceSheduleModuledata);
  /* data from createotherchanges */
  const [CreateOtherchangedata, setCreateOtherchangedata] = useState([]);
  // console.log('CreateOtherchangedata:',CreateOtherchangedata)
  const handleDataFromCreateOtherChanges = (data) => {
    // console.log("Data received from child:", data);
    setCreateOtherchangedata((prevData) => [...prevData, data]);
  };
  const handleDelete = (indexToDelete) => {
    setCreateOtherchangedata((prevData) =>
      prevData.filter((_, index) => index !== indexToDelete)
    );
  };

  const handelDataPriceShedule = (data) => {
    // console.log('POLineItemsData>>',data)
    setPriceSheduleModuledata((prevData) => [...prevData, data]);
    setCreateOtherchangedata((prevData) => [...prevData, data]);
  };

  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  /* Create po Modal Function */
  const showCreatepomodal = () => {
    setPoCreatelineItemModel(true);
  };
  const hidePoCreatelineItemModel = () => {
    setPoCreatelineItemModel(false);
  };
  /* Create po Modal Function */
  const showsetHeaderText = () => {
    setHeaderTextModel(true);
  };
  const hideHeaderTextModel = () => {
    setHeaderTextModel(false);
  };

  /* evalutions summary*/
  const EvaluationSummaryDatas = [
    {
      Profile: "Profile",
      Supplier: "Supplier",
      QTNNumber: "QTN Number",
      Version: "Version",
      ICVPercent: "ICV%",
      ICVWTValue: "ICV WT. Value",
      TechScore: "Tech Score",
      TechWTValue: "Tech WT. Value",
      BIDCUR: "BID CUR",
      InitialQTNValBIDCUR: "Initial QTN Val(BID CUR)",
      FinalQTNValBIDCUR: "Final QTN Val(BID CUR)",
      ExchangeRate: "Exchange Rate",
      RFQCURR: "RFQ CURR",
      FinalQtnValueRFQCUR: "Final Qtn Value(RFQ CUR)",
      Incoterm: "Incoterm",
      ShipmentCostToCompany: "Shipment Cost To Company",
      OtherCost: "Other Cost(+ Or -)",
      TotLandingCost: "Tot Landing Cost",
      PriceWTValue: "Price WT. Value",
      OtherWTValue: "Other WT. Value",
      OverallWTValue: "Overall WT. Value",
      BidderRanking: "Bidder Ranking",
      Comments: "Comments",
    },
  ];

  const EvaluationSummaryColumns = [

    {
      name: "Profile",
      cell: (row) => (
        <>
          <span>{row.Profile}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Supplier",
      cell: (row) => (
        <>
          <span>{row.Supplier}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "QTN Number",
      cell: (row) => (
        <>
          <span>{row.QTNNumber}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Version",
      cell: (row) => (
        <>
          <span>{row.Version}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "ICV%",
      cell: (row) => (
        <>
          <span>{row.ICVPercent}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "ICV WT. Value",
      cell: (row) => (
        <>
          <span>{row.ICVWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Tech Score",
      cell: (row) => (
        <>
          <span>{row.TechScore}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Tech WT. Value",
      cell: (row) => (
        <>
          <span>{row.TechWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "BID CUR",
      cell: (row) => (
        <>
          <span>{row.BIDCUR}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Initial QTN Val(BID CUR)",
      cell: (row) => (
        <>
          <span>{row.InitialQTNValBIDCUR}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Final QTN Val(BID CUR)",
      cell: (row) => (
        <>
          <span>{row.FinalQTNValBIDCUR}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Exchange Rate",
      cell: (row) => (
        <>
          <span>{row.ExchangeRate}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "RFQ CURR",
      cell: (row) => (
        <>
          <span>{row.RFQCURR}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Final Qtn Value(RFQ CUR)",
      cell: (row) => (
        <>
          <span>{row.FinalQtnValueRFQCUR}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Incoterm",
      cell: (row) => (
        <>
          <span>{row.Incoterm}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Shipment Cost To Company",
      cell: (row) => (
        <>
          <span>{row.ShipmentCostToCompany}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Other Cost(+ Or -)",
      cell: (row) => (
        <>
          <span>{row.OtherCost}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Tot Landing Cost",
      cell: (row) => (
        <>
          <span>{row.TotLandingCost}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Price WT. Value",
      cell: (row) => (
        <>
          <span>{row.PriceWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Other WT. Value",
      cell: (row) => (
        <>
          <span>{row.OtherWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Overall WT. Value",
      cell: (row) => (
        <>
          <span>{row.OverallWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Bidder Ranking",
      cell: (row) => (
        <>
          <span>{row.BidderRanking}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Comments",
      cell: (row) => (
        <>
          <span>{row.Comments}</span>
        </>
      ),
      width: "200px",
    },
  ];
  /* createpo */
  const cratepoDatas =[
    {
      Supplier: "Supplier",
      SAPAssociateNo: "SAP Associate No",
      QTNNumber: "QTN Number",
      Version: "Version",
      Status: "Status",
      SAPPONumber: "SAP PO Number",
      SAPErrorMsg: "SAP Error Msg",
      SAPRemarks: "SAP Remarks",
      CreatePO: "Create PO",
      UpdatePO: "Update PO"
    }
  ]
  const cratepoColumns=[
    {
      name: "Supplier",
      cell: (row) => (
        <>
          <span>{row.Supplier}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "SAP Associate No",
      cell: (row) => (
        <>
          <span>{row.SAPAssociateNo}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "QTN Number",
      cell: (row) => (
        <>
          <span>{row.QTNNumber}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Version",
      cell: (row) => (
        <>
          <span>{row.Version}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <span>{row.Status}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "SAP PO Number",
      cell: (row) => (
        <>
          <span>{row.SAPPONumber}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "SAP Error Msg",
      cell: (row) => (
        <>
          <span>{row.SAPErrorMsg}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "SAP Remarks",
      cell: (row) => (
        <>
          <span>{row.SAPRemarks}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Create PO",
      cell: (row) => (
        <>
          <span>{row.CreatePO}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Update PO",
      cell: (row) => (
        <>
          <span>{row.UpdatePO}</span>
        </>
      ),
      width: "100px",
    }
  ]
  /* Award Summary */
  const AwardSummaryData =[
    {
      Profile: "Profile",
      Supplier: "Supplier",
      QTNNumber: "QTN Number",
      Version: "Version",
      AwardItems: "Award Items",
      RFQCUR: "RFQ CUR",
      InitialValue: "Initial Value",
      HighestValue: "Highest Value",
      FinalValue: "Final Value",
      Saving: "Saving",
      FreightCharges: "Freight Charges",
      OtherCharges: "Other Charges",
      OtherDiscounts: "Other Discounts",
      FinalAwardValue: "Final Award Value",
      ExchangeRate: "Exchange Rate",
      BIDCURR: "BID CURR",
      FinalAwardValueBidCUR: "Final Award Value (Bid CUR)",
      PaymentTerms: "Payment Terms",
      Incoterm: "Incoterm",
      IncotermLocation: "Incoterm Location"
    }
  ]
const AwardSummaryColumns=[
  {
    name: "Profile",
    cell: (row) => (
      <>
        <span>{row.Profile}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Supplier",
    cell: (row) => (
      <>
        <span>{row.Supplier}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "QTN Number",
    cell: (row) => (
      <>
        <span>{row.QTNNumber}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Version",
    cell: (row) => (
      <>
        <span>{row.Version}</span>
      </>
    ),
    width: "100px",
  },
  {
    name: "Award Items",
    cell: (row) => (
      <>
        <span>{row.AwardItems}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "RFQ CUR",
    cell: (row) => (
      <>
        <span>{row.RFQCUR}</span>
      </>
    ),
    width: "100px",
  },
  {
    name: "Initial Value",
    cell: (row) => (
      <>
        <span>{row.InitialValue}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Highest Value",
    cell: (row) => (
      <>
        <span>{row.HighestValue}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Final Value",
    cell: (row) => (
      <>
        <span>{row.FinalValue}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Saving",
    cell: (row) => (
      <>
        <span>{row.Saving}</span>
      </>
    ),
    width: "100px",
  },
  {
    name: "Freight Charges",
    cell: (row) => (
      <>
        <span>{row.FreightCharges}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Other Charges",
    cell: (row) => (
      <>
        <span>{row.OtherCharges}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Other Discounts",
    cell: (row) => (
      <>
        <span>{row.OtherDiscounts}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Final Award Value",
    cell: (row) => (
      <>
        <span>{row.FinalAwardValue}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Exchange Rate",
    cell: (row) => (
      <>
        <span>{row.ExchangeRate}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "BID CURR",
    cell: (row) => (
      <>
        <span>{row.BIDCURR}</span>
      </>
    ),
    width: "100px",
  },
  {
    name: "Final Award Value (Bid CUR)",
    cell: (row) => (
      <>
        <span>{row.FinalAwardValueBidCUR}</span>
      </>
    ),
    width: "200px",
  },
  {
    name: "Payment Terms",
    cell: (row) => (
      <>
        <span>{row.PaymentTerms}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Incoterm",
    cell: (row) => (
      <>
        <span>{row.Incoterm}</span>
      </>
    ),
    width: "150px",
  },
  {
    name: "Incoterm Location",
    cell: (row) => (
      <>
        <span>{row.IncotermLocation}</span>
      </>
    ),
    width: "200px",
  }
]
  /* radio buttons */
  const [selectedShipment, setSelectedShipment] = useState("");
  const handleShipmentChange = (event) => {
    setSelectedShipment(event.target.value);
    // console.log("selectedShipment>>", selectedShipment);
  };



  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Purchase Order</h2>
            <p className="subText">Fill Purchase Oreder Details</p>
            <hr />
            {/*RFQ Header */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">RFQ Header</span>
                      <span className="subHeaderTitle">RFQ Header</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Plant"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Address"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Type"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Type of Purchase"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding d-flex justify-content-start align-items-center"
                      >
                        <button className="btnTable w-25">PO Created</button>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bid Type"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Commercial Evaluation Header */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CH</span>
                    </Col>

                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle">
                        Commercial Evaluation Header
                      </span>
                      <span className="subHeaderTitle">
                        Commercial Evaluation Header
                      </span>
                    </Col>
                    {/*  <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable" onClick={showsetHeaderText}>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </Col> */}
                    <Col xs={1} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      {/* <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={OtherChangesColumns}
                          data={CreateOtherchangedata}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        /> 

            
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding "
                      >
                        <InputField
                          className="inputBox"
                          label="Technical Approval Type"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Comm Evaluation Prepared On"
                          name="UnitPrice"
                          type="date"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Tech Focal point"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Comm Eligibility Score"
                          name="UnitPrice"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Tender Summary*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TS</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Tender Summary</span>
                      <span className="subHeaderTitle">Tender Summary</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* Tender Summary pending */}
                  </div>
                </div>
              </div>
            </div>
            {/* Evaluation Summary*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Evaluation Summary</span>
                      <span className="subHeaderTitle">Evaluation Summary</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={EvaluationSummaryColumns}
                          data={EvaluationSummaryDatas}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Evaluation Summary*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AS</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Award Summary</span>
                      <span className="subHeaderTitle">Award Summary</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={AwardSummaryColumns}
                          data={AwardSummaryData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Item Level Award Recommendation*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">IR</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Item Level Award Recommendation</span>
                      <span className="subHeaderTitle">Item Level Award Recommendation</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>

                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Create po*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CP</span>
                    </Col>
                    <Col xs={12} md={6} lg={7}>
                      <span className="headerTitle">Create PO</span>
                      <span className="subHeaderTitle">Create PO</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                      <DataTable
                          // title=""
                          columns={cratepoColumns}
                          data={cratepoDatas}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </Col>
      </Row>
      

      <CreatePOLineItem
        show={PoCreatelineItemModel}
        onHide={hidePoCreatelineItemModel}
        onData={handleDataFromCreateOtherChanges}
      />
      <HeaderText
        show={HeaderTextModel}
        onHide={hideHeaderTextModel}
        hederTextData={handelDataPriceShedule}
      />
    </main>
  );
};

export default CreatePO;
