import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useNavigate } from 'react-router-dom';

// Components
import { ComboboxField } from "../../../formElements/ComboboxField";
import Constants from "../../../../common/Constants";

//Serivce
// import ItemService from "../../../services/supplier_appCommonService";

//Redux
import { useDispatch } from "react-redux";
import { addPurchaseDocType } from "../../../../redux/feature/supplier_app/purchasingSlice";

const CreatePOMasterModal = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");
  const [getDropdownOption, setDropdownOption] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");

  // Handle Submit IviteTeamMembers
  const handleSubmit = async () => {
    if (
      getDropdownData?.name === "" 
    ) {
      setErrorMsg("* Fields are mandatory");
      return;
    } else {
      setErrorMsg("");
    }

    // const data = {
    //   supplierCategory: formInputs.supplierCategory
    // };
    // console.log(">> data ", data);

    dispatch(addPurchaseDocType(getDropdownData));
    navigate(Constants.POCOLLABRATION_PURCHASE_ORDER_CREATION);
    // const toastId = toast.loading("Loading...", {
    //   position: "top-right",
    // });

    // setDisabled(true);
    // await ItemService.inviteTeamMembers(data).then((item) => {
    //   // console.log('>> item!',item);
    //   if (item?.status === 200) {
    //     toast.success(item?.message || "User Invited successfully", {
    //       duration: 2000,
    //       position: "top-right",
    //     });
    //     setErrorMsg("");

    //     // Update response value in Redux Store
    //     dispatch(getTeamData(compIdObj));
    //   } else {
    //     toast.error(item?.message || "Please try again !!", {
    //       duration: 4000,
    //       position: "top-right",
    //     });
    //     setErrorMsg("*" + item?.message);
    //   }
    //   setDisabled(false);
    // });
    // toast.dismiss(toastId);
  };

  useEffect(() => {
    // console.log(">> props?.dropdownData", props?.dropdownData);
    setDropdownOption(props?.dropdowndata);

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
    
  }, [props]);

  // const onInputChange = ({ target: { name, value } }) => {
  //   setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  // };

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> Create PO
          {/* <p className="subText">
            At supplier_app, every person has a role, each with its own level of
            access.
          </p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <ComboboxField
                label="PO Document Type"
                placeholder="please select the PO Document Type"
                data={getDropdownOption}
                id="poDocumentType"
                iconClassName="dropdownIcon"
                name="poDocumentType"
                setValue={getDropdownData?.name || []}
                getvalue={setDropdownData}
                className="dropdownOption"
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={()=> props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CreatePOMasterModal;
