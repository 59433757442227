import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

// import ConstantsList from '../../../common/Constants';

//Components
import Layout from "../../../../Layout/Layout";
import CreateSupplierMasterModal from "../../../../Components/Modal/supplier_app/CreateSupplierMasterModal";
import Constants from "../../../../common/Constants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { useNavigate } from "react-router-dom";
import {
  addASNDetailNumber,
  addPoDetailNumber,
  getASN,
} from "../../../../redux/feature/supplier_app/purchasingSlice";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import { capitalize, currencyFormat } from "../../../../common/Validations";
import { SearchField } from "../../../../Components/formElements/SearchField";
import CancelModal from "../../../../Components/Modal/supplier_app/common/CancelModal";

const ASNList = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "Advanced Shipping Notice");

  // let asn_data = useSelector((state) => state?.purchasing?.asn?.data); // User Details from Store

  let asn_data = useSelector((state) => state?.purchasing?.asn); // get asn_data from Store
  let module = useSelector(
    (state) => state?.common?.data?.purchaseOrder?.ASN?.module
  ); // config Details from Store

  let asn_dataTable = useSelector(
    (state) => state?.purchasing?.asn?.pagination
  ); // asn_dataTable Details from Store

  const [data, setData] = useState([]);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  // const [loading, setLoading] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  const detailedPage = (ansNumber) => {
    // console.log("ansNumber", ansNumber);
    dispatch(addASNDetailNumber(ansNumber));
    navigate(Constants.POCOLLABRATION_ASN_DETAIL_PAGE);
  };

  const detailedPagePO = (poNumber) => {
    dispatch(addPoDetailNumber(poNumber));
    navigate(Constants.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE);
  };

  const loadInitalPageData = async () => {
    setData(asn_data.data);
  };

  useEffect(() => {
    loadInitalPageData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asn_data]);

  const handlePageChange = (page) => {
    let obj = {
      page: page,
      per_page: getNewPerPage,
      searchTerm: getCommonSearchTxt,
    };

    dispatch(getASN(obj));
    setData(asn_data?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getASN(obj));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      page: page,
      per_page: newPerPage,
      searchTerm: getCommonSearchTxt,
    };
    setNewPerPage(newPerPage);
    dispatch(getASN(obj));
    setData(asn_data?.data);
  };

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "ASN Number",
      selector: (row) => row.asnNumber,
      cell: (row) => (
        <button className="btnInfo" onClick={() => detailedPage(row.asnNumber)}>
          {row.asnNumber}{" "}
        </button>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.asnStatusHeader,
      cell: (row) => (
        <>
          {row.asnStatusHeader === "In Transit" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.asnStatusHeader}
            </span>
          ) : row.asnStatusHeader === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.asnStatusHeader}
            </span>
          ) : row.asnStatusHeader === "Gatepass Created" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.asnStatusHeader}
            </span>
          ) : (
            row.asnStatusHeader
          )}
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "ASN Date",
      selector: (row) => row.asnDate || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Invoice Number",
      selector: (row) => row.vendorInvoiceNo,
      sortable: true,
      width: "180px",
    },
    {
      name: "Invoice Date",
      selector: (row) => row.vendorInvoiceDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Invoice Value",
      selector: (row) => row.vendorTotalInvoiceValue || "--",
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.vendorTotalInvoiceValue).toFixed(2))}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Currency",
      selector: (row) => row.vendorInvoiceCurrency,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorInvoiceCurrency} </p>
          <p className="m-0 p-0">{row.currency} </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    // {
    //   name: "PO/SA Number",
    //   selector: (row) => row?.purchasingDocumentNr || "--",
    //   sortable: true,
    //   width: "150px",
    // },

    {
      name: "PO/SA Number",
      selector: (row) => row.purchasingDocumentNr,
      cell: (row) => (
        <>
          {row.purchasingDocumentNr && (
            <button
              className="btnInfo"
              onClick={() => detailedPagePO(row.purchasingDocumentNr)}
            >
              {row.purchasingDocumentNr}{" "}
            </button>
          )}
          {!row.purchasingDocumentNr && (
            <>
              {" "}
              <p>--</p>{" "}
            </>
          )}
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "PO/SA Date",
      selector: (row) => row?.purchasingDocumentDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SAP IBD ",
      selector: (row) => row.sapInboundDelivery || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "GRN Number ",
      selector: (row) => row.grnNumber || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Posted On",
      selector: (row) => row.postedOn || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Shipping Type",
      cell: (row) => (
        <>
          <span> {capitalize(row.shippingType)} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Gatepass Number",
      selector: (row) => row?.gatepassNr || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Gatepass Date",
      selector: (row) => row?.gatepassCreatedDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Gatepass Time",
      selector: (row) => row?.gatepassCreatedTime || "--",
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Delivery Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.deliveredStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Invoice Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.invoicedStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Payment Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.paymentStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
  ];

  const lineItemColInfo = [
    {
      name: "Item Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material/Service",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialGroup} </span>,
      width: "220px",
    },
    {
      name: "Purchase Qty",
      cell: (row) => <span>{row.purchaseQuantity} </span>,
      width: "220px",
    },
    {
      name: "ASN Qty",
      cell: (row) => <span>{row.asnQuantity} </span>,
      width: "220px",
    },
    {
      name: "Balance Qty",
      cell: (row) => <span>{row.balancePurchaseQuantity} </span>,
      width: "220px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "220px",
    },

    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "220px",
    },
    {
      name: "PO Nr",
      cell: (row) => <span>{row.purchasingDocumentNr} </span>,
      width: "220px",
    },
    {
      name: "PO Item Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "220px",
    },
    {
      name: "Schedule Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "220px",
    },
  ];
  /* Cancel Modal */
  const [getCancelModalStatus, setCancelModalStatus] = useState(false);
  const cancelModalShow = () => {
    setCancelModalStatus(true);
  };
  const cancelModalHide = () => {
    setCancelModalStatus(false);
  };

  /* Handle selected rows */
  const [getSelectedRows, setSelectedRows] = useState([]);
  const handleRowSelected = ({ selectedRows }) => {
    const validRows = selectedRows.filter(
      (item) => item?.asnStatusHeader !== "Cancelled"
    );
    setSelectedRows(validRows);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Advanced Shipping Notice</h2>
                <p className="subText">Advanced Shipping Notice</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SL</span>
                    </Col>
                    <Col xs={5} md={8} lg={8}>
                      <span className="headerTitle">ASN List</span>
                      <span className="subHeaderTitle">View of ASN List </span>
                    </Col>

                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-plus-lg"></i> Change Buyer
                      </button>
                    </Col> */}

                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button className="btnTable" onClick={() => createRFQ()}>
                        <i className="bi bi-plus-lg"></i> Request ACK
                      </button>
                    </Col> */}
                    <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                      <Col xs={6} md={6} lg={6}>
                        <button
                          className="btnTable"
                          onClick={cancelModalShow}
                          disabled={getSelectedRows.length > 0 ? false : true}
                        >
                          <i className="bi bi-x-circle"></i> Cancel
                        </button>
                      </Col>
                      <Col xs={6} md={1} lg={1}>
                        <POCollabrationCommonFillter />
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={asn_data?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={asn_dataTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CreateSupplierMasterModal show={modalShow} onHide={() => hideModal()} />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
      <CancelModal
        title="Cancel the ASN"
        type={module}
        show={getCancelModalStatus}
        onHide={cancelModalHide}
        selectedrow={getSelectedRows}
      />
    </main>
  );
};

export default ASNList;
