import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./common.scss";
import "./dashboard.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-widgets/scss/styles.scss";
import RouterNav from "./Router";
import { Providers } from "./redux/provider";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <I18nextProvider i18n={i18n}>
        <Providers>
          <BrowserRouter>
            <RouterNav />
          </BrowserRouter>
        </Providers>
      </I18nextProvider>
    </Suspense>
  );
}
export default App;
