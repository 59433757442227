/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../Components/formElements/TextAreaField";
import RiverseTimer from "../../../Components/RiverseTimer";
import Constants from "../../../common/Constants";
import CreateOtherChanges from "../../../Components/Modal/supplier_app/CreateQuotation/CreateOtherChanges";
import PriceShedule from "../../../Components/Modal/supplier_app/CreateQuotation/PriceShedule";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";

const CommercialEvaluation = () => {
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Commercial Evaluation");

  // let supplierData = useSelector(
  //   (state) => state?.supplier.data
  // ); /* suplierlist  data from suplier */

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  // const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);

  const [getAccessData, setAccessData] = useState("");
  const [getDisabledStatus, setDisabledStatus] = useState(true);

  const [loading, setLoading] = useState(false);
  const [otherchangesmodelshow, setotherchangesmodelshow] = useState(false);
  const [PriceShedulemodalShow, setPriceShedulemodalShow] = useState(false);
  const [PriceSheduleModuledata, setPriceSheduleModuledata] = useState([]);
  console.log("PriceSheduleModuledata>>", PriceSheduleModuledata);
  /* data from createotherchanges */
  const [CreateOtherchangedata, setCreateOtherchangedata] = useState([]);
  // console.log('CreateOtherchangedata:',CreateOtherchangedata)
  const handleDataFromCreateOtherChanges = (data) => {
    // console.log("Data received from child:", data);
    setCreateOtherchangedata((prevData) => [...prevData, data]);
  };
  const handleDelete = (indexToDelete) => {
    setCreateOtherchangedata((prevData) =>
      prevData.filter((_, index) => index !== indexToDelete)
    );
  };

  /*  */
  const handelDataPriceShedule = (data) => {
    // console.log('priceSheduleData>>',data)
    setPriceSheduleModuledata(data);
  };

  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  /* other changes popup  Modal Function */
  const createotherchangesmodel = () => {
    setotherchangesmodelshow(true);
  };

  const hideOtherChangesModel = () => {
    setotherchangesmodelshow(false);
  };
  /* Price Shedule handling function */
  const CreatePriceSheduleModel = () => {
    setPriceShedulemodalShow(true);
  };
  const hidePriceSheduleModal = () => {
    setPriceShedulemodalShow(false);
  };

  /* suppliers data table */
  const suppliersData = [
    {
      Profile: "Profile",
      Supplier: "Supplier",
      QTNNumber: "QTN Number",
      Version: "Version",
      QTNDetails: "QTN Details",
      Tech: "Tech",
      UnPriced: "UnPriced",
      Comm: "Comm",
      BIDCUR: "BID CUR",
      SAPSupplierNumber: "SAPSupplierNumber",
      QTNValue: "QTN Value",
      CommScore: "Comm Score*",
      Remark: "Remark",
      Status: "Status",
    },
  ];
  const supplersColumns = [
    {
      name: "",
      cell: (row) => (
        <>
          <i className="bi bi-pencil-square"></i>
        </>
      ),
      width: "50px",
    },
    {
      name: "Profile",
      cell: (row) => (
        <>
          <img
            src="assets/img/profile-img.png"
            alt="Profile"
            className="rounded-circle profileImageCss"
            style={{ width: "40px" }}
          />
        </>
      ),
      width: "100px",
    },
    {
      name: "Supplier",
      cell: (row) => (
        <>
          <span>{row.Supplier}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "QTN Number",
      cell: (row) => (
        <>
          <button className="btnInfo">ESQ6812 </button>
        </>
      ),
      width: "150px",
    },
    {
      name: "Version",
      cell: (row) => (
        <>
          <span>{row.Version}</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "QTN Details",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-arrow-up-circle"></i>
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Tech",
      cell: (row) => (
        <>
          <span>
            {/* <i class="bi bi-upload"></i> */}
            <i className="bi bi-paperclip"></i>
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "UnPriced",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-paperclip"></i>
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Comm",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-paperclip"></i>
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "BID CUR",
      cell: (row) => (
        <>
          <span>AED</span>
        </>
      ),
      width: "100px",
    },
    {
      name: "SAPSupplierNumber",
      cell: (row) => (
        <>
          <span>{row.SAPSupplierNumber}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "QTN Value",
      cell: (row) => (
        <>
          <span>{row.QTNValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Comm Score*",
      cell: (row) => (
        <>
          <InputField
            className="inputBox"
            /* label="Closing Date:" */
            name=""
            // placeholder=""
            // value={PriceSheduleModuledata.UnitPrice || ""}

            onChange={onInputChange}
            disabled={false}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Remark",
      cell: (row) => (
        <>
          <InputField
            className="inputBox"
            /* label="Closing Date:" */
            name="UnitPrice"
            // placeholder=""
            // value={PriceSheduleModuledata.UnitPrice || ""}

            onChange={onInputChange}
            disabled={false}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <button className="btn btn-success text-white">Awarded </button>
        </>
      ),
      width: "200px",
    },
  ];

  /* Evaluation Criteria data table*/
  const evaluationCriteriaData = [
    {
      SLNo: 1,
      Criteria: "Technical Rating",
      Weightage: "",
    },
    {
      SLNo: 1,
      Criteria: "Technical Rating",
      Weightage: "",
    },
    {
      SLNo: 1,
      Criteria: "Technical Rating",
      Weightage: "",
    },
    {
      SLNo: 1,
      Criteria: "Technical Rating",
      Weightage: "",
    },
  ];
  const evaluationCriteriaColumns = [
    {
      name: "",
      cell: (row) => (
        <>
          <i className="bi bi-pencil-square"></i>
        </>
      ),
      width: "50px",
    },
    {
      name: "SL No",
      cell: (row) => (
        <>
          <span>{row.SLNo}</span>
        </>
      ),
      width: "300px",
    },
    {
      name: "Criteria",
      cell: (row) => (
        <>
          <span>{row.Criteria}</span>
        </>
      ),
      width: "300px",
    },
    {
      name: "Weightage",
      cell: (row) => (
        <>
          <InputField
            className="inputBox"
            /* label="Closing Date:" */
            name=""
            // placeholder=""
            // value={PriceSheduleModuledata.UnitPrice || ""}

            onChange={onInputChange}
            disabled={false}
          />
        </>
      ),
      width: "200px",
    },
  ];
  /* Exchange Rate table */
  const ExchangeRateData = [
    {
      BidderCurrency: "Bidder Currency",
      RFQCurrency: "RFQ Currency",
      Value: "Value",
      ExchangeRate: "Exchange Rate",
      ModifyExchangeRateAED: "Modify Exchange Rate (AED)",
    },
  ];
  const ExchangeRateColumns = [
    {
      name: "Bidder Currency",
      cell: (row) => (
        <>
          <span>{row.BidderCurrency}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "RFQ Currency",
      cell: (row) => (
        <>
          <span>{row.RFQCurrency}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Value",
      cell: (row) => (
        <>
          <span>{row.Value}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Exchange Rate",
      cell: (row) => (
        <>
          <span>{row.ExchangeRate}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Modify Exchange Rate (AED)",
      cell: (row) => (
        <>
          <button className="btn"> Edit</button>
        </>
      ),
      width: "150px",
    },
  ];

  /* Evalution Summary table*/
  const EvaluationSummaryData = [
    {
      Profile: "Profile",
      Supplier: "Supplier",
      QTNVersion: "QTN Version",
      ICVEvalCriteria: "ICV Eval Criteria",
      ICVPercentage: "ICV%",
      ICVWTValue: "ICV WT. Value",
      TechEvalCriteria: "Tech Eval Criteria",
      TechScore: "Tech Score",
      TechWTValue: "Tech WT. Value",
      BIDCUR: "BID CUR",
      InitialQtnValBIDCUR: "Initial Qtn Val(BID CUR)",
      FinalQtnValBIDCUR: "Final Qtn Val(BID CUR)",
      EvaluationSummary: "Exchange Rate",
      RFQCUR: "RFQ CUR",
      FinalQtnValueRFQCUR: "Final Qtn Value(RFQ CUR)",
      Incoterm: "Incoterm",
      IncotermLoc: "Incoterm Loc",
      ShipmentCostToCompany: "Shipment Cost To Company",
      OtherCost: "Other Cost(+ Or -)",
      TotLandingCost: "Tot Landing Cost",
      PriceEvalCriteria: "Price Eval Criteria",
      PriceWTValue: "Price WT. Value",
      WeightedScore: "Weighted Score",
      OtherEvalCriteria: "Other Eval Criteria",
    },
  ];
  const EvaluationSummaryColumns = [
    {
      name: "",
      cell: (row) => (
        <>
          <i className="bi bi-pencil-square"></i>
        </>
      ),
      width: "50px",
    },
    {
      name: "Profile",
      cell: (row) => (
        <>
        <img
            src="assets/img/profile-img.png"
            alt="Profile"
            className="rounded-circle profileImageCss"
            style={{ width: "40px" }}
          />
        </>
      ),
      width: "150px",
    },
    {
      name: "Supplier",
      cell: (row) => (
        <>
          <span>{row.Supplier}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "QTN Version",
      cell: (row) => (
        <>
        <button class="btnInfo">ESQ6812 v0 </button>
        </>
      ),
      width: "150px",
    },
    {
      name: "ICV Eval Criteria",
      cell: (row) => (
        <>
          <span>{row.ICVEvalCriteria}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "ICV%",
      cell: (row) => (
        <>
          <span>{row.ICVPercentage}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "ICV WT. Value",
      cell: (row) => (
        <>
          <span>{row.ICVWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Tech Eval Criteria",
      cell: (row) => (
        <>
          <span>{row.TechEvalCriteria}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Tech Score",
      cell: (row) => (
        <>
          <span>{row.TechScore}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Tech WT. Value",
      cell: (row) => (
        <>
          <span>{row.TechWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "BID CUR",
      cell: (row) => (
        <>
          <span>{row.BIDCUR}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Initial Qtn Val(BID CUR)",
      cell: (row) => (
        <>
          <span>{row.InitialQtnValBIDCUR}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Final Qtn Val(BID CUR)",
      cell: (row) => (
        <>
          <span>{row.FinalQtnValBIDCUR}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Exchange Rate",
      cell: (row) => (
        <>
          <span>{row.EvaluationSummary}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "RFQ CUR",
      cell: (row) => (
        <>
          <span>{row.RFQCUR}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Final Qtn Value(RFQ CUR)",
      cell: (row) => (
        <>
          <span>{row.FinalQtnValueRFQCUR}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Incoterm",
      cell: (row) => (
        <>
          <span>{row.Incoterm}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Incoterm Loc",
      cell: (row) => (
        <>
          <span>{row.IncotermLoc}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Shipment Cost To Company",
      cell: (row) => (
        <>
          <span>{row.ShipmentCostToCompany}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Other Cost(+ Or -)",
      cell: (row) => (
        <>
          <span>{row.OtherCost}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Tot Landing Cost",
      cell: (row) => (
        <>
          <span>{row.TotLandingCost}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Price Eval Criteria",
      cell: (row) => (
        <>
          <span>{row.PriceEvalCriteria}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Price WT. Value",
      cell: (row) => (
        <>
          <span>{row.PriceWTValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Weighted Score",
      cell: (row) => (
        <>
          <span>{row.WeightedScore}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Other Eval Criteria",
      cell: (row) => (
        <>
          <span>{row.OtherEvalCriteria}</span>
        </>
      ),
      width: "150px",
    },
  ];
  /* Award Summary data table*/
  const AwardSummaryData = [
    {
      Profile: "Profile",
      Supplier: "Supplier",
      VendorRegStatus: "Vendor Reg. Status",
      QTNNumberVersion: "QTN Number-Version",
      AwardItems: "Award Items",
      RFQCUR: "RFQ CUR",
      InitialValue: "Initial Value",
      HighestValue: "Highest Value",
      FinalValue: "Final Value",
      Saving: "Saving",
      FreightCharges: "Freight Charges",
      OtherCharges: "Other Charges",
      OtherDiscounts: "Other Discounts",
      FinalAwardValue: "Final Award Value",
      Delete: "Delete",
      EvaluationSummary: "Exchange Rate",
      BIDCURR: "BID CURR",
      FinalAwardVal: "Final Award Val",
    },
  ];
  const AwardSummaryColumns = [
    {
      name: "Profile",
      cell: (row) => (
        <>
          <span>{row.Profile}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Supplier",
      cell: (row) => (
        <>
          <span>{row.Supplier}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Vendor Reg. Status",
      cell: (row) => (
        <>
          <span>{row.VendorRegStatus}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "QTN Number-Version",
      cell: (row) => (
        <>
          <span>{row.QTNNumberVersion}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Award Items",
      cell: (row) => (
        <>
          <span>{row.AwardItems}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "RFQ CUR",
      cell: (row) => (
        <>
          <span>{row.RFQCUR}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Initial Value",
      cell: (row) => (
        <>
          <span>{row.InitialValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Highest Value",
      cell: (row) => (
        <>
          <span>{row.HighestValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Final Value",
      cell: (row) => (
        <>
          <span>{row.FinalValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Saving",
      cell: (row) => (
        <>
          <span>{row.Saving}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Freight Charges",
      cell: (row) => (
        <>
          <span>{row.FreightCharges}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Other Charges",
      cell: (row) => (
        <>
          <span>{row.OtherCharges}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Other Discounts",
      cell: (row) => (
        <>
          <span>{row.OtherDiscounts}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Final Award Value",
      cell: (row) => (
        <>
          <span>{row.FinalAwardValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Delete",
      cell: (row) => (
        <>
          <span>{row.Delete}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Exchange Rate",
      cell: (row) => (
        <>
          <span>{row.EvaluationSummary}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "BID CURR",
      cell: (row) => (
        <>
          <span>{row.BIDCURR}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Final Award Val",
      cell: (row) => (
        <>
          <span>{row.FinalAwardVal}</span>
        </>
      ),
      width: "150px",
    },
  ];

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Commercial Evaluation</h2>
            <p className="subText">Commercial Evaluation Details</p>
            <hr />
            {/*RFQ Header  */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">RFQ Header </span>
                      <span className="subHeaderTitle">RFQ Header</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Plant"
                          name="Plant"
                          placeholder=""
                          value={"CP01"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="RFQ ID"
                          placeholder=""
                          value={"EDA8616"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Product Type"
                          name="firstName"
                          placeholder=""
                          value={"Material"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Follow on"
                          name="Product Type "
                          placeholder=""
                          value={"Contract"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Address"
                          name="firstName"
                          placeholder=""
                          value={"Emirates Steel industries-AbuDhabi"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title"
                          name="Follow on"
                          placeholder=""
                          value={"Test RFQ without PR"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number of Evaluation"
                          name="Company Address "
                          placeholder=""
                          value={"1"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="TE Comments"
                          name="firstName"
                          placeholder=""
                          value={"--"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Version"
                          name="firstName"
                          placeholder=""
                          value={"v0"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Type"
                          name="firstName"
                          placeholder=""
                          value={"Technical and Commercial"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Product Type"
                          name="firstName"
                          placeholder=""
                          value={"Service"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="TE Summary Attachment"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Created On"
                          name="firstName"
                          placeholder=""
                          value={"28 May 2024"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bid Type"
                          name="firstName"
                          placeholder=""
                          value={"Closed"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="firstName"
                          placeholder=""
                          value={"403710-Vasantharam Ramakrishnan"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Technical Approval Type"
                          name="firstName"
                          placeholder=""
                          value={"Line Item"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFX Category"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="firstName"
                          placeholder=""
                          value={"- -"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Commercial Evaluation Header*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Commercial Evaluation Header{" "}
                      </span>
                      <span className="subHeaderTitle">
                        Commercial Evaluation Header{" "}
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4"></Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Commercial Justification Memo */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Commercial Justification Memo{" "}
                      </span>
                      <span className="subHeaderTitle">
                        Commercial Justification Memo{" "}
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4"></Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Commercial Evaluation Questionaire */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Commercial Evaluation Questionaire
                      </span>
                      <span className="subHeaderTitle">
                        Commercial Evaluation Questionaire
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4"></Row>
                  </div>
                </div>
              </div>
            </div>
            {/*suppliers table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Suppliers</span>
                      <span className="subHeaderTitle">Supplier Sections</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={supplersColumns}
                          data={suppliersData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Evaluation Criteria table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Evaluation Criteria</span>
                      <span className="subHeaderTitle">
                        Evaluation Criteria
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={evaluationCriteriaColumns}
                          data={evaluationCriteriaData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                    <hr />
                    <div className="d-flex justify-content-between w-50">
                      <p className="ms-3 fw-medium text-black">total</p>
                      <p>100</p>
                    </div>
                    <hr className="mb-3" />
                  </div>
                </div>
              </div>
            </div>
            {/* Exchange Rate  table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Exchange Rate</span>
                      <span className="subHeaderTitle">Exchange Rate</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={ExchangeRateColumns}
                          data={ExchangeRateData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Evaluation Summary table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Evaluation Summary</span>
                      <span className="subHeaderTitle">Evaluation Summary</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={EvaluationSummaryColumns}
                          data={EvaluationSummaryData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Award Summary table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Award Summary</span>
                      <span className="subHeaderTitle">Award Summary</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                    <SourcingCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  onClick={toggleCardVisibility}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={AwardSummaryColumns}
                          data={AwardSummaryData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CreateOtherChanges
        show={otherchangesmodelshow}
        onHide={hideOtherChangesModel}
        onData={handleDataFromCreateOtherChanges}
      />
      <PriceShedule
        show={PriceShedulemodalShow}
        onHide={hidePriceSheduleModal}
        submitPriceShedule={handelDataPriceShedule}
      />
    </main>
  );
};

export default CommercialEvaluation;
