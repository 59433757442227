import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

//Componentd
import Layout from "../../Layout/Layout";
import Loader from "../../Components/Loader";
import ShowStatus from "../../Components/Modal/supplier_app/Workflow/ShowStatus";
import Constants from "../../common/Constants";
import configFromEnv from "../../services/configuration";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getWorkflowInboxCards } from "../../redux/feature/supplier_app/workflowSlice";
import {
  addsupplierID,
  workflow,
} from "../../redux/feature/supplier_app/supplierSlice";
import {
  addGRNDetailNumber,
  addInvoiceDetailNumber,
  addPoDetailNumber,
  workflowPO,
} from "../../redux/feature/supplier_app/purchasingSlice";
import { 
  addPrDetailNumber, 
  workflowPR 
} from "../../redux/feature/supplier_app/sourcingSlice";

//API
import ItemService from "../../services/supplier_appCommonService";

const Inbox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Inbox");
  localStorage.setItem("submenu", "-");

  let getConstantVar = useSelector((state) => state?.common?.data); // User getConstantVar from Store
  let userData = useSelector((state) => state?.user?.data); // User Details from Store
  let workflowData = useSelector(
    (state) => state?.workflow?.workflowCounts?.data
  ); // workflowData Details from Store
  let workflowDataStatus = useSelector(
    (state) => state?.workflow?.workflowCounts?.status
  ); // workflowDataStatus Details from Store

  const [getWorkflowInfoStatus, setWorkflowInfoStatus] = useState(false);
  // const [getWorkflowDataStatus, setWorkflowDataStatus] = useState(false);
  const [getTableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShow(true);
    setSendDataModal(rowData?.workflowActionLogs);
    setModelTitle("Status Information");
  };

  const hideModal = () => {
    setModalShow(false);
  };

  const columns = [
    {
      name: "Business Component",
      selector: (row) => row.businessComponentDesc || "-",
      sortable: true,
      width: "180px",
    },
    {
      name: "Workflow Id",
      cell: (row) => (
        <button className="btnInfo" onClick={() => detailedPage(row, "Inbox")}>
          {row.businessComponentInstanceId || "--"}{" "}
        </button>
      ),
      width: "180px",
    },
    // {
    //   name: "Status",
    //   cell: (row) => (
    //     <span className="text-primary fw-bold">{row.status || "--"} </span>
    //   ),
    //   width: "180px",
    // },
    {
      name: "Previous Approver",
      selector: (row) => row.previousApprover || "-",
      cell: (row) => (
        <>
          <span
            className="bg-table-status bg-table-previous-approver-status"
            title={`${row.currentApprover} - ${row.previousAction}`}
          >
            <i className="bi bi-check-circle"></i> {row.previousApprover} -{" "}
            {row.previousAction}
          </span>
        </>
      ),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Current Approver",
    //   selector: (row) => row.currentApprover || "--",
    //   cell: (row) => (
    //     <>
    //       <span
    //         className="badge bg-warning badgeExtra"
    //         title={`${row.currentApprover} - ${row.currentAction}`}
    //       >
    //         <i className="bi bi-arrow-repeat"></i> {row.currentApprover} -{" "}
    //         {row.currentAction}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "170px",
    // },
    {
      name: "Next Approver",
      selector: (row) => row.nextApprover || "--",
      cell: (row) => (
        <>
          <span
            className="bg-table-status bg-table-next-approver-status"
            title={row.nextApprover}
          >
            {row.nextApprover} <i className="bi bi-arrow-right-square"></i>
          </span>
        </>
      ),
      sortable: true,
      width: "170px",
    },
    {
      name: "Status",
      selector: (row) => row.workflowStatus,
      cell: (row) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <i
            className="bi bi-substack iconTable statusColor"
            title="Workflow Log Details"
            onClick={() => showStatusInfo(row)}
          ></i>
        </div>
      ),
      sortable: true,
      width: "90px",
    },
    {
      name: "Created At",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <Moment format={configFromEnv.TIME_FORMATE_WITH_TIME}>{row.createdAt}</Moment>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Updated At",
      selector: (row) => row.updatedAt,
      cell: (row) => (
        <Moment format={configFromEnv.TIME_FORMATE_WITH_TIME}>{row.updatedAt}</Moment>
      ),
      sortable: true,
      width: "140px",
    },
  ];

  useEffect(() => {
    dispatch(getWorkflowInboxCards()); // Workflow card store
    dispatch(workflow({}));
    dispatch(workflowPO({}));
    dispatch(workflowPR({}));
    // eslint-disable-next-line
  }, []);

  const handleClickCard = (businessComponentId, count) => {
    if (count > 0) {
      setWorkflowInfoStatus(true);
      workflowInboxList(businessComponentId);
    }
  };

  const workflowInboxList = async (businessComponentId) => {
    setLoading(true);
    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });
    let obj = {
      workflowStatus: "Open",
      businessComponentId: businessComponentId,
    };

    await ItemService.getworkflowInboxList(obj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        toast.success("Workflow loaded", {
          duration: 2000,
          position: "top-right",
        });

        setTableData(item.data);

        // Update response value in Redux Store
        // dispatch(getSupplierList()); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);

    setLoading(false);
  };

  // useEffect(() => {
  //   if (workflowData.length > 0) {
  //     console.log(">> workflowData", workflowData);
  //   }

  //   // eslint-disable-next-line
  // }, [workflowData]);

  // Redirect to suppiler detailed page
  const detailedPage = (row, page) => {
    let obj = {
      workflow_record: row,
      page: page,
    };

    if (userData?.category === "VENDOR") {
      obj.pageInfo = userData?.category;
      dispatch(workflow(obj));
      navigate(Constants.SUPPILER_REGISTRATION);
    } else if (
      row.businessComponentId === getConstantVar?.SuplierBusinessComponentId
    ) {
      // SM
      obj.pageInfo = "";
      dispatch(addsupplierID(row.businessComponentInstanceId));
      dispatch(workflow(obj));
      navigate(Constants.SUPPLIER_DETAILED_PAGE_WORKFLOW);
    } else if (
      row.businessComponentId === getConstantVar?.POBusinessComponentId
    ) {
      // PO
      obj.pageInfo = "";
      dispatch(addPoDetailNumber(row.businessComponentInstanceId));
      dispatch(workflowPO(obj));
      navigate(Constants.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE);
    } else if (
      row.businessComponentId === getConstantVar?.InvoiceBusinessComponentId
    ) {
      // Invoice
      obj.pageInfo = "";
      dispatch(addInvoiceDetailNumber(row.businessComponentInstanceId));      
      dispatch(workflowPO(obj));
      navigate(Constants.POCOLLABRATION_INVOICE_DETIAILS);
    } else if (
      row.businessComponentId === getConstantVar?.GRNBusinessComponentId
    ) {
      // GRN
      obj.pageInfo = "";
      dispatch(addGRNDetailNumber(row.businessComponentInstanceId));      
      dispatch(workflowPO(obj));
      navigate(Constants.POCOLLABRATION_GRN_DETAILS);
    } else if (
      row.businessComponentId === getConstantVar?.workflow?.PR?.businessComponentId
    ) {
      // PR
      obj.pageInfo = "";
      dispatch(addPrDetailNumber(row.businessComponentInstanceId));      
      dispatch(workflowPR(obj));
      navigate(Constants.SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE);
    }
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="inboxScreen justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Workflow Inbox</h2>
            <p className="subText">Workflow details listed below</p>
            <hr />

            {!getWorkflowInfoStatus && (
              <Row>
                <Loader
                  isLoading={!workflowDataStatus}
                  className={"text-center"}
                />
                {workflowData && workflowData.length > 0
                  ? workflowData.map((value, index) => {
                      return (
                        <Col
                          xs={12}
                          md={3}
                          className="commLeftRightPadding"
                          key={index}
                        >
                          <div
                            className="card info-card sales-card"
                            onClick={() =>
                              handleClickCard(
                                value.businessComponentId,
                                value.count
                              )
                            }
                          >
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="card-icon d-flex align-items-center justify-content-center">
                                  {value.businessComponentId}
                                </div>
                                <div className="ps-3">
                                  <h1>{value.count}</h1>
                                  <span className="text-muted subTextCard pt-2">
                                    {value.businessComponentDesc}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            )}

            {getWorkflowInfoStatus && (
              <>
                <button
                  className="btnBack"
                  onClick={() => setWorkflowInfoStatus(false)}
                >
                  <i className="bi bi-arrow-return-left"></i> Go Back
                </button>

                {/* List Table */}
                <div className="accordion mt-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">SL</span>
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                          <span className="headerTitle">
                            Workflow Inbox List
                          </span>
                          <span className="subHeaderTitle">
                            View of Workflow Inbox List{" "}
                          </span>
                        </Col>

                        <Col xs={2} md={1} lg={1} className="">
                          <div className="filter">
                            <span
                              className="icon hide headerIcon"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i className="bi bi-three-dots"></i>
                            </span>
                            <ul
                              className="dropdown-menu dropdown-menu-end dropdown-menu-arrow hide menuList"
                              data-popper-placement="bottom-end"
                            >
                              <li className="dropdown-header text-start">
                                <h6>Menu</h6>
                              </li>

                              {/* <li>
                                <span className="dropdown-item headerIcon">
                                  <i className="bi bi-bar-chart mr-3"></i>
                                  Chart
                                </span>
                              </li>
                              <li>
                                <span className="dropdown-item headerIcon">
                                  <i className="bi bi-download mr-2"></i>
                                  Download
                                </span>
                              </li>
                              <li>
                                <span className="dropdown-item headerIcon">
                                  <i className="bi bi-funnel"></i>
                                  Filter
                                </span>
                              </li> */}
                              <li>
                                <span className="dropdown-item headerIcon">
                                  <i className="bi bi-arrow-clockwise"></i>
                                  Refresh
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={columns}
                              data={getTableData}
                              progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <SupplierList /> */}
              </>
            )}
          </div>
        </Col>
      </Row>

      <ShowStatus
        show={modalShow}
        onHide={() => hideModal()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
    </main>
  );
};

export default Inbox;
