import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import {
  validateEmail,
  validateContactNumber,
  validateRequired,
} from "../../../common/Validations";
import FailureModal from "../../../Components/Modal/supplier_app/FailureModal";
import { MultiselectField } from "../../../Components/formElements/MultiselectField";

//Serivce
import ItemService from "../../../services/supplier_appCommonService";
import toast from "react-hot-toast";
import SuccessRegisterModal from "../../../Components/SuccessRegisterModal";
import Constants from "../../../common/Constants";
import { getUsersListData } from "../../../redux/feature/userSlice";
import DataTable from "react-data-table-component";

const ManageAddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  let userListData = useSelector((state) => state?.user.userList.data); // User Details from Store
  let userId = useSelector((state) => state?.user.userList.userId); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [getDropdownData, setDropdownData] = useState("");

  const modalData = {
    title: "User Updation",
    successMsg: "",
  };
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [getApplicationMasterVal, setApplicationMasterVal] = useState("");
  const [getCategoryVal, setCategoryVal] = useState([]);
  const [getSubCategoryVal, setSubCategoryVal] = useState([]);
  const [getRoleIdVal, setRoleIdVal] = useState([]);

  const [getSubCategoryText, setSubCategoryText] = useState([]);
  const [getRoleIdText, setRoleIdText] = useState([]);
  const [getEditUpdateStatus, setEditUpdateStatus] = useState(false);
  const [getInputAccess, setInputAccess] = useState(true);

  const [formInputs, setFormInputs] = useState({
    status: "On Boarded",
    tenant: "",
    customerId: "",
    customerName: "",
    userId: "",
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    contactNumber: "",
    companyName: "",
    category: "",
    subCategory: "",
    roles: "",
  });

  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  /* handel Update User */
  const handelUpdate = async () => {
    let requiredFields = [];
    if (
      !validateRequired(formInputs?.userId) ||
      !validateRequired(formInputs?.firstName) ||
      !validateRequired(formInputs?.email) ||
      !validateRequired(formInputs?.contactNumber)
    ) {
      requiredFields.push("*Please Enter User Id");
      requiredFields.push("*Please Enter First Name");
      requiredFields.push("*Please Enter Email");
      requiredFields.push("*Please Enter Contact Number");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    if (!validateEmail(formInputs?.email)) {
      requiredFields.push("*Invalid Contact Email address");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    if (!validateContactNumber(formInputs?.contactNumber)) {
      requiredFields.push("*Invalid contact number (10 digits)");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }



    console.log(">> handelUpdate", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });
    let obj = {
      data: formInputs,
    };
    setDisabled(true);
    await ItemService.addUser(obj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
        // Update response value in Redux Store
        dispatch(getUsersListData()); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  //Edit
  const handleEdit = () => {
    setEditUpdateStatus(true);
    setInputAccess(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const onInputChange = ({ target: { name, value } }) => {    
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> getDropdownData >> ", getDropdownData);

    if (getDropdownData?.textField === "category") {
      getSubCategory(getDropdownData?.name); // get Sub Category List
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));

    } else if (getDropdownData?.textField === "subCategory") {
      let objArr = [];
      let objForApi = [];

      setSubCategoryText(getDropdownData?.selected);

      getDropdownData?.selected?.map((item, index) => {
        objArr.push({
          categoryId: item.name,
          active: true,
        });

        objForApi.push(item.name);
        return "";
      });

      getSubCategoryRoleId(objForApi); // get SubCategory Role Id List

      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: objArr,
      }));
    } else if (getDropdownData?.textField === "roles") {
      let objArr = [];

      setRoleIdText(getDropdownData?.selected);

      getDropdownData?.selected?.map((item, index) =>
        objArr.push({
          roleId: item.name,
        })
      );
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: objArr,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.selected,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const [getFailureModal] = useState(failureModalData);

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  //on page load
  useEffect(() => {
    getApplicationMaster();
    getCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategory = async () => {
    let obj = {};
    await ItemService.getCategory(obj).then((item) => {
      if (item?.status === 200) {
        setCategoryVal(item?.data);
      } else {
        setCategoryVal([]);
        console.log(">> Error: Loading getCategory");
      }
    });
  };

  const getSubCategory = async (param) => {
    let obj = {
      category: param,
    };
    await ItemService.getSubCategory(obj).then((item) => {
      if (item?.status === 200) {
        setSubCategoryVal(item?.data);
        setRoleIdVal([]);
      } else {
        setSubCategoryVal([]);
        console.log(">> Error: Loading getSubCategory");
      }
    });
  };

  const getSubCategoryRoleId = async (param) => {
    let obj = {
      subCategories: param,
    };
    await ItemService.getSubCategoryRoleId(obj).then((item) => {
      if (item?.status === 200) {
        setRoleIdVal(item?.data);
      } else {
        setRoleIdVal([]);
        console.log(">> Error: Loading getSubCategoryRoleId");
      }
    });
  };

  const getApplicationMaster = async () => {
    let obj = {};
    await ItemService.getApplicationMaster(obj).then((item) => {
      if (item?.status === 200) {
        setApplicationMasterVal(item?.data[0]);
      } else {
        setApplicationMasterVal([]);
        console.log(">> Error: Loading getApplicationMaster");
      }
    });
  };

  const navigateToWorkflow = () => {
    navigate(Constants.ADMIN_MANAGE_USER_LIST);
  };

  const loadPageIntialData = async () => {
    setLoading(true);
    // console.log(">> userId", userId);
    if (userId) {
      let filteredData = userListData.filter((item) => item.userId === userId);
      // console.log(">> filteredData", filteredData);

      let roles = filteredData[0].roles;
      let subCategory = filteredData[0].subCategory;

      let objSubCategory = [];
      let objRoles = [];

      subCategory?.map((item, index) => {
        // return objSubCategory.push(item.categoryId);
        return objSubCategory.push({
          id: index + 1,
          name: item.categoryId,
        });
      });

      roles?.map((item, index) => {
        // return objRoles.push(item.roleId);
        return objRoles.push({
          id: index + 1,
          name: item.roleId,
        });
      });

      setSubCategoryText(objSubCategory);
      setRoleIdText(objRoles);

      setFormInputs(filteredData[0]);
      setLoading(false);
    } else {
      navigate(Constants.ADMIN_MANAGE_USER_LIST);
    }
  };

  useEffect(() => {
    loadPageIntialData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userListData]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={9} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">User Details: {userId} </h2>
                {/* <p className="subText">User Details</p> */}
              </Col>
              {getEditUpdateStatus && (
                <Col xs={3} md={2} lg={2} className="mb-2">
                  <button
                    className="btn w-full"
                    onClick={handelUpdate}
                    disabled={disabled}
                  >
                    Update User
                  </button>
                </Col>
              )}

              {!getEditUpdateStatus && (
                <Col xs={3} md={2} lg={2} className="mb-2">
                  <button
                    className="btn w-full"
                    onClick={handleEdit}
                    disabled={disabled}
                  >
                    Edit User
                  </button>
                </Col>
              )}

              <hr />

              <Col xs={12} md={4} lg={4} className="text-left mb-4">
                <button
                  className="btnBack"
                  onClick={() => navigateToWorkflow()}
                >
                  <i className="bi bi-arrow-return-left"></i> Go Back
                </button>
              </Col>
            </Row>

            {/* Add/Edit - User */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">UD</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">User Details</span>
                      <span className="subHeaderTitle">User Details</span>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          name="status"
                          placeholder=""
                          value={formInputs.status || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer ID"
                          name="customerId"
                          placeholder=""
                          value={getApplicationMasterVal.customerId || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          name="customerName"
                          placeholder=""
                          value={getApplicationMasterVal.customerName || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="User ID"
                          name="userId"
                          placeholder=""
                          value={formInputs.userId || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="First Name"
                          name="firstName"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                          disabled={getInputAccess}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Last Name"
                          name="lastName"
                          placeholder=""
                          value={formInputs.lastName || ""}
                          onChange={onInputChange}
                          disabled={getInputAccess}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Email"
                          name="email"
                          placeholder=""
                          value={formInputs.email || ""}
                          onChange={onInputChange}
                          disabled={getInputAccess}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact Number"
                          name="contactNumber"
                          placeholder=""
                          value={formInputs.contactNumber || ""}
                          onChange={onInputChange}
                          maxLength="10"
                          disabled={getInputAccess}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Name"
                          name="companyName"
                          placeholder=""
                          value={formInputs.companyName || ""}
                          onChange={onInputChange}
                          disabled={getInputAccess}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Category"
                          placeholder="Please select the Category"
                          data={getCategoryVal}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          setValue={formInputs.category}
                          getvalue={setDropdownData}
                          className="dropdownOption"
                          disabled={getInputAccess}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <MultiselectField
                          label="Sub Category"
                          placeholder="Please select the Sub Category"
                          data={getSubCategoryVal}
                          id="subCategory"
                          iconClassName="dropdownIcon"
                          name="subCategory"
                          getvalue={setDropdownData}
                          className="dropdownOption"
                          disabled={getInputAccess}
                          setValue={getSubCategoryText}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <MultiselectField
                          label="Roles"
                          placeholder="Please select the Roles"
                          data={getRoleIdVal}
                          id="roles"
                          iconClassName="dropdownIcon"
                          name="roles"
                          getvalue={setDropdownData}
                          className="dropdownOption"
                          disabled={getInputAccess}
                          setValue={getRoleIdText}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Historical Log Of Approval */}
            <div className="accordion mt-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LG</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">
                        Historical Log Of Approval
                      </span>
                      {/*  <span className="subHeaderTitle">
                       Add User to User's Collection
                      </span> */}
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <DataTable
                      title=""
                      columns={[]}
                      data={[]}
                      progressPending={userListData?.status}
                      paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                      pagination
                      // paginationServer
                      // paginationTotalRows={totalRows}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      // onChangePage={handlePageChange}
                      selectableRows
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.ADMIN_MANAGE_USER_LIST}
      />
    </main>
  );
};

export default ManageAddUser;
