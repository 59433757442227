import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";


const BuyerManual = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

 

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">BuyerManual </h2>
                <p className="subText">BuyerManual</p>
              </Col>
              <hr />
            </Row>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default BuyerManual;
